import { filterData } from "./Utility";
import customFetch from "./apicall/api";
export const update_payload = async (
  api_token,
  bucket_name,
  payload_key,
  payload
) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };

    const response = await customFetch(
      process.env.REACT_APP_URL +
        "/payload/update/" +
        bucket_name +
        "/" +
        payload_key,
      requestOptions
    );

    if (response.status != 200) throw "Error updating the payload";

    const data = await response.json();
    data["status"] = 1;
    return data;
  } catch (e) {
    console.error(e);
    return { message: "Error updating the payload", status: 0 };
  }
};
export const update_mongopayload = async (
  api_token,
  dbname,
  collectionname,
  objectid,
  change_data
) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(change_data),
    };

    const response = await customFetch(
      process.env.REACT_APP_URL +
        "/mongo/updateone/" +
        dbname +
        "/" +
        collectionname +
        "/" +
        objectid,
      requestOptions
    );

    if (response.status != 200) throw "Error updating the payload";

    const data = await response.json();
    return data;
  } catch (e) {
    return { message: "Error updating the payload", status: 0 };
  }
};
export const insert_mongopayload = async (
  api_token,
  dbname,
  collectionname,
  change_data
) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(change_data),
    };

    const response = await customFetch(
      process.env.REACT_APP_URL +
        "/mongo/insert/" +
        dbname +
        "/" +
        collectionname ,
        
      requestOptions
    );

    if (response.status != 200) throw "Error updating the payload";

    const data = await response.json();
    return data;
  } catch (e) {
    return { message: "Error updating the payload", status: 0 };
  }
};
export const update_mongogrouppayload = async (
  api_token,
  dbname,
  collectionname,
  change_data
) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(change_data),
    };

    const response = await customFetch(
      process.env.REACT_APP_URL +
        "/mongo/updateone/" +
        dbname +
        "/" +
        collectionname,
      requestOptions
    );

    if (response.status != 200) throw "Error updating the payload";

    const data = await response.json();
    return data;
  } catch (e) {
    return { message: "Error updating the payload", status: 0 };
  }
};

export const delete_mongofield = async (
  api_token,
  dbname,
  collectionname,
  objectid,
  change_data
) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(change_data),
    };

    const response = await customFetch(
      process.env.REACT_APP_URL +
        "/mongo/delete/" +
        dbname +
        "/" +
        collectionname +
        "/" +
        objectid,
      requestOptions
    );

    if (response.status != 200) throw "Error updating the payload";

    const data = await response.json();
    return data;
  } catch (e) {
    return { message: "Error updating the payload", status: 0 };
  }
};

export const get_payload_config = async (
  api_token,
  effective_designation_id
) => {
  try {
    const formdata = new FormData();
    formdata.append("branch_id", localStorage.getItem("branch_id"));
    formdata.append(
      "effective_designation",
      localStorage.getItem("e_designation_id")
    );
    formdata.append("userid", localStorage.getItem("in_userid"));
    formdata.append("flag", "fetch_user_functionality");
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
      },
      body: formdata,
    };

    const response = await customFetch(
      process.env.REACT_APP_URL + "/permission/data",
      requestOptions
    );
    const data = await response.json();
    if (
      !data.message &&
      data.user_functionality_mapping[0].views &&
      data.user_functionality_mapping[0].views.length > 0
    ) {
      return data.user_functionality_mapping[0].views;
    } else {
      return get_view_config(api_token, effective_designation_id);
    }
  } catch (e) {
    console.error(e);
    return [];
  }
};

const get_view_config = async (api_token, effective_designation_id) => {
  try {
    const requestOptions = {
      method: "GET",
      headers: {
        "api-token": api_token,
      },
    };

    const response = await customFetch(
      process.env.REACT_APP_URL +
        "/get/webhook/configuration?effective_designation_id=" +
        effective_designation_id,
      requestOptions
    );
    const data = await response.json();
    return data;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const get_payload_views = async (
  api_token,
  bucket_name,
  event_name,
  defaultfilter,
  isLoginuser,
  filterDate,
  event_location,
  searchData,
  searchconfig
) => {
  console.log("defaultfilter->", defaultfilter.length);
  try {
    let payload = "";
    let defaultpayload = [];
    if (event_name != "") {
      defaultpayload = [
        [event_location, "==", event_name],
        ["created_at", ">", filterDate],
      ];
    } else {
      defaultpayload = [["created_at", ">", filterDate]];
    }
    if (isLoginuser == "1") {
      let convertedData = [];
      if (defaultfilter.length > 0 && defaultfilter[0].operatordata == "==") {
        convertedData = defaultfilter.map((item) => [
          item.field,
          "==",
          parseInt(item.fieldvalue),
        ]);
      }
      if (
        defaultfilter.length > 0 &&
        defaultfilter[0].operatordata == "array_contains_any"
      ) {
        convertedData = defaultfilter.map((item) => [
          item.field,
          "array_contains_any",
          item.fieldvalue.split(",").map((num) => parseInt(num)), //[parseInt(item.fieldvalue)],
        ]);
      }
      payload = [...defaultpayload, ...convertedData];
    } else {
      payload = defaultpayload; //[["event", "==", event_name]];
    }
    let payload_search = [...payload, ...searchData];
    let search_data;
    if (searchconfig) {
      let searchvalue = [];
      searchvalue = searchconfig.map((item) => [item[0], item[1], item[2]]);
      search_data = [...payload_search, ...searchvalue];
    } else {
      search_data = payload_search;
    }
    console.log("search_data-->", search_data);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(search_data),
    };

    const response = await customFetch(
      process.env.REACT_APP_URL + "/payload/get/" + bucket_name,
      requestOptions
    );
    const data = await response.json();
    return data;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const get_mongo_payload = async (
  api_token,
  search_by_pair,
  filterDate,
  searchconfig,
  dbname,
  collectionname,
  defaultfilter,
  isLoginuser,
  limit_per_call,
  offset,
  filter_columns,
  hideloadmore
) => {
  console.log("defaultfilter", defaultfilter);
  try {
    let payload = "";
    let defaultpayload = [];
    // if (event_name != "") {
    //   defaultpayload = [
    //     [event_location, "==", event_name],
    //     ["created_at", ">", filterDate],
    //   ];
    // } else {
    //   defaultpayload = [["created_at", ">", filterDate]];
    // }
    if (isLoginuser == "1" && defaultfilter.length > 0) {
      let convertedData = [];
      if (defaultfilter[0].operatordata == "==") {
        convertedData = defaultfilter.map((item) => [
          item.field,
          "==",
          parseInt(item.fieldvalue),
        ]);
      }
      if (defaultfilter[0].operatordata == "array_contains_any") {
        convertedData = defaultfilter.map((item) => [
          item.field,
          "in",
          item.fieldvalue.split(",").map((num) => parseInt(num)), //[parseInt(item.fieldvalue)],
        ]);
        console.log("convertedData", convertedData);
      }
      payload = [...defaultpayload, ...convertedData];
    }
    let payload_search = JSON.parse(search_by_pair);
    let splitkeypair = [...payload, ...payload_search];
    if (searchconfig) {
      const elementToAdd = ["issearch", "==", [searchconfig]];
      payload = [...splitkeypair, elementToAdd]; // splitkeypair.push(elementToAdd);
    } else {
      payload = search_by_pair;
    }
    // Add bucketFields to the payload
    if (filter_columns) {
      payload.push(["filter_columns", "==", filter_columns]);
    }

    console.log("payload->", payload);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };
    let url = "";
    if (hideloadmore == "1") {
      url =
        process.env.REACT_APP_URL +
        "/mongo/get/" +
        dbname +
        "/" +
        collectionname +
        "?limit=" +
        limit_per_call +
        "&offset=" +
        offset;
    } else {
      url =
        process.env.REACT_APP_URL +
        "/mongo/get/" +
        dbname +
        "/" +
        collectionname;
    }
    const response = await customFetch(url, requestOptions);
    const data = await response.json();
    return data;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const cache_payload_data = async (update_data,current_data,isLoginuser,defaultfilter,search_by_pair,searchconfig) => {
  let payload = "";
    let defaultpayload = [];    
    if (isLoginuser == "1" && defaultfilter.length > 0) {
      let convertedData = [];
      if (defaultfilter[0].operatordata == "==") {
        convertedData = defaultfilter.map((item) => [
          item.field,
          "==",
          parseInt(item.fieldvalue),
        ]);
      }
      if (defaultfilter[0].operatordata == "array_contains_any") {
        convertedData = defaultfilter.map((item) => [
          item.field,
          "in",
          item.fieldvalue.split(",").map((num) => parseInt(num)), //[parseInt(item.fieldvalue)],
        ]);
        console.log("convertedData", convertedData);
      }
      payload = [...defaultpayload, ...convertedData];
    }
    let payload_search = JSON.parse(search_by_pair);
    let splitkeypair = [...payload, ...payload_search];
    if (searchconfig.rules.length > 0) {
      const elementToAdd = ["issearch", "==", [searchconfig]];
      payload = [...splitkeypair, elementToAdd]; // splitkeypair.push(elementToAdd);
    } else {
      payload = search_by_pair;
    } 
    console.log("query",payload);

    const updatedData = current_data.map((item) => {
      const update = update_data.find(
        (updateItem) => updateItem.object_id === item._id.$oid
      );

      if (update) {
        // Merge the existing item with the update data
        return {
          ...item,
          ...update,
          ...update.add_data_before,
          _id: item._id, // Preserve the original _id
          //add_data_before: update.add_data_before, // Include add_data_before if needed
        };
      }
      return item;
    });
    const filteredData = applyFilter(updatedData, payload);
    console.log("updatedData",filteredData);
    return filteredData;
}

export const applyFilter = (data, query) => {
  if (!Array.isArray(query) || query.length === 0) return data; // Ensure query is valid

  return data.filter(item => {
      let issearchMatched = true;
      
      return query.every(condition => {
          if (!Array.isArray(condition) || condition.length < 3) return false; // Validate condition format
          
          const [field, operator, value] = condition;
          if (typeof field !== 'string' || typeof operator !== 'string') return false;

          if (field === 'issearch' && Array.isArray(value) && value.length > 0 && typeof value[0] === 'object') {
              const searchCondition = value[0];
              if (searchCondition.combinator === 'or' && Array.isArray(searchCondition.rules)) {
                  return searchCondition.rules.some(rule => {
                      const { field: ruleField, operator: ruleOperator, value: ruleValue } = rule;
                      if (!(ruleField in item)) return false;

                      // Convert ruleValue to match item[field] type
                      const typedRuleValue = typeof item[ruleField] === 'number' ? Number(ruleValue) : ruleValue;

                      switch (ruleOperator) {
                          case '=':
                              return item[ruleField] === typedRuleValue;
                          case '!=':
                              return item[ruleField] !== typedRuleValue;
                          case '>':
                              return item[ruleField] > typedRuleValue;
                          case '<':
                              return item[ruleField] < typedRuleValue;
                          case '>=':
                              return item[ruleField] >= typedRuleValue;
                          case '<=':
                              return item[ruleField] <= typedRuleValue;
                          case 'in':
                              return Array.isArray(typedRuleValue) && typedRuleValue.includes(item[ruleField]);
                          case 'nin':
                              return Array.isArray(typedRuleValue) && !typedRuleValue.includes(item[ruleField]);
                          default:
                              return false;
                      }
                  });
              }
              if (searchCondition.combinator === 'and' && Array.isArray(searchCondition.rules)) {
                return searchCondition.rules.some(rule => {
                    const { field: ruleField, operator: ruleOperator, value: ruleValue } = rule;
                    if (!(ruleField in item)) return false;

                    // Convert ruleValue to match item[field] type
                    const typedRuleValue = typeof item[ruleField] === 'number' ? Number(ruleValue) : ruleValue;

                    switch (ruleOperator) {
                        case '=':
                            return item[ruleField] === typedRuleValue;
                        case '!=':
                            return item[ruleField] !== typedRuleValue;
                        case '>':
                            return item[ruleField] > typedRuleValue;
                        case '<':
                            return item[ruleField] < typedRuleValue;
                        case '>=':
                            return item[ruleField] >= typedRuleValue;
                        case '<=':
                            return item[ruleField] <= typedRuleValue;
                        case 'in':
                            return Array.isArray(typedRuleValue) && typedRuleValue.includes(item[ruleField]);
                        case 'nin':
                            return Array.isArray(typedRuleValue) && !typedRuleValue.includes(item[ruleField]);
                        default:
                            return false;
                    }
                });
            }
          } else {
              if (!(field in item)) return false; // Ensure field exists in item

              switch (operator) {
                  case '==':
                      return item[field] === value;
                  case '>':
                      return item[field] > value;
                  case '<':
                      return item[field] < value;
                  case '>=':
                      return item[field] >= value;
                  case '<=':
                      return item[field] <= value;
                  case '!=':
                      return item[field] !== value;
                  case 'in':
                      return Array.isArray(value) && value.includes(item[field]);
                  case 'nin':
                      return Array.isArray(value) && !value.includes(item[field]);
                  case 'exists':
                      return value ? field in item : !(field in item);
                  case 'regex':
                      return typeof item[field] === 'string' && new RegExp(value, 'i').test(item[field]);
                  default:
                      return false;
              }
          }
      });
  });
};