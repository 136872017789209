//import "../../public/leegalityv6.js";
import React, { Component } from "react";
import customFetch from "./apicall/api";
import { logout, generateId } from "./Utility";
import Webcam from "react-webcam";
import { ThemeContext } from "../context/IsLoader";
import { PEButton, PEDiv, PEImg, PEInput, PEA, PESpan } from "./permissionComponents";

// AT-1739 | AT-2199 | Ankur Makavana | 19-07-2023 | Enable back camera selection
const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const videoConstraints = {
  facingMode: FACING_MODE_USER,
};
let intervalId;

const generate_Id1 = generateId(10);
var api_token = "";
class Esign extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.webcamRef = React.createRef(null);
    this.state = {
      signersdata: [],
      loan_doc_file: "",
      loan_id: "",
      isSpin: false,
      OpenCam: false,
      isdocid: false,
      signingStatusWithFlagB: false,
      facingMode: FACING_MODE_USER,
      isGenerate: false,
      isDownload: false,
      isProcessDoc: false,
      isDelete: false,
      isDCB: 0,
    };
  }
  componentDidMount() {
    document.title = "E-sign";
    //AT-1659 | AT-1767 | Ankur Makavana | 05-07-2023 | store ui performance data
    setTimeout(() => {
      if (document.readyState === "complete") {
        this.context.onPageLoad({
          component: this.constructor.name,
          component_id: generate_Id1,
        });
      } else {
        window.addEventListener("load", this.context.onPageLoad());
        this.cleanup = () =>
          window.removeEventListener("load", this.context.onPageLoad());
      }
    }, 1000);
    const { modifiedArray } =
      (this.props.location && this.props.location.state) || {};
    if (modifiedArray) {
      this.setState(
        {
          loan_id: modifiedArray.loan_id,
          branch_id: modifiedArray.branch_id,
          isDCB:
            process.env.REACT_APP_DCB_BRANCH.split(",").includes(
              modifiedArray.branch_id
            ) == true
              ? 1
              : 0,
        },
        () => this.getsigners()
      );
    }
    this.checkForRequest();
  }
  checkForRequest = () => {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    api_token = params.get("api_token");
    const user_id = params.get("uid");
    const desc_id = params.get("desc_id");
    if (api_token == null) {
      api_token =
        (this.props.location && this.props.location.state.api_token) || {};
    } else {
      localStorage.setItem("api_token", api_token);
      if (user_id == null || user_id == "null" || user_id == "") {
        this.props.history.goBack();
      } else {
        localStorage.setItem("in_userid", user_id);
        this.setState({ isDCB: 0 });
      }
    }
  };
  componentDidUpdate() {
    if (this.state.isProcessDoc == true) {
      if (!intervalId) {
        intervalId = setInterval(() => {
          this.check_isdocsign();
        }, 30000);
      }
    } else {
      clearInterval(intervalId);
      intervalId = null;
    }
  }
  componentWillUnmount() {
    this.setState({
      isProcessDoc: false,
    });
    intervalId = null;
  }
  check_isdocsign = () => {
    // const { api_token } =
    //   (this.props.location && this.props.location.state) || {};
    let url = "";
    if (this.state.isDCB == 1) {
      url =
        process.env.REACT_APP_URL +
        "/esign/dcb/signers?loan_id=" +
        this.state.loan_id;
    } else {
      url =
        process.env.REACT_APP_URL +
        "/esign/signers?loan_id=" +
        this.state.loan_id;
    }
    customFetch(url, {
      method: "GET",
      headers: {
        "api-token": api_token,
        payload_id: this.context.payloadId,
      },
    })
      .then((res) => {
        if (res.status != 200) {
          this.setState({
            isErrOpen: true,
            api_error_message: "Please Contact Techincal Team.",
            isSpin: false,
          });
        }
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return res.json();
      })
      .then((data) => {
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }

        if (
          data.document.mid_doc_file_path != null &&
          data.document.blob_url != null &&
          data.document.document_status == true
        ) {
          this.setState({
            isProcessDoc: false,
          });

          const signersWithFlagB = data.signers.filter(
            (signer) => signer.flag === "B"
          );
          const signingStatusWithFlagB = signersWithFlagB.map(
            (signer) => signer.signing_status
          );

          this.setState({
            signersdata: data,
            isSpin: false,
            signingStatusWithFlagB: signingStatusWithFlagB[0],
          });

          data.signers.map((sign) =>
            this.getprofilephoto(
              sign.profile_photo_filename,
              sign.family_member_id
            )
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // AT-1739 | AT-2199 | Ankur Makavana | 19-07-2023 | Enable back camera selection
  change_cam() {
    this.setState((prevState) => ({
      facingMode:
        prevState.facingMode === FACING_MODE_USER
          ? FACING_MODE_ENVIRONMENT
          : FACING_MODE_USER,
    }));
  }
  initDigio = (documentId, identifier, access_token, sign_method) => {
    if (
      (documentId == "" || documentId == null) &&
      (access_token == "" || access_token == null)
    ) {
      this.setState({
        isdocid: true,
      });
    } else {
      let environment = "";
      if (process.env.REACT_APP_ENVIRONMENT == "prod")
        environment = "production";
      else environment = "sandbox";
      const digoOptions = {
        environment: environment,
        logo:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKoCImzRGYjIXBOLGvldo_SKveUrqk1OiXhIg90e5XGQ&s",
        is_iframe: true,
        method: sign_method,
      };
      digoOptions.callback = (_digio) => {
        if (_digio.error_code === "CANCELLED") {
          console.log("Flow cancelled by user");
          return;
        }
        if (_digio.error_code !== undefined) {
          throw new Error(_digio.message);
        }
        console.log(_digio);
        this.getsigners();
      };

      const digioInstance = new window.Digio(digoOptions);
      digioInstance.init();
      digioInstance.submit(documentId, identifier, access_token);
    }
  };
  getprofilephoto = async (path, member) => {
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    // const formdata = new FormData();
    // formdata.append("bucket_name", path.split("/")[0]);
    // formdata.append("blob_name", path.split("/")[2]);
    // formdata.append("blob_url", path);
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({"source_blob_name": path}),
    };

    customFetch(process.env.REACT_APP_FILE_DOWNLOAD + "/gcs/download", requestOptions)
      .then((res) => {
        if (res.status != 200) {
          this.setState({
            isErrOpen: true,
            api_error_message: "Please Contact Techincal Team.",
            isSpin: false,
          });
        }
        if (res.api_code === 4) {
          localStorage.clear();
          this.props.history.push("/");
        }
        return res.blob();
      })
      .then((img) => {
        //console.log(URL.createObjectURL(img))
        let member1 = "member_" + member;

        this.setState({
          [member1]: URL.createObjectURL(img),
        });
        this.getFileMetadata(URL.createObjectURL(img), member);
      });
  };

  getFileMetadata = async (fileUrl, member) => {
    //const { fileUrl } = this.props;
    console.log("fileUrl-->", fileUrl);

    try {
      const response = await fetch(fileUrl, { method: "HEAD" });

      if (response.ok) {
        const metadata = {
          contentType: response.headers.get("content-type"),
          contentLength: response.headers.get("content-length"),
          lastModified: response.headers.get("last-modified"),
          // Add more metadata properties as needed
        };
        const contentLength = response.headers.get("content-length");

        const sizeInMB = contentLength
          ? (contentLength / (1024 * 1024)).toFixed(2)
          : null;

        //this.setState({ metadata });
        console.log("metadata->", sizeInMB);
        let memberImg = "memberImg_" + member;
        this.setState({
          [memberImg]: sizeInMB,
        });
        //return sizeInMB;
      } else {
        console.error(
          "Failed to fetch file metadata. Status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error fetching file metadata:", error);
    }
  };

  getsigners() {
    this.setState({ isSpin: true });
    // const { api_token } =
    //   (this.props.location && this.props.location.state) || {};
    let generate_Id = generateId(10);
    let url = "";
    if (this.state.isDCB == 1) {
      url =
        process.env.REACT_APP_URL +
        "/esign/dcb/signers?loan_id=" +
        this.state.loan_id;
    } else {
      url =
        process.env.REACT_APP_URL +
        "/esign/signers?loan_id=" +
        this.state.loan_id;
    }
    customFetch(url, {
      method: "GET",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
      },
    })
      .then((res) => {
        if (res.status != 200) {
          this.setState({
            isErrOpen: true,
            api_error_message: "Please Contact Techincal Team.",
            isSpin: false,
          });
        }
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return res.json();
      })
      .then((data) => {
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        if (data.message) {
          this.setState({
            isErrOpen: true,
            api_error_message: data.message,
            isSpin: false,
          });
        } else {
          if (
            data.document.mid_doc_file_path == null &&
            data.document.blob_url == null &&
            data.document.document_status == true
          ) {
            this.setState({
              isProcessDoc: true,
            });
          } else {
            this.setState({
              isProcessDoc: false,
            });
          }

          const signersWithFlagB = data.signers.filter(
            (signer) => signer.flag === "B"
          );
          const signingStatusWithFlagB = signersWithFlagB.map(
            (signer) => signer.signing_status
          );

          //console.log("signingStatusWithFlagB", signingStatusWithFlagB[0]);

          this.setState({
            signersdata: data,
            isSpin: false,
            signingStatusWithFlagB: signingStatusWithFlagB[0],
          });

          data.signers.map((sign) =>
            this.getprofilephoto(
              sign.profile_photo_filename,
              sign.family_member_id
            )
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  capture = () => {
    const imageSrc = this.webcamRef.current.getScreenshot();
    // Perform face liveness check with the captured image
    // Send the captured image to your backend server for processing
    // Implement your liveness check algorithm here
    this.setState({
      tempImg: imageSrc,
    });
    const fileSize = imageSrc.length * (3 / 4) - 2;
    // if (fileSize > 6000000) {
    // } else {
    // }
  };
  setcaptureimg = () => {
    let newImage = "member_" + this.state.family_member_id;
    this.setState({
      [newImage]: this.state.tempImg,
      OpenCam: false,
    });
    // console.log("blob", blob);
    const fileType = this.state.tempImg.substring(
      this.state.tempImg.indexOf("/") + 1,
      this.state.tempImg.indexOf(";")
    );

    const fileName =
      this.state.loan_id + "_" + this.state.family_member_id + "." + fileType;

    //let formdata = new FormData();
    let blob = this.base64ToBlob(this.state.tempImg)
      .then((blob) => {
        this.blobToFormData(blob, fileName);
        // Use the FormData object as needed, e.g., send it via AJAX request
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    //formdata.append("file", blob);
  };
  blobToFormData = (blob, fileName) => {
    // const { api_token } =
    //   (this.props.location && this.props.location.state) || {};
    const formData = new FormData();
    formData.append("file", blob, fileName);
    formData.append("blob_url", "navadhanftp/KYC/" + fileName);
    formData.append("flag", "profile_image");
    formData.append("family_member_id", this.state.family_member_id);
    formData.append(
      "loan_documentation_id",
      this.state.signersdata.loan_documentation_id
    );
    //return formData;
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
      },
      body: formData,
    };
    customFetch(
      process.env.REACT_APP_URL + "/save/files/bucket",
      requestOptions
    )
      .then((response) => {
        if (response.status != 200) {
          this.setState({
            isErrOpen: true,
            api_error_message: "Please Contact Techincal Team.",
            isSpin: false,
          });
        }
        if (response.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: response.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        console.log("data->", data);
        this.getprofilephoto(data[0].blob_url, this.state.family_member_id);
        // this.setState({ isSpin: false });
        // this.fetchDetails();
      })
      .catch((error) => console.log("error", error));
  };
  base64ToBlob = async (base64String) => {
    const response = await fetch(base64String);
    const blob = await response.blob();
    return blob;
  };
  editmobile = (mid) => {
    let mobileEdit = "hhmobileedit_" + mid;
    this.setState({
      [mobileEdit]: 1,
    });
  };
  changewitnessesmobile = (e, mid, index) => {
    let valid = true;
    //let errorMsg = {};
    let errmobile = "Errormobile_" + mid;
    // if (e.target.value.replace(/\D/g, "").length !== 10) {
    //   valid = false;
    //   this.setState({
    //     [errmobile]: "Please Enter Valid Number",
    //   });
    // } else {
    //   valid = true;
    //   this.setState({
    //     [errmobile]: "",
    //   });
    // }
    if (isNaN(e.target.value)) {
      let validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (e.target.value.match(validRegex)) {
        valid = true;
        this.setState({
          [errmobile]: "",
        });
      } else {
        valid = false;
        this.setState({
          [errmobile]: "Please Enter Valid Email",
        });
      }
    } else {
      if (e.target.value.replace(/\D/g, "").length !== 10) {
        valid = false;
        this.setState({
          [errmobile]: "Please Enter Valid Number",
        });
      } else {
        valid = true;
        this.setState({
          [errmobile]: "",
        });
      }
    }
    let hhmobile = "hhmobile_" + mid;
    this.setState({
      [hhmobile]: e.target.value,
    });
    let signdata = this.state.signersdata;
    //const list = [...signdata];
    signdata.witnesses[index]["mobile"] = e.target.value;
    //setfieldlist(list);
    this.setState({
      signersdata: signdata,
    });
  };
  changehhmobile = (e, mid, index) => {
    let valid = true;
    //let errorMsg = {};
    let errmobile = "Errormobile_" + mid;
    if (isNaN(e.target.value)) {
      let validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (e.target.value.match(validRegex)) {
        valid = true;
        this.setState({
          [errmobile]: "",
        });
      } else {
        valid = false;
        this.setState({
          [errmobile]: "Please Enter Valid Email",
        });
      }
    } else {
      if (e.target.value.replace(/\D/g, "").length !== 10) {
        valid = false;
        this.setState({
          [errmobile]: "Please Enter Valid Number",
        });
      } else {
        valid = true;
        this.setState({
          [errmobile]: "",
        });
      }
    }
    let hhmobile = "hhmobile_" + mid;
    this.setState({
      [hhmobile]: e.target.value,
    });
    let signdata = this.state.signersdata;
    //const list = [...signdata];
    signdata.signers[index]["mobile"] = e.target.value;
    //setfieldlist(list);
    this.setState({
      signersdata: signdata,
    });

    //console.log("data", signdata);
  };
  // AT-2109 | AT-2156 | Ankur Makavana | 10-07-2023 | replace blob_url with public filepath
  getloandoc = async (filename, path) => {
    this.setState({
      isSpin: true,
    });
    // const { api_token } =
    //   (this.props.location && this.props.location.state) || {};
    // AT-2109 | AT-2156 | Ankur Makavana | 10-07-2023 | replace blob_url with public filepath
    // const formdata = new FormData();
    // formdata.append("bucket_name", path.split("/")[0]);
    // formdata.append("blob_name", path.split("/")[2]);
    // formdata.append("blob_url", path);
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({"source_blob_name": path}),
    };
    customFetch(process.env.REACT_APP_FILE_DOWNLOAD + "/gcs/download", requestOptions)
      .then((res) => {
        if (res.status != 200) {
          this.setState({
            isErrOpen: true,
            api_error_message: "Please Contact Techincal Team.",
            isSpin: false,
          });
        }
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
            isSpin: false,
          });
        }

        if (res.api_code === 4 || res.api_code === 3 || res.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        return res.blob();
      })
      .then((img) => {
        this.setState({
          isSpin: false,
        });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(img);
        link.setAttribute("download", filename);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
    // if (this.state.signersdata.document) {
    //   const link = document.createElement("a");
    //   link.href = path;
    //   link.setAttribute("download", filename);
    //   // Append to html link element page
    //   document.body.appendChild(link);
    //   // Start download
    //   link.click();
    //   // Clean up and remove the link
    //   link.parentNode.removeChild(link);
    //}
  };
  downloadesigndoc = async (loan_documentation_id, doctype) => {
    //console.log("url", url);
    //let filename = ""; //url.substring(url.lastIndexOf("/") + 1);
    this.setState({ isSpin: true });
    // const { api_token } =
    //   (this.props.location && this.props.location.state) || {};

    let generate_Id = generateId(10);
    const requestOptions = {
      method: "GET",
      headers: {
        "api-token": api_token,
        payload_id: this.context.payloadId,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
      },
    };
    const params = {
      loan_documentation_id: loan_documentation_id,
      download_type: doctype,
    };
    const queryParams = new URLSearchParams(params).toString();
    const response = await customFetch(
      process.env.REACT_APP_URL +
        "/esign/leegality/document/download?" +
        queryParams,
      requestOptions
    );
    const data = await response.blob();
    let url = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", loan_documentation_id + ".pdf");

    // Append to html link element page
    document.body.appendChild(link);
    this.setState({ isSpin: false });
    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };
  downloaddocument = async (loan_documentation_id) => {
    //console.log("url", url);
    //let filename = ""; //url.substring(url.lastIndexOf("/") + 1);
    this.setState({ isSpin: true });
    if (this.state.signersdata.document) {
      // const { api_token } =
      //   (this.props.location && this.props.location.state) || {};
      const formdata = new FormData();
      // formdata.append("bucket_name", path.split("/")[0]);
      // formdata.append("blob_name", path.split("/")[2]);
      formdata.append("blob_url", this.state.signersdata.document.blob_url);
      let generate_Id = generateId(10);
      const requestOptions = {
        method: "GET",
        headers: {
          "api-token": api_token,
          payload_id: this.context.payloadId,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
      };

      const response = await customFetch(
        process.env.REACT_APP_URL +
          "/esign/document/download?loan_documentation_id=" +
          loan_documentation_id,
        requestOptions
      );
      const data = await response.blob();
      let url = window.URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", loan_documentation_id + ".pdf");

      // Append to html link element page
      document.body.appendChild(link);
      this.setState({ isSpin: false });
      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
  };
  generateloandoc = () => {
    // const { api_token } =
    //   (this.props.location && this.props.location.state) || {};
    let generate_Id = generateId(10);
    this.setState({ isSpin: true, isGenerate: false });
    const uid = localStorage.getItem("in_userid");
    const data = this.state.signersdata;
    const transformedData = {
      signers: data.signers.map((signer) => ({
        name: signer.family_member_name,
        family_member_id: signer.family_member_id.toString(),
        signed_status: "U",
        sign_type: signer.esign_type,
        mobile: signer.mobile.toLowerCase(),
      })),
      witnesses: data.witnesses.map((witness) => ({
        name: witness.employee_name,
        employee_id: witness.employee_id.toString(),
        signed_status: "U",
        sign_type: witness.esign_type,
        mobile: witness.mobile.toLowerCase(),
      })),
    };
    transformedData.userid = uid;
    transformedData.loan_documentation_id = data.loan_documentation_id;

    const signersMobiles = transformedData.signers.map(
      (signer) => signer.mobile
    );
    const witnessesMobiles = transformedData.witnesses.map(
      (witness) => witness.mobile
    );

    const allMobiles = [...signersMobiles, ...witnessesMobiles];
    const uniqueMobiles = [...new Set(allMobiles)];

    const isMobilesUnique = allMobiles.length === uniqueMobiles.length;

    //console.log("transform=>", isMobilesUnique);
    if (isMobilesUnique) {
      // const { api_token } =
      //   (this.props.location && this.props.location.state) || {};
      //const formdata = new FormData();
      //formdata.append("loan_id", data.loan_documentation_id);
      let _body = JSON.stringify(transformedData);
      let generate_Id = generateId(10);

      let url;
      if (this.state.isDCB) {
        url = "esign/dcb/generate/document";
      } else {
        url = "create/firestore/loan";
      }

      customFetch(process.env.REACT_APP_URL + "/" + url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-token": api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
        body: _body,
      })
        .then((res) => {
          if (res.status != 200) {
            this.setState({
              isErrOpen: true,
              api_error_message: "Please Contact Techincal Team.",
              isSpin: false,
            });
          }
          if (res.headers.get("api_code") > 0) {
            this.setState({
              isErrOpen: true,
              api_error_message: res.headers.get("api_error_message"),
              isSpin: false,
            });
          }
          return res.json();
        })
        .then((json) => {
          if (
            json.api_code === 4 ||
            json.api_code === 3 ||
            json.api_code === 5
          ) {
            localStorage.clear();
            this.props.history.push("/");
          }
          this.setState({
            isProcessDoc: true,
            isErrOpen: true,
            api_error_message: json.message,
            isSpin: false,
          });
          this.getsigners();
        })
        .catch((error) => console.log("error", error));
    } else {
      this.setState({
        isErrOpen: true,
        api_error_message:
          "Mobile number or email should be unique for each signer and witness.",
        isSpin: false,
      });
    }
    // const requestOptions = {
    //   method: "GET",
    //   headers: {
    //     "api-token": api_token,
    //     component_id: generate_Id1,
    //     apiId: generate_Id,
    //     api_group_id: "",
    //     component: this.constructor.name,
    //     user_id: localStorage.getItem("in_userid"),
    //   },
    // };
    // customFetch(
    //   process.env.REACT_APP_URL +
    //     "/get/configuration/parameter/master?parameter_name=loan_doc_gen_stage",
    //   requestOptions
    // )
    //   .then((response) => {
    //     if (response.headers.get("api_code") > 0) {
    //       this.setState({
    //         isErrOpen: true,
    //         api_error_message: response.headers.get("api_error_message"),
    //         isSpin: false,
    //       });
    //     }
    //     return response.json();
    //   })
    //   .then((data) => {
    //     console.log("generateloandoc->", data);
    //     if (data) {
    //       let form = [
    //         [
    //           "household_block_data.householdDetails.household_number",
    //           "==",
    //           this.state.loan_id,
    //         ],
    //         ["status", "!=", parseInt(data.parameter_value)],
    //       ];
    //       const requestOptions = {
    //         method: "POST",
    //         body: JSON.stringify(form),
    //         headers: {
    //           "api-token": api_token,
    //           component_id: generate_Id1,
    //           apiId: generate_Id,
    //           api_group_id: "",
    //           component: this.constructor.name,
    //           user_id: localStorage.getItem("in_userid"),
    //           "Content-Type": "application/json",
    //         },
    //       };
    //       customFetch(
    //         process.env.REACT_APP_URL +
    //           "/mongo/get/callbacks/household_base_data",
    //         requestOptions
    //       )
    //         .then((response) => {
    //           if (response.headers.get("api_code") > 0) {
    //             this.setState({
    //               isErrOpen: true,
    //               api_error_message: response.headers.get("api_error_message"),
    //               isSpin: false,
    //             });
    //           }
    //           return response.json();
    //         })
    //         .then((data) => {
    //           if (data.length > 0) {
    //             const householdNumbers = data
    //               .map((item) => `${item.household_number}`)
    //               .join(", ");
    //             console.log("householdNumbers", householdNumbers);
    //             this.setState({
    //               isErrOpen: true,
    //               api_error_message:
    //                 householdNumbers + " Have Different Status",
    //               isSpin: false,
    //               isGenerate: false,
    //             });
    //           } else {
    //             this.setState({ isSpin: true, isGenerate: false });
    //             const uid = localStorage.getItem("in_userid");
    //             const data = this.state.signersdata;
    //             const transformedData = {
    //               signers: data.signers.map((signer) => ({
    //                 name: signer.family_member_name,
    //                 family_member_id: signer.family_member_id.toString(),
    //                 signed_status: "U",
    //                 sign_type: signer.esign_type,
    //                 mobile: signer.mobile.toLowerCase(),
    //               })),
    //               witnesses: data.witnesses.map((witness) => ({
    //                 name: witness.employee_name,
    //                 employee_id: witness.employee_id.toString(),
    //                 signed_status: "U",
    //                 sign_type: witness.esign_type,
    //                 mobile: witness.mobile.toLowerCase(),
    //               })),
    //             };
    //             transformedData.userid = uid;
    //             transformedData.loan_documentation_id =
    //               data.loan_documentation_id;

    //             const signersMobiles = transformedData.signers.map(
    //               (signer) => signer.mobile
    //             );
    //             const witnessesMobiles = transformedData.witnesses.map(
    //               (witness) => witness.mobile
    //             );

    //             const allMobiles = [...signersMobiles, ...witnessesMobiles];
    //             const uniqueMobiles = [...new Set(allMobiles)];

    //             const isMobilesUnique =
    //               allMobiles.length === uniqueMobiles.length;

    //             //console.log("transform=>", isMobilesUnique);
    //             if (isMobilesUnique) {
    //               const { api_token } =
    //                 (this.props.location && this.props.location.state) || {};
    //               //const formdata = new FormData();
    //               //formdata.append("loan_id", data.loan_documentation_id);
    //               let _body = JSON.stringify(transformedData);
    //               let generate_Id = generateId(10);

    //               let url;
    //               if (this.state.isDCB) {
    //                 url = "esign/dcb/generate/document";
    //               } else {
    //                 url = "create/firestore/loan";
    //               }

    //               customFetch(process.env.REACT_APP_URL + "/" + url, {
    //                 method: "POST",
    //                 headers: {
    //                   "Content-Type": "application/json",
    //                   "api-token": api_token,
    //                   component_id: generate_Id1,
    //                   apiId: generate_Id,
    //                   api_group_id: "",
    //                   component: this.constructor.name,
    //                   user_id: localStorage.getItem("in_userid"),
    //                 },
    //                 body: _body,
    //               })
    //                 .then((res) => {
    //                   if (res.status != 200) {
    //                     this.setState({
    //                       isErrOpen: true,
    //                       api_error_message: "Please Contact Techincal Team.",
    //                       isSpin: false,
    //                     });
    //                   }
    //                   if (res.headers.get("api_code") > 0) {
    //                     this.setState({
    //                       isErrOpen: true,
    //                       api_error_message: res.headers.get(
    //                         "api_error_message"
    //                       ),
    //                       isSpin: false,
    //                     });
    //                   }
    //                   return res.json();
    //                 })
    //                 .then((json) => {
    //                   if (
    //                     json.api_code === 4 ||
    //                     json.api_code === 3 ||
    //                     json.api_code === 5
    //                   ) {
    //                     localStorage.clear();
    //                     this.props.history.push("/");
    //                   }
    //                   this.setState({
    //                     isProcessDoc: true,
    //                     isErrOpen: true,
    //                     api_error_message: json.message,
    //                     isSpin: false,
    //                   });
    //                   this.getsigners();
    //                 })
    //                 .catch((error) => console.log("error", error));
    //             } else {
    //               this.setState({
    //                 isErrOpen: true,
    //                 api_error_message:
    //                   "Mobile number or email should be unique for each signer and witness.",
    //                 isSpin: false,
    //               });
    //             }
    //           }
    //         })
    //         .catch((error) => console.log("error", error));
    //     }
    //   })
    //   .catch((error) => console.log("error", error));
  };
  getmobile = (mid, type) => {
    if (type == "signers") {
      return this.state.signersdata.signers[mid].mobile;
    }
  };
  delete_doc = () => {
    this.setState({
      isSpin: true,
      isDelete: false,
    });
    // const { api_token } =
    //   (this.props.location && this.props.location.state) || {};
    const formdata = new FormData();
    let url;
    let _body;
    if (this.state.isDCB) {
      url = "esign/delete/document";

      _body = JSON.stringify({
        loan_documentation_id: this.state.signersdata.loan_documentation_id,
      });

      customFetch(process.env.REACT_APP_URL + "/" + url, {
        method: "POST",
        headers: {
          "api-token": api_token,
          "Content-Type": "application/json",
        },
        body: _body,
        //body: _body,
      })
        .then((res) => {
          if (res.status != 200) {
            this.setState({
              isErrOpen: true,
              api_error_message: "Please Contact Techincal Team.",
              isSpin: false,
            });
          }
          if (res.headers.get("api_code") > 0) {
            this.setState({
              isErrOpen: true,
              api_error_message: res.headers.get("api_error_message"),
              isSpin: false,
            });
          }
          return res.json();
        })
        .then((json) => {
          if (
            json.api_code === 4 ||
            json.api_code === 3 ||
            json.api_code === 5
          ) {
            localStorage.clear();
            this.props.history.push("/");
          }
          this.setState({
            isErrOpen: true,
            api_error_message: json.message,
            isSpin: false,
          });
          this.getsigners();
        })
        .catch((error) => console.log("error", error));
    } else {
      url = "move/block/deleted";
      formdata.append("loan_id", this.state.signersdata.loan_documentation_id);

      customFetch(process.env.REACT_APP_URL + "/" + url, {
        method: "POST",
        headers: {
          "api-token": api_token,
        },
        body: formdata,
        //body: _body,
      })
        .then((res) => {
          if (res.status != 200) {
            this.setState({
              isErrOpen: true,
              api_error_message: "Please Contact Techincal Team.",
              isSpin: false,
            });
          }
          if (res.headers.get("api_code") > 0) {
            this.setState({
              isErrOpen: true,
              api_error_message: res.headers.get("api_error_message"),
              isSpin: false,
            });
          }
          return res.json();
        })
        .then((json) => {
          if (
            json.api_code === 4 ||
            json.api_code === 3 ||
            json.api_code === 5
          ) {
            localStorage.clear();
            this.props.history.push("/");
          }
          this.setState({
            isErrOpen: true,
            api_error_message: json.message,
            isSpin: false,
          });
          this.getsigners();
        })
        .catch((error) => console.log("error", error));
    }
  };
  esign_doc = () => {
    this.setState({
      isSpin: true,
    });
    // const { api_token } =
    //   (this.props.location && this.props.location.state) || {};
    const formdata = new FormData();
    // formdata.append(
    //   "loan_documentation_id",
    //   this.state.signersdata.loan_documentation_id
    // );
    let _body = JSON.stringify({
      loan_documentation_id: this.state.signersdata.loan_documentation_id,
    });
    let url;
    if (this.state.isDCB == 1) {
      url = process.env.REACT_APP_URL + "/esign/leegality/document/send";
    } else {
      url = process.env.REACT_APP_URL + "/esign/document/send";
    }
    customFetch(url, {
      method: "POST",
      headers: {
        "api-token": api_token,
        "Content-Type": "application/json",
      },
      body: _body,
    })
      .then((res) => {
        if (res.status != 200) {
          this.setState({
            isErrOpen: true,
            api_error_message: "Please Contact Techincal Team.",
            isSpin: false,
          });
        }
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return res.json();
      })
      .then((json) => {
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          isErrOpen: true,
          api_error_message: json.message,
          isSpin: false,
        });
        this.getsigners();
      })
      .catch((error) => console.log("error", error));
  };

  callback(response) {
    if (response.error) {
      // document.getElementById("message").style.color = "red";
      // document.getElementById("message").innerHTML = response.error;
      console.log("callback error", response.error);
    } else {
      // document.getElementById("message").style.color = "green";
      // document.getElementById("message").innerHTML = response.message;
      console.log("callback message", response.message);
    }
  }
  callenque = (signdata, signers) => {
    // const { api_token } =
    //   (this.props.location && this.props.location.state) || {};
    const formdata = new FormData();
    // formdata.append(
    //   "loan_documentation_id",
    //   this.state.signersdata.loan_documentation_id
    // );
    let _body = {
      payload: {
        messages: [{ message: signdata.message }],
        documentId: signdata.documentId,
        request: {
          name: signers.family_member_name,
          phone: signers.mobile,
        },
        vendor_name: "Leegality",
        event: "esign",
      },
      topic: "Transformation",
    };
    customFetch(process.env.REACT_APP_URL + "/enque/", {
      method: "POST",
      headers: {
        "api-token": api_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(_body),
    })
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return res.json();
      })
      .then((json) => {
        console.log(json);
      })
      .catch((error) => console.log("error", error));
  };
  openPopup = (signers) => {
    let obj = {
      logoUrl:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKoCImzRGYjIXBOLGvldo_SKveUrqk1OiXhIg90e5XGQ&s",
      callback: (response) => {
        if (response.error) {
          console.log("Flow cancelled by user", response.error);
          return;
        }
        if (response.message) {
          this.callenque(response, signers);
        }
        console.log(response);
        this.getsigners();
      },
    };

    console.log("url", signers.signUrl);
    var leegality = new window.Leegality(obj);
    leegality.init();
    //You will get a signing url by uploading document from backend.
    //var signingUrl = “your-signing-url”;
    leegality.esign(signers.signUrl);
  };
  render() {
    const { facingMode } = this.state;

    console.log("this.state.signersdata", this.state.signersdata);
    return (
      <>
        {this.state.isSpin ? (
          <div className="preloader">
            <div className="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}

        <div className="container">
          <div
            className={
              this.state.isErrOpen
                ? "modal fade bd-example-modal-sm show mymodal"
                : "modal fade bd-example-modal-sm"
            }
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
            id="confirm-box"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content cdb-confirm-box col-sm-12">
                <button
                  type="button"
                  className="close text-right"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.setState({ isErrOpen: false })}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <p>{this.state.api_error_message}</p>
              </div>
            </div>
          </div>
          <div
            className={
              this.state.isDelete
                ? "modal fade bd-example-modal-sm show mymodal"
                : "modal fade bd-example-modal-sm"
            }
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
            id="confirm-box"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content cdb-confirm-box col-sm-12">
                <button
                  type="button"
                  className="close text-right"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.setState({ isDelete: false })}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <p>Are you sure to delete document ?</p>
                <div className="cb-buttons">
                  <div className="col-sm-6">
                    <button
                      className="btn btn-primary btn-lg btn-block rbtn"
                      onClick={() => {
                        this.delete_doc();
                      }}
                      //disabled={isDisableBtn}
                    >
                      Yes
                    </button>
                  </div>
                  <div className="col-sm-6">
                    <button
                      data-dismiss="modal"
                      type="button"
                      onClick={() => this.setState({ isDelete: false })}
                      className="btn btn-primary btn-lg btn-block rbtn"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              this.state.isGenerate
                ? "modal fade bd-example-modal-sm show mymodal"
                : "modal fade bd-example-modal-sm"
            }
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
            id="confirm-box"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content cdb-confirm-box col-sm-12">
                <button
                  type="button"
                  className="close text-right"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.setState({ isGenerate: false })}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <p>After Generate Document data can not be change</p>
                <div className="cb-buttons">
                  <div className="col-sm-6">
                    <button
                      className="btn btn-primary btn-lg btn-block rbtn"
                      onClick={() => {
                        this.generateloandoc();
                      }}
                      //disabled={isDisableBtn}
                    >
                      Confirm
                    </button>
                  </div>
                  <div className="col-sm-6">
                    <button
                      data-dismiss="modal"
                      type="button"
                      onClick={() => this.setState({ isGenerate: false })}
                      className="btn btn-primary btn-lg btn-block rbtn"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              this.state.isDownload
                ? "modal fade bd-example-modal-sm show mymodal"
                : "modal fade bd-example-modal-sm"
            }
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
            id="confirm-box"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content cdb-confirm-box col-sm-12">
                <button
                  type="button"
                  className="close text-right"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.setState({ isDownload: false })}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                {this.state.signersdata != "" && (
                  <>
                    <button
                      disabled={
                        this.state.signersdata.document.mid_doc_file_path ==
                        null
                      }
                      className={
                        this.state.signersdata.document.mid_doc_file_path ==
                        null
                          ? "generategrey-btn"
                          : "generate-btn"
                      }
                      onClick={() =>
                        this.getloandoc(
                          this.state.signersdata.document &&
                            this.state.signersdata.document.mid_doc_file_path
                              .split("/")
                              .pop(),
                          this.state.signersdata.document.mid_doc_file_path
                        )
                      }
                    >
                      Download MID
                    </button>
                    <button
                      disabled={
                        this.state.signersdata.document.blob_url == null
                      }
                      className={
                        this.state.signersdata.document.blob_url == null
                          ? "generategrey-btn"
                          : "generate-btn"
                      }
                      onClick={() =>
                        this.getloandoc(
                          this.state.signersdata.document &&
                            this.state.signersdata.document.blob_url
                              .split("/")
                              .pop(),
                          this.state.signersdata.document.blob_url
                        )
                      }
                    >
                      Download Unsigned Doc
                    </button>
                    {this.state.isDCB == 1 ? (
                      <>
                        <button
                          className={
                            this.state.signersdata.signers.every(
                              (signer) => signer.signing_status
                            ) === false ||
                            this.state.signersdata.witnesses.every(
                              (witnesses) => witnesses.signing_status
                            ) === false
                              ? "generategrey-btn"
                              : "generate-btn"
                          }
                          disabled={
                            this.state.signersdata.signers.every(
                              (signer) => signer.signing_status
                            ) === false ||
                            this.state.signersdata.witnesses.every(
                              (witnesses) => witnesses.signing_status
                            ) === false
                          }
                          onClick={() =>
                            this.downloadesigndoc(
                              this.state.signersdata.loan_documentation_id,
                              "DOCUMENT"
                            )
                          }
                        >
                          Download E-signed Document{" "}
                        </button>
                        <button
                          className={
                            this.state.signersdata.signers.every(
                              (signer) => signer.signing_status
                            ) === false ||
                            this.state.signersdata.witnesses.every(
                              (witnesses) => witnesses.signing_status
                            ) === false
                              ? "generategrey-btn"
                              : "generate-btn"
                          }
                          disabled={
                            this.state.signersdata.signers.every(
                              (signer) => signer.signing_status
                            ) === false ||
                            this.state.signersdata.witnesses.every(
                              (witnesses) => witnesses.signing_status
                            ) === false
                          }
                          onClick={() =>
                            this.downloadesigndoc(
                              this.state.signersdata.loan_documentation_id,
                              "AUDIT_TRAIL"
                            )
                          }
                        >
                          Download E-signed TRAIL{" "}
                        </button>
                      </>
                    ) : (
                      <button
                        className={
                          this.state.signersdata.signers.every(
                            (signer) => signer.signing_status
                          ) === false ||
                          this.state.signersdata.witnesses.every(
                            (witnesses) => witnesses.signing_status
                          ) === false
                            ? "generategrey-btn"
                            : "generate-btn"
                        }
                        disabled={
                          this.state.signersdata.signers.every(
                            (signer) => signer.signing_status
                          ) === false ||
                          this.state.signersdata.witnesses.every(
                            (witnesses) => witnesses.signing_status
                          ) === false
                        }
                        onClick={() =>
                          this.downloaddocument(
                            this.state.signersdata.loan_documentation_id
                          )
                        }
                      >
                        Download E-signed Document{" "}
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            className={
              this.state.OpenCam
                ? "modal fade bd-example-modal-sm show mymodal"
                : "modal fade bd-example-modal-sm"
            }
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
            id="confirm-box"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content pt-confirm-box col-sm-12">
                {this.state.OpenCam && (
                  <>
                    <div className="webcam">
                      <div>
                        <Webcam
                          audio={false}
                          mirrored={true}
                          ref={this.webcamRef}
                          screenshotFormat="image/jpeg"
                          videoConstraints={{
                            ...videoConstraints,
                            facingMode,
                          }}
                        />
                        {/* AT-1739 | AT-2199 | Ankur Makavana | 19-07-2023 | Enable back camera selection */}
                        <button
                          className="btn btn-primary change_cam"
                          onClick={() => this.change_cam()}
                        >
                          <img
                            className="switch-camera"
                            src={require("../assets/images/camera-switch.png")}
                            alt=""
                          />
                        </button>
                      </div>
                      <div>
                        {this.state.tempImg && (
                          <img
                            className="captured-img"
                            src={this.state.tempImg}
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                    <button
                      className="btn btn-primary btn-lg btn-block rbtn mt-3"
                      onClick={this.capture}
                    >
                      Capture Image
                    </button>
                  </>
                )}
                <div className="cb-buttons">
                  <div className="col-sm-6">
                    {this.state.tempImg && (
                      <button
                        className="btn btn-primary btn-lg btn-block rbtn"
                        onClick={() => {
                          this.setcaptureimg();
                        }}
                        //disabled={isDisableBtn}
                      >
                        Confirm
                      </button>
                    )}
                  </div>
                  <div className="col-sm-6">
                    <button
                      data-dismiss="modal"
                      type="button"
                      onClick={() => this.setState({ OpenCam: false })}
                      className="btn btn-primary btn-lg btn-block rbtn"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              this.state.isdocid
                ? "modal fade bd-example-modal-sm show mymodal"
                : "modal fade bd-example-modal-sm"
            }
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
            id="confirm-box"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content pt-confirm-box col-sm-12">
                <button
                  type="button"
                  className="close text-right"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.setState({ isdocid: false })}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <p>Please Generate Document.</p>
              </div>
            </div>
          </div>
          <div className="payload_view_navbar">
            <button
              className="button back_button"
              onClick={() => this.props.history.goBack()}
            >
              <img src={require("../assets/images/back.png")} alt="" />
            </button>
            <h4>E sign</h4>
            <button className="button" onClick={() => logout()}>
              <img src={require("../assets/images/power-off.png")} alt="" />
            </button>
          </div>
          <div className="card-holdermain">
            <PEDiv element_id="EsignSearchLoanid" className="search-holder">
              <label className="search-label">Enter Loan ID</label>
              <div className="searchinput row">
                <div className="col-md-10 mt-1">
                  <input
                    className="form-control search-input"
                    name="loan_id"
                    value={this.state.loan_id}
                    onChange={(e) => this.setState({ loan_id: e.target.value })}
                  />
                </div>
                <div className="col-md-2 mt-1">
                  <button
                    className="search-btn"
                    onClick={() => this.getsigners()}
                  >
                    Fetch
                  </button>
                </div>
              </div>
            </PEDiv>
            <div className="main-container mt-4">
              <main className="card-container">
                {this.state.signersdata.signers &&
                  this.state.signersdata.signers.map((signers, index) => (
                    <>
                      <div className="card-holder mt-2" key={index}>
                        <figure>
                          <img
                            // AT-2109 | AT-2156 | Ankur Makavana | 10-07-2023 | replace blob_url with public filepath
                            src={
                              this.state[
                                `member_${signers.family_member_id}`
                              ] &&
                              this.state[`member_${signers.family_member_id}`]
                            }
                            alt=""
                          />
                          {this.state[
                            `memberImg_${signers.family_member_id}`
                          ] && (
                            <strong
                              className={
                                this.state[
                                  `memberImg_${signers.family_member_id}`
                                ] > 2
                                  ? "clrred"
                                  : "clrgreen"
                              }
                            >
                              {this.state[
                                `memberImg_${signers.family_member_id}`
                              ] + " MB"}
                            </strong>
                          )}
                        </figure>

                        <span
                          className={
                            this.state.signersdata.document.document_status ==
                            true
                              ? "disabledbutton"
                              : ""
                          }
                          onClick={() =>
                            this.setState({
                              OpenCam: true,
                              family_member_id: signers.family_member_id,
                              tempImg: "",
                            })
                          }
                        >
                          <img
                            src={require("../assets/images/camera.png")}
                            alt=""
                          />
                        </span>
                        <div className="applicant-info">
                          <div className="hhnamelabel">
                            {signers.flag == "B" && "Applicant Name"}
                            {signers.flag == "C" && "Co Applicant Name"} :
                          </div>
                          <div className="hhname">
                            {signers.family_member_name}
                          </div>
                          <div className="hhnumber">
                            Mo:{" "}
                            {this.state[
                              `hhmobileedit_${signers.family_member_id}`
                            ] == 1 ? (
                              <input
                                className="form-control"
                                value={
                                  this.state[
                                    `hhmobile_${signers.family_member_id}`
                                  ] == signers.mobile
                                    ? this.state[
                                        `hhmobile_${signers.family_member_id}`
                                      ]
                                    : signers.mobile
                                }
                                defaultValue={signers.mobile}
                                onChange={(e) =>
                                  this.changehhmobile(
                                    e,
                                    signers.family_member_id,
                                    index
                                  )
                                }
                                onBlur={() =>
                                  this.setState({
                                    [`hhmobileedit_${signers.family_member_id}`]: 0,
                                  })
                                }
                              />
                            ) : (
                              signers.mobile
                            )}
                            <img
                              className={
                                this.state.signersdata.document
                                  .document_status == true
                                  ? "iconed disabledbutton"
                                  : "iconed"
                              }
                              onClick={() =>
                                this.editmobile(signers.family_member_id)
                              }
                              src={require("../assets/images/number-edit.png")}
                              alt=""
                            />
                            <p className="errmsg">
                              {
                                this.state[
                                  `Errormobile_${signers.family_member_id}`
                                ]
                              }
                            </p>
                          </div>
                          {/* <span className="iconed">
                    </span> */}
                        </div>
                        <section className="imgs">
                          {this.state.isDCB == 1 ? (
                            signers.signUrl &&
                            signers.signing_status != true ? (
                              <button
                                className="search-btn p-1"
                                onClick={() => this.openPopup(signers)}
                              >
                                Sign
                              </button>
                            ) : (
                              <button
                                className="search-btn disabledbutton p-1"
                                onClick={() => this.openPopup(signers)}
                              >
                                Sign
                              </button>
                            )
                          ) : (
                            signers.esign_method.map((esign, index1) => (
                              <>
                                <p
                                  key={index1}
                                  className={
                                    signers.signing_status === true ||
                                    this.state.signersdata.document
                                      .document_id === null ||
                                    (this.state.signingStatusWithFlagB ===
                                      false &&
                                      signers.flag != "B")
                                      ? "circle disabledbutton"
                                      : "circle"
                                  }
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.initDigio(
                                      this.state.signersdata.document
                                        .document_id,
                                      this.getmobile(index, "signers"),
                                      this.state.signersdata.document
                                        .access_token,
                                      esign.method
                                    );
                                  }}
                                >
                                  {esign.family_member_id}
                                  <aside className="sideimg">
                                    <img
                                      src={require("../assets/images/plus-sign.png")}
                                      alt=""
                                    />
                                  </aside>

                                  {esign.type == "aadhaar" && (
                                    <div>
                                      <div className="img1">
                                        <img
                                          src={require("../assets/images/aadhaar.png")}
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {esign.type == "electronic" && (
                                    <div>
                                      <div className="img1">
                                        <img
                                          src={require("../assets/images/aadhaar-mobile.png")}
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {esign.method == "biometric" && (
                                    <>
                                      <aside className="botimg">
                                        <img
                                          src={require("../assets/images/fingerprint.png")}
                                          alt=""
                                        />
                                      </aside>
                                      {signers.signing_status === true && (
                                        <aside className="botimg2">
                                          <img
                                            src={require("../assets/images/accept.png")}
                                            alt=""
                                          />
                                        </aside>
                                      )}
                                    </>
                                  )}
                                  {(esign.method == "otp" ||
                                    esign.method == "electronic") && (
                                    <>
                                      <aside className="botimg">
                                        <img
                                          src={require("../assets/images/aadhaar-mobile.png")}
                                          alt=""
                                        />
                                      </aside>
                                      {signers.signing_status === true && (
                                        <aside className="botimg2">
                                          <img
                                            src={require("../assets/images/accept.png")}
                                            alt=""
                                          />
                                        </aside>
                                      )}
                                    </>
                                  )}
                                </p>
                              </>
                            ))
                          )}
                        </section>
                        {this.state[`memberImg_${signers.family_member_id}`] >
                        2 ? (
                          <strong className="clrred col-md-12 text-center">
                            Please upload image smaller than 2MB.
                          </strong>
                        ) : (
                          ""
                        )}
                        {signers.esign_errors && <div>{signers.esign_errors.map((err)=><p className="alert alert-danger">{err}</p>)}</div>}
                      </div>
                    </>
                  ))}
                {this.state.signersdata.witnesses &&
                  this.state.signersdata.witnesses.map((signers, index) => (
                    <>
                      {/* <label>witnesses</label> */}
                      <div className="card-holder mt-2" key={index}>
                        <figure>
                          <img
                            src={require("../assets/images/default-user.png")}
                            alt=""
                          />
                        </figure>
                        <span>
                          {/* <img
                            src={require("../assets/images/camera.png")}
                            alt=""
                          /> */}
                        </span>
                        <div className="applicant-info">
                          <div className="hhnamelabel">Witnesses Name :</div>
                          <div className="hhname">{signers.employee_name}</div>
                          <div className="hhnumber">
                            Mo:{" "}
                            {this.state[
                              `hhmobileedit_${signers.employee_id}`
                            ] == 1 ? (
                              <input
                                className="form-control"
                                value={
                                  this.state[
                                    `hhmobile_${signers.employee_id}`
                                  ] == signers.mobile
                                    ? this.state[
                                        `hhmobile_${signers.employee_id}`
                                      ]
                                    : signers.mobile
                                }
                                defaultValue={signers.mobile}
                                onChange={(e) =>
                                  this.changewitnessesmobile(
                                    e,
                                    signers.employee_id,
                                    index
                                  )
                                }
                                onBlur={() =>
                                  this.setState({
                                    [`hhmobileedit_${signers.employee_id}`]: 0,
                                  })
                                }
                              />
                            ) : (
                              signers.mobile
                            )}
                            <img
                              onClick={() =>
                                this.editmobile(signers.employee_id)
                              }
                              className={
                                this.state.signersdata.document
                                  .document_status == true
                                  ? "iconed disabledbutton"
                                  : "iconed"
                              }
                              src={require("../assets/images/number-edit.png")}
                              alt=""
                            />
                            <p className="errmsg">
                              {this.state[`Errormobile_${signers.employee_id}`]}
                            </p>
                          </div>
                          {/* <span className="iconed">
                    </span> */}
                        </div>
                        <section className="imgs">
                          {this.state.isDCB == 1 ? (
                            signers.signUrl &&
                            signers.signing_status != true ? (
                              <button
                                className="search-btn p-1"
                                onClick={() => this.openPopup(signers)}
                              >
                                Sign
                              </button>
                            ) : (
                              <button
                                className="search-btn disabledbutton p-1"
                                onClick={() => this.openPopup(signers)}
                              >
                                Sign
                              </button>
                            )
                          ) : (
                            signers.esign_method.map((esign, index2) => (
                              <>
                                <p
                                  key={index2}
                                  className={
                                    signers.signing_status === true ||
                                    this.state.signersdata.document
                                      .document_id === null ||
                                    (this.state.signingStatusWithFlagB ===
                                      false &&
                                      signers.flag != "B")
                                      ? "circle disabledbutton"
                                      : "circle"
                                  }
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.initDigio(
                                      this.state.signersdata.document
                                        .document_id,
                                      signers.mobile,
                                      this.state.signersdata.document
                                        .access_token,
                                      esign.method
                                    );
                                  }}
                                >
                                  <aside className="sideimg">
                                    <img
                                      src={require("../assets/images/plus-sign.png")}
                                      alt=""
                                    />
                                  </aside>

                                  {esign.type == "aadhaar" && (
                                    <div>
                                      <div className="img1">
                                        <img
                                          src={require("../assets/images/aadhaar.png")}
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {esign.type == "electronic" && (
                                    <div>
                                      <div className="img1">
                                        <img
                                          src={require("../assets/images/aadhaar-mobile.png")}
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {esign.method == "biometric" && (
                                    <>
                                      <aside className="botimg">
                                        <img
                                          src={require("../assets/images/fingerprint.png")}
                                          alt=""
                                        />
                                      </aside>
                                      {signers.signing_status === true && (
                                        <aside className="botimg">
                                          <img
                                            src={require("../assets/images/accept.png")}
                                            alt=""
                                          />
                                        </aside>
                                      )}
                                    </>
                                  )}
                                  {(esign.method == "otp" ||
                                    esign.method == "electronic") && (
                                    <>
                                      <aside className="botimg">
                                        <img
                                          src={require("../assets/images/aadhaar-mobile.png")}
                                          alt=""
                                        />
                                      </aside>
                                      {signers.signing_status === true && (
                                        <aside className="botimg">
                                          <img
                                            src={require("../assets/images/accept.png")}
                                            alt=""
                                          />
                                        </aside>
                                      )}
                                    </>
                                  )}
                                </p>
                              </>
                            ))
                          )}
                        </section>
                        {this.state[`memberImg_${signers.family_member_id}`] >
                        2 ? (
                          <strong className="clrred col-md-12 text-center">
                            Please upload image smaller than 2MB.
                          </strong>
                        ) : (
                          ""
                        )}
                        {signers.esign_errors && <div>{signers.esign_errors.map((err)=><p className="alert alert-danger">{err}</p>)}</div>}
                      </div>
                    </>
                  ))}
              </main>
              {this.state.signersdata != "" && (
                <div className="generate text-center">
                  {!this.state.signersdata.document.document_id && (
                    <PEButton element_id="EsignGenerateBtn"
                      disabled={
                        this.state.signersdata.document.document_status ==
                          true || this.state.isProcessDoc
                      }
                      className={
                        this.state.signersdata.document.document_status ==
                          true || this.state.isProcessDoc
                          ? "generategrey-btn"
                          : "generate-btn"
                      }
                      onClick={() => this.setState({ isGenerate: true })}
                    >
                      {this.state.isProcessDoc ? (
                        <img
                          src={require("../assets/images/loader-inf.gif")}
                          className="inf_loader"
                          alt=""
                        />
                      ) : (
                        "Generate"
                      )}
                    </PEButton>
                  )}
                  <PEButton element_id="EsignDownloadBtn"
                    disabled={
                      this.state.signersdata.document.mid_doc_file_path ==
                        null && this.state.signersdata.document.blob_url == null
                    }
                    className={
                      this.state.signersdata.document.mid_doc_file_path ==
                        null && this.state.signersdata.document.blob_url == null
                        ? "generategrey-btn"
                        : "generate-btn"
                    }
                    onClick={() => this.setState({ isDownload: true })}
                  >
                    Download Document
                  </PEButton>
                  <PEButton element_id="EsignDeleteBtn"
                    disabled={!this.state.signersdata.document.document_status}
                    className={
                      !this.state.signersdata.document.document_status
                        ? "generategrey-btn"
                        : "generate-btn"
                    }
                    onClick={() => this.setState({ isDelete: true })}
                  >
                    Delete Document
                  </PEButton>
                  {!this.state.signersdata.document.document_id && (
                    <PEButton element_id="EsignBtn"
                      disabled={
                        this.state.signersdata.document.blob_url == null
                      }
                      className={
                        this.state.signersdata.document.blob_url == null
                          ? "generategrey-btn"
                          : "generate-btn"
                      }
                      onClick={() => this.esign_doc()}
                    >
                      E-sign
                    </PEButton>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Esign;
