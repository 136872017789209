import React, { Component, useEffect, useState } from "react";
import customFetch from "./apicall/api";
import DetailComponent from "./PayloadviewDetail";
import { filterData, logout, generateId } from "./Utility";
import {
  get_payload_views,
  update_payload,
  get_mongo_payload,
  update_mongopayload,
  delete_mongofield,
  update_mongogrouppayload,
  insert_mongopayload,
  cache_payload_data,
} from "./Services";
import {
  Select,
  Modal,
  Button,
  Input,
  DatePicker,
  Upload,
  message,
  Image,
} from "antd";
import {
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  SettingOutlined,
  ReloadOutlined,
  FileAddOutlined,
  FileExcelOutlined,
  UploadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { ThemeContext } from "../context/IsLoader";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "../assets/css/payload_view.css";
import {
  QueryBuilder,  
  ValueEditor,
  ValueEditorProps,
  Rule,
} from "react-querybuilder";
import 'react-querybuilder/dist/query-builder.css';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import ReactJson from "react-json-view";
import { WidthProvider, Responsive } from "react-grid-layout";
import _, { partial, result } from "lodash";
import "../assets/css/styles.css";
import "../assets/css/example-styles.css";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import DragIcon from "../assets/images/draggable.png";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import * as Icons from "react-icons/bs";

const { RangePicker } = DatePicker;
const ResponsiveReactGridLayout = WidthProvider(Responsive);
const originalLayouts = {};

const { TextArea } = Input;
const dateFormatList = "DD/MM/YYYY";
const generate_Id1 = generateId(10);
export default class PayloadView extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);

    this.state = {
      expandedSections: {}, // Keys as unique identifiers, values as true/false
      payload_config: props.location.state.payload_config,
      defaultpayload_config: props.location.state.payload_config,
      api_token: props.location.state.api_token,
      effective_designation_id: props.location.state.effective_designation_id,
      payload_data: [],
      payloadglobal_data: [],
      init_outputdata: [],
      disabled: false,
      isspin: false,
      isLoginuser: props.location.state.isLoginuser,
      sortOrder: "asc",
      otherScreen: props.location.state.otherScreen,
      no_of_page: "",
      filterDate: "",
      selectDate: "",
      isDateSel: "",
      DateSelMessage: false,
      view_name: props.location.state.view_name,
      search_fields: [],
      search_click: 0,
      isExporting: false,
      subpayload_config: "",
      unique_groups: "",
      filters: {},
      supervisor_branch_code: "",
      isSettings: false,
      HeaderData: [],
      checkedCheckboxes: [],
      Reasonlist: [],
      Reasonlist_default: [],
      MassReasonlist: [],
      massUpdateData: [],
      selectedMassUpdateData: [],
      massUpdateInput: [],
      defaultCheckbox: true,
      isMassupdate: false,
      group_id: "",
      DataForMassUpdate: [],
      CheckAll: false,
      load_more: 0,
      viewfilter: "",
      searchconfig:
        props.location.state.payload_config.payload_type == "M"
          ? props.location.state.payload_config.field_mapping[0]
              .search_query || {
              combinator: "and",
              not: "False",
              rules: [],
            }
          : [],
      defaultsearchconfig:
        props.location.state.payload_config.payload_type == "M"
          ? props.location.state.payload_config.field_mapping[0]
              .search_query || {
              combinator: "and",
              not: "False",
              rules: [],
            }
          : [],
      filtername: "",
      savedFilter: "",
      filtercrtupd: "",
      isMobile: false,
      query: { combinator: "and", rules: [] },
      Operators: [
        { name: "=", label: "=" },
        { name: "!=", label: "!=" },
        { name: ">", label: ">" },
        { name: "<", label: "<" },
        { name: ">=", label: ">=" },
        { name: "<=", label: "<=" },
        { name: "exists", label: "Exists" },
        { name: "TDHG", label: "Time Different > (In Hour)" },
        { name: "TDHL", label: "Time Different < (In Hour)" },
        { name: "NDaysBefore", label: "N days before" },
        { name: "lastNDays", label: "last N days" },
        {
          name: "isNthDayBeforeToday",
          label: "is exactly N days before today",
        },
        { name: "nextNDays", label: "next N days" },
        { name: "NDaysAfter", label: "N days after" },
      ],
      disable_loadmore: false,
      disable_loadmore: false,
      disable_loadmore: false,
      layouts: JSON.parse(JSON.stringify(originalLayouts)),
      bodyLayouts: JSON.parse(JSON.stringify(originalLayouts)),
      isGetFirstdata: 0,
      CheckAll: false,
      isMassupdclick: false,
      isError: false,
      isErrorMessage: "",
      filter_name: "",
      dropdowncondition: [],
      isMobileChecked: false,
      mobilequery: "",
      isVisibleEdit: true,
      isMassUpdatePopup: false,
      updateUniqueData: [],
      sub_data: [],
      sumDetail: false,
      currentPosition: {
        xRate: 254.06586456298828,
        yRate: -511.6484088897705,
      },
      isExprow: false,
      ExpandedRow: [],
      removedCheckboxes: [],
      fieldsumm: 0,
      partialAmt: false,
      totalSum: 0,
      partial_amount: "",
      morethan_collection: "",
      partial_amt_by_cso: "",
      remainingSum: 0,
      editfieldsumm: 0,
      showSearch: false,
      removedCheckboxesid:[],
      editval:false,
      change_val:{},
      updpayload:[],
      conditional_update: [],
      dateRangeFilters: {},
      filtered_data: [],
      process_err:false,
      updatedPayloadData: [], // State from Child
    };
    this.getColor = this.getColor.bind(this);
  }

  updateComponentGlobalState = (newState,callback) => {
    console.log("Updating Global State:", newState);
  
    this.setState((prevState) => {
      const updatedGlobalState = {
        ...prevState.componentGlobalState,
        ...newState,
      };
      console.log("Updated Global State:", updatedGlobalState.pettycash); // Added console log here
      return {
        componentGlobalState: updatedGlobalState,
      };
    }, () => {
      // State has been updated, now call the callback
      if (callback) callback();
    });
  };

  toggleSection = (rowindex) => {
    this.setState((prevState) => ({
      expandedSections: {
        ...prevState.expandedSections,
        [rowindex]: !prevState.expandedSections[rowindex],
      },
    }));
  };

  componentDidMount() {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobiledata = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(
      userAgent
    );
    this.setState({ isMobile: isMobiledata });
    console.log("isMobile", Array.isArray(this.state.searchconfig));
    if(this.state.payload_config.payload_type == "A")
    {
      this.state.payload_config.datasource[0].formdata.map((frmdata)=>{
        if(frmdata.manual_input=="1"){
          if(frmdata.getdata_from !=""){
            this.selecapiinputdata(frmdata)
              .then((data) => {
                this.setState({ [frmdata.formdatakey]: data });
              })
              .catch((error) => {
                console.error("Error:", error);
                this.setState({ loading: false });
              });
          }
        }
      })
      if(this.state.search_click==1){

      }
    }

    const filtersearch = this.props.location.state.payload_config.field_mapping.filter(
      (item) => item.isSearch === "1"
    );    
    const filtercustom_field =
    this.props.location.state.payload_config.field_mapping.filter(
      (item) => item.custom_field === "1"
    );

    filtercustom_field.forEach((item) => {

      if(item.isglobal_api_call == "1"){
        this.getdatafromAPI(item,item.global_api_call).then((namesData) => {
          //console.log(namesData);
          console.log("apiresponse",namesData); 
        })
        .catch((error) => {
          console.error('Error:', error);
        });                       
      }
      this.updateComponentGlobalState({ [item.BucketField]: item.custom_defaultval });
    })

    const hasEditableElement = this.state.payload_config.field_mapping.some(
      (item) => item.isEdit === "1"
    );
    this.setState({ isVisibleEdit: hasEditableElement });
    console.log("payload_config", this.state);
    const extractedDataArray = [];
    if (this.props.location.state.payload_config.payload_type == "M") {
      filtersearch.forEach((item) => {
        const name = item.BucketField;
        const label = item.fieldname;
        const datatype = item.datatype;

        // Push the extracted data as an object into the array
        extractedDataArray.push({ name, label, datatype });
      });
      //setsearch_fields(extractedDataArray);
      this.setState({ search_fields: extractedDataArray });
    } else {
      this.setState({ search_fields: filtersearch });
    }

    setTimeout(() => {
      if (document.readyState === "complete") {
        this.context.onPageLoad({
          component: "PaylodView",
          component_id: generate_Id1,
        });
      } else {
        window.addEventListener("load", this.context.onPageLoad());
        this.cleanup = () =>
          window.removeEventListener("load", this.context.onPageLoad());
      }
    }, 1000);

    if (this.state.supervisor_branch_code == "") {
      let generate_Id = generateId(10);
      const params = {
        userid: localStorage.getItem("in_userid"),
      };
      const queryParams = new URLSearchParams(params).toString();
      const requestOptions = {
        method: "GET",
        headers: {
          "api-token": this.state.api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: "PaylodView",
          user_id: localStorage.getItem("in_userid"),
        },
      };
      // AT-2109 | AT-2156 | Ankur Makavana | 10-07-2023 | replace blob_url with public filepath
      const Reasonlist = customFetch(
        process.env.REACT_APP_URL + "/getrole?" + queryParams,
        requestOptions
      )
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          
          this.setState(
            {
              supervisor_branch_code: json.branch_code,
              contextuser_branchid: json.branch_name,
            },
            () => {
              
              if (this.state.filterDate=="") {
                let inputDate = moment()
                  .subtract(6, "months")
                  .format("YYYY-MM-DD");
                this.setState(
                  {
                    filterDate: moment(inputDate).unix(),
                    defaultdate: inputDate,
                  },
                  () => {
                    this.getPayloads();
                  }
                );
              }
            }
          );
        });
    }
    
    const fetchData = async () => {
      try {
        for (const field of this.state.payload_config.field_mapping) {
          if (field.addtosum && field.addtosum == "1") {
            
            this.setState({ addtosum: field });
          }

          if (field.datatype === "dropdownlist") {
            const generate_Id = generateId(10);
            const requestOptions = {
              method: "GET",
              headers: {
                "api-token": this.state.api_token,
                component_id: generate_Id1,
                apiId: generate_Id,
                api_group_id: "",
                component: "PaylodView",
                user_id: localStorage.getItem("in_userid"),
              },
            };

            let combinedResult = [];
            let uniqueItems = new Map(); // Use Map to ensure uniqueness by item_id

            if (Array.isArray(field.dropdownlist)) {
              // If dropdownlist is an array, loop through each value
              for (const dropdownId of field.dropdownlist) {
                try {
                  const response = await customFetch(
                    `${process.env.REACT_APP_URL}/get/dropdown/item/list?dropdown_id=${dropdownId}`,
                    requestOptions
                  );
                  const result = await response.json();

                  

                  // Add each item to the Map to ensure uniqueness
                  result.forEach((item) => {
                    uniqueItems.set(item.item_id, item);
                  });
                } catch (error) {
                  console.error(`Error fetching data for dropdown_id=${dropdownId}`, error);
                }
              }
            } else {
              // If dropdownlist is a single value
              try {
                const response = await customFetch(
                  `${process.env.REACT_APP_URL}/get/dropdown/item/list?dropdown_id=${field.dropdownlist}`,
                  requestOptions
                );
                const result = await response.json();

                

                // Add each item to the Map to ensure uniqueness
                result.forEach((item) => {
                  uniqueItems.set(item.item_id, item);
                });
              } catch (error) {
                console.error(
                  `Error fetching data for dropdown_id=${field.dropdownlist}`,
                  error
                );
              }
            }

            // Combine unique items into an array
            combinedResult = Array.from(uniqueItems.values());

            const bucket_field = field.BucketField.replace(/\./g, "_");
            //if (!this.state.Reasonlist[field.BucketField]) {
              this.setState({ Reasonlist_default: { [bucket_field]: combinedResult } });
              
              // this.setState(
              //   (prevState) => ({
              //     ...prevState,
              //     Reasonlist: {
              //       ...prevState.Reasonlist,
              //       [bucket_field]: result,
              //     },
              //   }),
              //   () => {
              //     // Log the entire state to check Reasonlist
              //     console.log("Updated State:", this.state.Reasonlist);
              //     //this.setquery();
              //   }
              // );
              this.setState({ Reasonlist: { [bucket_field]: combinedResult } });
              //this.setState({ MassReasonlist: { [bucket_field]: result } });
            //}
          }

          if (field.datatype === "group_id") {
            const generate_Id = generateId(10);
            const payload = [
              ["processed", "exists", "false"],
              ["issearch", "==", [{}]],
            ];
            const requestOptions = {
              method: "POST",
              headers: {
                "api-token": this.state.api_token,
                component_id: generate_Id1,
                apiId: generate_Id,
                api_group_id: "",
                component: "PaylodView",
                user_id: localStorage.getItem("in_userid"),
                "Content-Type": "application/json",
              },
              body: JSON.stringify(payload),
            };

            const response = await customFetch(
              process.env.REACT_APP_URL + "/mongo/get/callbacks/unique_groups",
              requestOptions
            );
            const json = await response.json();
            const convertedArray = json.map((item) => ({
              group_id: item.group_id,
            }));
            this.setState({ unique_groups: convertedArray });
          }
          //if (fields.exprow || fields.view_type == "AV") {
          if (this.state.payload_config.view_type == "AV") {
            this.getsubviewconfig(this.state.payload_config.agg_view);
          }
          if (field.massupdate == "1") {
            this.setState((prevState) => ({
              massUpdateData: [...prevState.massUpdateData, field],
            }));
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error here, e.g., show an error message to the user
      }
    };

    fetchData();

    if (this.state.otherScreen != null) {
      this.setState({
        no_of_page: 2,
      });
      this.context.setotherscreen(this.state.otherScreen);
      this.context.setno_of_page(2);
    }

    // if (this.state.isDateSel == "") {
    //   this.setState({ DateSelMessage: true });
    // }

    const filteredData = this.state.payload_config.field_mapping.map((item) => {
      // Extract only the required fields
      const { fieldname, BucketField } = item;
      return { fieldname, BucketField };
    });

    // console.log("this.state.payload_config.field_mapping", filteredData);
    // const fieldnames = this.state.payload_config.field_mapping.map(
    //   (item) => item.fieldname
    // );
    this.setState({
      HeaderData: filteredData,
    });
    if(this.state.payload_config.field_mapping && this.state.payload_config.field_mapping[0].conditional_update){
      this.setState({
        conditional_update:this.state.payload_config.field_mapping[0].conditional_update
      });
    }

    this.getviewcolconfig();
  }
  componentDidUpdate(prevProps, prevState) {
    const updfilterdata = this.filterData() || [];
    const filtercustom_field =
      this.props.location.state.payload_config.field_mapping.filter(
        (item) => item.custom_field === "1"
      );
    if (this.state.Reasonlist !== prevState.Reasonlist) {
      this.setquery();
    }
    
    if(this.state.payload_data !== prevState.payload_data || this.state.filters !== prevState.filters)
    {
      
      this.setState({
        filtered_data: updfilterdata,
      });
     
      if(updfilterdata.length > 0){
        filtercustom_field.forEach((item) => {          
          if(item.manuall_operator == "sum"){
            const sumresult = this.getfieldsum(updfilterdata, item.manuall_condition_field);            
            this.updateComponentGlobalState({ [item.BucketField]: sumresult });
          }
          setTimeout(() => {if(item.manuall_condition_field && item.manuall_condition_field_with){
            const result = this.performOperation(item);
            this.updateComponentGlobalState({ [item.BucketField]: result });
          }},1000);
          if(item.isglobal_api_call == "1"){
            this.getdatafromAPI(item,item.global_api_call).then((namesData) => {
              //console.log(namesData);
              console.log("apiresponse",namesData); 
            })
            .catch((error) => {
              console.error('Error:', error);
            });                       
          }
        })
      }
    }
  }
  performOperation = (data) => {
    if(this.state.componentGlobalState[data.manuall_condition_field] && this.state.componentGlobalState[data.manuall_condition_field_with]){
      const numericString1 = String(this.state.componentGlobalState[data.manuall_condition_field]).replace(/(?!^-)[^0-9]/g, "");
      const cleanedString1 = numericString1.replace(/(?!^-)\D/g, "");
      const value1 = parseInt(cleanedString1, 10);

      // Extract and clean value2
      const numericString2 = String(this.state.componentGlobalState[data.manuall_condition_field_with]).replace(/(?!^-)[^0-9]/g, "");
      const cleanedString2 = numericString2.replace(/(?!^-)\D/g, "");
      const value2 = parseInt(cleanedString2, 10);
      let result;
    
      switch (data.manuall_operator) {
        case "+":
          result = value1 + value2;
          break;
        case "-":
          result = value1 - value2;
          break;
        case "*":
          result = value1 * value2;
          break;
        case "/":
          result = value2 !== 0 ? value1 / value2 : "Cannot divide by zero";
          break;
        default:
          result = "Invalid operator";
      }
    
      console.log("Result:", result);
      return result;
    } else return 0;
  };
  getdatafromAPI = async (getfromapidata,apiname) => {
    try {
      //setisLoadingname(true);       
      if (getfromapidata != "") {
        const namesMap = "";
  
        // Iterate over each record in getfromapidata
        //for (const field of getfromapidata) {
          const getapi = this.state.payload_config.get_api_mapping.filter(
            (item) => item.action_name == apiname
          );
  
          if (getapi.length > 0) {
            const apiurl = this.getDataBetweenBraces(getapi[0].api_url);
  
            const regex = /{{(.*?)}}/g;
            let match = regex.exec(getapi[0].getoutputdata);
  
            const requestOptions = {
              method: "GET",
              headers: {
                "api-token": this.state.api_token,
              },
            };
  
            const response = await customFetch(
              process.env.REACT_APP_URL + "/" + apiurl,
              requestOptions
            );
  
            if (response.status == 200) {
              const data = await response.json();
  
              const respinsedata = this.getDataFromresponse(getapi[0].getoutputdata, data);
              // namesMap = [{
              //   key: respinsedata[0].value,
              //   value: respinsedata[1].value,
              // }];
              this.updateComponentGlobalState({ [getfromapidata.BucketField]: respinsedata[0].value });
            } else {
              this.updateComponentGlobalState({ [getfromapidata.BucketField]: [getfromapidata.custom_defaultval] });
            }
          } else {
            this.updateComponentGlobalState({ [getfromapidata.BucketField]: [getfromapidata.custom_defaultval] });
          }
        
        //return namesMap;
        //setNames(namesMap);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      //setisLoadingname(false);
    }
  };
  // componentDidUpdate(prevProps, prevState) {
  //   // Check if stateA has changed
  //   console.log("addtosum1", prevState.selectedMassUpdateData);
  //   console.log("addtosum2", this.state.selectedMassUpdateData);
  //   if (
  //     prevState.selectedMassUpdateData !== this.state.selectedMassUpdateData
  //   ) {
  //     console.log("addtosum", this.state.addtosum);
  //   }
  // }
  setquery = () => {
    const filtersearch = this.props.location.state.payload_config.field_mapping.filter(
      (item) => item.isSearch === "1"
    );
    const extractedDataArray = [];
    if (this.props.location.state.payload_config.payload_type == "M") {
      filtersearch.forEach((item) => {
        const name = item.BucketField;
        const label = item.fieldname;
        const datatype = item.datatype;
        if (item.datatype == "dropdownlist") {
          const valueEditorType = "select";
          

          const transformedData =
            this.state.Reasonlist &&
            this.state.Reasonlist[name].map((item) => ({
              label: item.item_name,
              name: item.item_value,
            }));
          const values = transformedData;
          extractedDataArray.push({
            name,
            label,
            datatype,
            valueEditorType,
            values,
          });
        } else {
          extractedDataArray.push({ name, label, datatype });
        }
      });
      //setsearch_fields(extractedDataArray);
      this.setState({ search_fields: extractedDataArray });
    } else {
      this.setState({ search_fields: filtersearch });
    }
  };
  updateisspin = (e) => {
    this.setState({
      isspin: e,
    });
  };

  getviewcolconfig = () => {
    let generate_Id = generateId(10);

    let payload = [
      ["view_id", "==", this.state.payload_config.view_id],
      ["user_id", "==", localStorage.getItem("in_userid")],
    ];
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": this.state.api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: "PaylodView",
        user_id: localStorage.getItem("in_userid"),
      },
      body: JSON.stringify(payload),
    };
    // AT-2109 | AT-2156 | Ankur Makavana | 10-07-2023 | replace blob_url with public filepath
    const Reasonlist = customFetch(
      process.env.REACT_APP_URL + "/mongo/get/callbacks/store_user_view",
      requestOptions
    )
      .then((res) => {
        if(res) {
          return res.json();
        }
      })
      .then((json) => {
        
        if (json.length > 0) {
          // const updatedData = this.state.payload_config.field_mapping.map(
          //   (item) => {
          //     // Check if the BucketField is present in data1
          //     const isBucketFieldInData1 = json[0].view_config.includes(
          //       item.BucketField
          //     );

          //     // Update isVisible property based on the presence in data1
          //     return {
          //       ...item,
          //       isVisible: isBucketFieldInData1 ? "1" : "0",
          //     };
          //   }
          // );

          // this.setState((prevState) => ({
          //   payload_config: {
          //     ...prevState.payload_config,
          //     field_mapping: updatedData,
          //   },
          // }));
          
          this.setState({
            savedFilter: json,
          });

          // this.setState({
          //   checkedCheckboxes: json[0].view_config,
          // });

          let chkdata = [];
          this.state.HeaderData.map((field, index) => {
            chkdata.push(field.BucketField);
          });
          
          this.setState({
            checkedCheckboxes: chkdata,
          });
        } else {
          
          let chkdata = [];
          this.state.HeaderData.map((field, index) => {
            chkdata.push(field.BucketField);
          });
          
          this.setState({
            checkedCheckboxes: chkdata,
          });
        }
      });
  };
  handleCheckboxChangeupdate = (fieldName) => {
    const { updatecheckbox } = this.state;
    const updatedCheckboxes = [...updatecheckbox];

    if (updatedCheckboxes.includes(fieldName)) {
      // If the checkbox is already checked, uncheck it
      const index = updatedCheckboxes.indexOf(fieldName);
      updatedCheckboxes.splice(index, 1);
    } else {
      // If the checkbox is unchecked, check it
      updatedCheckboxes.push(fieldName);
    }

    this.setState({ updatecheckbox: updatedCheckboxes });
  };
  handleCheckboxChange = (fieldName) => {
    const { checkedCheckboxes } = this.state;
    const updatedCheckboxes = [...checkedCheckboxes];

    if (updatedCheckboxes.includes(fieldName)) {
      // If the checkbox is already checked, uncheck it
      const index = updatedCheckboxes.indexOf(fieldName);
      updatedCheckboxes.splice(index, 1);
    } else {
      // If the checkbox is unchecked, check it
      updatedCheckboxes.push(fieldName);
    }

    this.setState({ checkedCheckboxes: updatedCheckboxes });
  };
  getsubviewconfig = async (exprow) => {
    const filtered = this.props.location.state.all_view.filter(
      (item) => item.view_id == exprow
    );
    
    this.setState({
      subpayload_config: filtered[0],
    });
  };
  handleSort = () => {
    // Implement sorting logic based on the current sort order
    const sortedData = [...this.state.payload_data].sort((a, b) => {
      if (this.state.sortOrder === "asc") {
        return moment
          .unix(a.created_at)
          .format("MM/DD/YYYY HH:mm:ss")
          .localeCompare(
            moment.unix(b.created_at).format("MM/DD/YYYY HH:mm:ss")
          );
      } else {
        return moment
          .unix(b.created_at)
          .format("MM/DD/YYYY HH:mm:ss")
          .localeCompare(
            moment.unix(a.created_at).format("MM/DD/YYYY HH:mm:ss")
          );
      }
    });

    // Update the table data and sort order

    this.setState({
      sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc",
      payload_data: sortedData,
    });
    // setTableData(sortedData);
    // setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };
  DeletePayload = async (doc_id) => {
    try {
      const url =
        process.env.REACT_APP_URL +
        "/payload/move/" +
        this.state.payload_config.bucket_name +
        "/" +
        doc_id;
      let payload = null;
      let method = "POST";
      let generate_Id = generateId(10);
      const requestOptions = {
        method: method,
        headers: {
          "api-token": this.state.api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: "PaylodView",
          user_id: localStorage.getItem("in_userid"),
        },
        body: payload,
      };

      const response = await customFetch(url, requestOptions);

      const data = await response.json();
      if (response.status != 200) throw data;

      Modal.success({
        content: <pre id="json">{JSON.stringify(data)}</pre>,
        title: "Success",
      });
    } catch (e) {
      Modal.error({
        content: <pre id="json">{JSON.stringify(e)}</pre>,
        title: "Error",
      });
    } finally {
      this.getPayloads();
    }
  };
  DeleteMongoPayload = async (doc_id) => {
    try {
      const url =
        process.env.REACT_APP_URL + "/payload/movemongo/callbacks/" + doc_id;
      let payload = null;
      let method = "POST";
      let generate_Id = generateId(10);
      const requestOptions = {
        method: method,
        headers: {
          "api-token": this.state.api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: "PaylodView",
          user_id: localStorage.getItem("in_userid"),
        },
        body: payload,
      };

      const response = await customFetch(url, requestOptions);

      const data = await response.json();
      if (response.status != 200) throw data;

      Modal.success({
        content: <pre id="json">{JSON.stringify(data)}</pre>,
        title: "Success",
      });
    } catch (e) {
      Modal.error({
        content: <pre id="json">{JSON.stringify(e)}</pre>,
        title: "Error",
      });
    } finally {
      this.getPayloads();
    }
  };
  getNestedValue = (obj, path) => {

    return path
      .split(/[\[\]\.]+/)
      .filter(Boolean)
      .reduce((acc, part) => acc && acc[part], obj);
  };
  checkPlaceholders = (value) => {
    const regex = /{{(.*?)}}/g;
    const matches = value.match(regex);
    if (matches) {
      let result = value;
      for (let match of matches) {
        const key = match
          .replace("{{", "")
          .replace("}}", "")
          .replaceAll(/"/g, "")
          .trim();
        let replacement = null;
        const dataItem = this.state.payload_config.datasource[0].formdata.find(item => item.formdatakey === key);
        //let replacement = null;

        if (dataItem) {
            replacement = dataItem.formdatavalue;
        } else {
            // Fallback to localStorage if key is not found in data
            if (key === "branch_id") {
                replacement = parseInt(localStorage.getItem("branch_id"), 10);
            } else if (key === "effective_designation") {
                replacement = parseInt(localStorage.getItem("e_designation_id"), 10);
            } else if (key === "userid" || key === "user_id") {
                replacement = parseInt(localStorage.getItem("in_userid"), 10);
            }
        }

        if (replacement !== null) {
          result = result.replace(match, replacement);
        }
      }
      return result;
    }
    return value; // Return original value if no placeholders are found
  };

  processData = async (data) => {
    // Create a copy of the data array to avoid mutating the original
    const updatedData = [...data];
  
    // Iterate through each item in the array
    for (let item of updatedData) {
      if (item.manual_input === "1") {
        // If manual_input is 1, set formdatavalue to formdatakey
        item.formdatavalue = this.state[`select_${item.formdatakey}`];
      }
  
      if (item.useasinput === "1") {
        // If useasinput is 1, call the API function and update formdatavalue
        try {
          const getapi = this.state.payload_config.get_api_mapping.filter(
            (dataitem) => dataitem.action_name == item.inputapi
          );
          let cnt = 1;          
          const url = this.checkPlaceholders(getapi[0].api_url);
          let generate_Id = generateId(10);
          const requestOptions = {
            method: "GET",
            headers: {
              "api-token": this.state.api_token,
              component_id: generate_Id1,
              apiId: generate_Id,
              api_group_id: "",
              component: "payloadview",
              user_id: localStorage.getItem("in_userid"),
            },
          };      
          const response = await customFetch(
            process.env.REACT_APP_URL + "/" + url + this.state[`select_${item.formdatakey}`],
            requestOptions
          );

          const data = await response.json();
          let upddata=[];
          if(getapi[0].output_path){
            upddata = this.getNestedValue(data, getapi[0].output_path);
          } else {
            upddata = data;
          }
          let respinsedata = "";
          if (upddata.length > 0) {
            respinsedata = upddata.map((item) =>
              this.getDataFromresponse(getapi[0].getoutputdata, item)
            );            
          } else {
            respinsedata = this.getDataFromresponse(getapi[0].getoutputdata, upddata)
          }
          console.log("respinsedata",respinsedata);
          //const apiResponse = await fetchApiData(item.inputapi);
          item.formdatavalue = respinsedata[0].value.toString();
        } catch (error) {
          console.error("Error fetching API data:", error);
          item.formdatavalue = "Error"; // Handle errors gracefully
        }
      }
    }
  
    return updatedData;
  };

  getPayloads = async () => {
    
    const { contextuser_id } = this.context;

    let data;

    if (
      this.props.location.state.fetch_onload == "true" ||
      this.state.search_click == 1
    ) {
      this.setState({ isspin: true });
      let dbname, collectionname;
      if (
        !this.state.payload_config.db_name ||
        this.state.payload_config.db_name == ""
      ) {
        dbname = "callbacks";
      } else {
        dbname = this.state.payload_config.db_name;
      }
      if (
        !this.state.payload_config.collection_name ||
        this.state.payload_config.collection_name == ""
      ) {
        collectionname = "callback_payloads";
      } else {
        collectionname = this.state.payload_config.collection_name;
      }
      if (this.state.payload_config.payload_type == "M") {
        let defaultfilter = [];
        if (this.state.isLoginuser == "1") {
          this.state.payload_config.field_mapping.map((fieldata) => {
            if (fieldata.defaultfilter != "") {
              let splitfieldata = fieldata.defaultfilter.split(".");
              
              //console.log("splitfieldata", localStorage.getItem(splitfieldata[1]));
              defaultfilter.push({
                field: fieldata.BucketField,
                fieldvalue: localStorage.getItem(splitfieldata[1]),
                //operatordata: "==",
                operatordata: splitfieldata[0],
              });
            }
          });
        }
        
        let limit_per_call;
        if (this.state.payload_config.record_per_call) {
          limit_per_call = parseInt(this.state.payload_config.record_per_call);
        } else {
          limit_per_call = 50;
        }
        let offset = 1;
        if (this.state.load_more == 1) {
          offset = this.state.payload_data.length + 1;
        }
        let filter_bucketfield = this.state.payload_config.field_mapping.map(
          (item) => item.BucketField
        );

        // let filter_bucketfield = this.state.payload_config.field_mapping
        //   .filter(
        //     (item) =>
        //       !["block", "Downloadfile", "jsondata", "image"].includes(
        //         item.datatype
        //       )
        //   )
        //   .map((item) => item.BucketField);

        let filter_columns = "";
        if (this.state.payload_config.get_data_by) {
          if (this.state.payload_config.get_data_by == "C") {
            filter_columns = this.filterUniqueData(filter_bucketfield);
          }
        } else {
          filter_columns = this.filterUniqueData(filter_bucketfield);
        }
        // let searchbypair;
        // searchbypair = this.processData(
        //   JSON.parse(this.state.payload_config.search_by_pair)
        // );
        // console.log(
        //   "filter_bucketfield",
        //   this.checkPlaceholders(this.state.payload_config.search_by_pair)
        // );
        const hideloadmore =
          this.state.payload_config.hide_loadmore &&
          this.state.payload_config.hide_loadmore == "0"
            ? "1"
            : "0";
        const search_by_pair = this.checkPlaceholders(this.state.payload_config.search_by_pair);
        
        const searchconfig = this.formatQuerybuild(this.state.searchconfig);
        this.setState({ search_by_pair: search_by_pair, defaultfilter:defaultfilter });
        const resdata = await get_mongo_payload(
          this.state.api_token,
          search_by_pair,
          this.state.filterDate,
          searchconfig,
          dbname,
          collectionname,
          defaultfilter,
          this.state.isLoginuser,
          limit_per_call,
          offset,
          filter_columns,
          hideloadmore
        );
        
        const responsedata = await resdata;
        const outputFormatPath =
          this.state.payload_config.sourcedataoutputformat;
        
        if (!responsedata.message && outputFormatPath) {
          data = this.getNestedValue(responsedata, outputFormatPath);
        } else {
          data = responsedata;
        }
      } else if (this.state.payload_config.payload_type == "A") {
        
        //const api = this.state.payload_config;
        const datasource = this.state.payload_config.datasource;
        const form = new FormData();
        const formdata = this.state.payload_config.datasource[0].formdata;
        let finaldata = [];
        if(this.state.search_click){
          const updatedData = await this.processData(formdata);
          finaldata = updatedData;
        } else {
          finaldata = formdata;
        }
        finaldata.forEach((item) => {
          const processedValue = this.checkPlaceholders(item.formdatavalue);
          
          form.append(item.formdatakey, processedValue);
        });
        const requestOptions = {
          method: "POST",
          headers: {
            "api-token": this.state.api_token,
          },
          body: form,
        };
        let url = "";
        if (datasource[0].url_type == "N") {
          url = `${process.env.REACT_APP_URL}/${datasource[0].api_url}`;
        } else {
          url = datasource[0].api_url;
        }
        const response = await customFetch(url, requestOptions);
        const responsedata = await response.json();
        const outputFormatPath = datasource[0].outputformat;
        if (!responsedata.message) {
          data = this.getNestedValue(responsedata, outputFormatPath);
        } else {
          data = responsedata;
        }
      } else {
        let defaultfilter = [];
        if (this.state.isLoginuser == "1") {
          this.state.payload_config.field_mapping.map((fieldata) => {
            if (fieldata.defaultfilter != "") {
              let splitfieldata = fieldata.defaultfilter.split(".");
              
              //console.log("splitfieldata", localStorage.getItem(splitfieldata[1]));
              defaultfilter.push({
                field: fieldata.BucketField,
                fieldvalue: localStorage.getItem(splitfieldata[1]),
                operatordata: splitfieldata[0],
              });
            }
          });
        }

        let searchData = [];
        this.state.payload_config.field_mapping.map((cell) => {
          if (cell.isSearch == 1 && this.state[cell.BucketField]) {
            searchData.push([
              cell.BucketField,
              "==",
              this.state[cell.BucketField],
            ]);
          }
        });

        data = await get_payload_views(
          this.state.api_token,
          this.state.payload_config.bucket_name,
          this.state.payload_config.event_name,
          defaultfilter,
          this.state.isLoginuser,
          this.state.filterDate,
          this.state.payload_config.event_location,
          searchData,
          this.state.searchconfig
        );
      }
      let parsed_data = [];
      if (!data.message && data.status != 0) {
        for (let item in data) {
          parsed_data.push({
            ...data[item],
            key: item,
          });
        }
        if (data.length == 0) {
          this.setState({ disable_loadmore: true });
        }
        this.setState({ isspin: false });
        
        this.setState({
          init_outputdata: parsed_data,
          payloadglobal_data: parsed_data,
        });
        if (
          this.state.payload_config.view_type &&
          this.state.payload_config.view_type == "AV"
        ) {
          parsed_data = this.processAggData(
            parsed_data,
            this.state.payload_config.field_mapping
          );
          
        }
        if (
          Array.isArray(this.state.payload_data) &&
          this.state.load_more == 1
        ) {
          const newArray = [...this.state.payload_data, ...parsed_data];
          const result = [];
          newArray.forEach((item, index) => {
            result[index.toString()] = item;
          });
          this.setState({ payload_data: result });
        } else {
          this.setState({
            payload_data: parsed_data,
          });
        }
      } else {
        this.setState({
          isspin: false,
        });
        alert(data.message);
        
      }
    }
    this.setState({ load_more: 0 });
    if (this.state.search_click == 1) {
      
      this.setState(
        {
          isGetFirstdata: 0,
        },
        () => this.getfirstrowstatus(this.state.payload_data)
      );
    }
    setTimeout(() => {
      const updateUniqueData = this.updateUniqueData();
      this.setState({ updateUniqueData: updateUniqueData }, () =>
        this.getconfigsum()
      );
    }, 1000);
  };
  processAggData = (data, config, lastValidData = null) => {
    let resultData = [...data]; // Clone the data array to avoid mutation
    let groupedData = null;
  
    // Check for 'GD' in aggregatecolumn
    const gdConfig = config.find((conf) => conf.aggregatecolumn === "GD");
    if (gdConfig) {
      const { BucketField } = gdConfig;
  
      // Group the data by the field specified in BucketField
      groupedData = resultData.reduce((acc, item) => {
        const fieldValue = BucketField.split(".").reduce((o, i) => (o && o[i] !== undefined ? o[i] : "UNGROUPED"), item);
        if (!acc[fieldValue]) {
          acc[fieldValue] = [];
        }
        acc[fieldValue].push(item);
        return acc;
      }, {});
  
      // Ensure groups from lastValidData are retained if empty
      if (lastValidData) {
        const lastGroupedData = lastValidData.reduce((acc, item) => {
          const fieldValue = BucketField.split(".").reduce((o, i) => (o && o[i] !== undefined ? o[i] : "UNGROUPED"), item);
          if (!acc[fieldValue]) {
            acc[fieldValue] = [];
          }
          acc[fieldValue].push(item);
          return acc;
        }, {});
  
        // Merge empty groups with lastValidData
        Object.keys(lastGroupedData).forEach((key) => {
          if (!groupedData[key] || groupedData[key].length === 0) {
            groupedData[key] = lastGroupedData[key].map((item) => ({
              ...item,
              //[gdConfig.BucketField]: 0, // Set the aggregation field to 0
            }));
          }
        });
      }

      const adConfig = config.find((conf) => conf.aggregatecolumn === "AD");
  if (adConfig && groupedData) {
    const { BucketField } = adConfig;

    // Ensure all groups are retained, with `BucketField` set to `0` for missing groups
    const processedData = Object.entries(groupedData).map(([key, items]) => {
      if (items.some((item) => data.includes(item))) {
        // If group exists in the current data, calculate the sum
        const summedItem = { ...items[0] }; // Clone the first item in the group
        summedItem[BucketField] = items.reduce(
          (sum, item) => sum + (item[BucketField] || 0),
          0
        );
        return summedItem;
      } else {
        // If group is missing, retain it with `BucketField` set to `0`
        const retainedItem = { ...items[0] };
        retainedItem[BucketField] = 0;
        return retainedItem;
      }
    });

    resultData = processedData;
  }
  
      // Flatten the grouped data back into an array, ensuring no duplicates
      // resultData = Object.entries(groupedData).reduce((acc, [key, group]) => {
      //   if (group.length > 0) {
      //     acc.push(group[0]); // Add only the first item of each group
      //   }
      //   return acc;
      // }, []);
    }
  
    return resultData;
  };  
  
  handleRowClick = (rowData) => {
    
    const subinput = this.getGroupForEntry(rowData, "RowClick");
    this.setState({
      ExpandedRow: subinput,
    });
    if (this.state.isMobile) {
      
      this.setState({
        isExprow: true,
      });
    }
  };
  updaterowindex = (rowid) => {
    this.setState({ selectedrowindex: rowid });
    // if(this.state.selectedrowindex_icon == rowid){
    //   this.setState({ selectedrowindex_icon: "" }, () =>
    //     console.log("selectedrowindex_if", this.state.selectedrowindex_icon)
    //   );
    // } else {
    //   this.setState({ selectedrowindex_icon: rowid }, () =>
    //     console.log("selectedrowindex", this.state.selectedrowindex_icon)
    //   );
    // }
  };
  // New function to find and return all grouped data for a specific entry
  getGroupForEntry = (inputEntry, filtertype,objectid=[]) => {
    
    //const data = this.state.init_outputdata || [];
    const data =
      filtertype == "RowClick"
        ? this.state.init_outputdata
        : (filtertype == "SubmitData" || filtertype == "SubmitRowData")
        ? this.state.payloadglobal_data.length > 0
          ? this.state.payloadglobal_data
          : this.state.init_outputdata
        : [];
    //filtertype = this.state.init_outputdata || [];
    console.log("resdata",data);
    const config = this.state.payload_config.field_mapping;
    

    let groupedData = null;

    // First check for 'GD' in aggregatecolumn
    const gdConfig = config.find((conf) => conf.aggregatecolumn === "GD");
    if (gdConfig) {
      const { BucketField } = gdConfig;
      let inputFieldValue;
      if (filtertype == "SubmitData") {
        const filteredData = data.filter((item) => {
          return Object.values(item).some((value) => {
            if (Array.isArray(value)) {
              return value.some((val) => this.state.selectedMassUpdateData.includes(val));
            }
            if (typeof value === "object" && value !== null && value.$oid) {
              return this.state.selectedMassUpdateData.includes(value.$oid);
            }
            return this.state.selectedMassUpdateData.includes(value);
          });
        }).map((item) => item[BucketField]);
        
        inputFieldValue = filteredData;
      } else if(filtertype == "SubmitRowData"){
        //let selectdata = objectid;
        const filteredData = data
          .filter((item) => {
            return Object.values(item).some((value) => {
              if (Array.isArray(value)) {
                // If the value is an array, check if any element matches the input values
                return value.some((val) =>
                  objectid.includes(val)
                );
              }
              return objectid.includes(value); // Check if the value matches any in the input array
            });
          })
          .map((item) => item[BucketField]);
        
        inputFieldValue = filteredData;       
      } else {
        inputFieldValue = inputEntry[BucketField];
      }
      
      // Group the data by the field specified in BucketField
      groupedData = data.reduce((acc, item) => {
        const fieldValue = BucketField.split(".").reduce((o, i) => o[i], item);
        if (!acc[fieldValue]) {
          acc[fieldValue] = [];
        }
        acc[fieldValue].push(item);
        return acc;
      }, {});

      // Find the group of the input entry

      // If the direct access returns undefined, check inside the nested "add_data_before" object
      if (inputFieldValue === undefined && inputEntry.add_data_before) {
        inputFieldValue = inputEntry.add_data_before[BucketField];
      }
      if (filtertype == "SubmitData" || filtertype == "SubmitRowData") {
        // const filteredData = Object.fromEntries(
        //   inputFieldValue.map(String).filter((key) => key in groupedData).map((key) => [groupedData[key]])
        // );
        // const result = inputFieldValue.reduce((accumulator, key) => {
        //   if (groupedData[key]) {
        //     accumulator[key] = groupedData[key]; // Add key-value pair if key exists in data
        //   }
        //   return accumulator; // Return the updated accumulator
        // }, {});

        // // Ensure the filteredData variable holds the result
        // const filteredData = result;
        //const filteredData = this.getFilteredData(inputFieldValue, groupedData);
        const filteredData = Object.keys(groupedData)
          .filter((key) => inputFieldValue.map(String).includes(key))
          .flatMap((key) => groupedData[key]);

        
        return filteredData;
      } else {
        const filterdata = groupedData[inputFieldValue];
        
        return groupedData[inputFieldValue] || [];
      }
    }

    return [];
  };

  getFilteredData = (ardata, data) => {
    const filteredData = ardata.reduce((accumulator, key) => {
      if (data[key]) {
        accumulator[key] = data[key]; // Add key-value pair to the accumulator
      }
      
      return accumulator; // Return the updated accumulator
    }, {});

    
    return filteredData; // Return the accumulated result
  };
  getconfigsum = () => {
    if (this.state.addtosum && this.state.addtosum.sumFieldlist) {
      // acc.push({ key: [item.sumFieldlist], sum: [item.BucketField] });
      // const statusField = "order_status";
      // const amountField = "total_amount";
      const result = (
        this.state.dropdoenFiltered_data || this.state.payload_data
      ).reduce((acc, item) => {
        const status = item[this.state.addtosum.sumFieldlist] || null;
        const amount = item[this.state.addtosum.BucketField] || 0;

        if (status !== null) {
          let key = "";
          const keyname = this.getItemNameByValue(
            status,
            this.state.addtosum.sumFieldlist
          );
          // const keyname = status;
          // if (keyname != null) {
          //   key = keyname;
          // } else {
          // }
          key = status;
          const existingItem = acc.find((obj) => obj.key === key);
          if (existingItem) {
            existingItem.sum += amount;
          } else {
            acc.push({ key: key, sum: amount, name: keyname });
          }
        }

        return acc;
      }, []);
      const sumconfig = this.state.payload_config.field_mapping[0].sum_config;
      
      if (
        sumconfig &&
        sumconfig.filter((config) => !config.name || config.name.trim() === "")
          .length == 0
      ) {
        const config_sumresults = this.calculateConfigurations(
          sumconfig,
          result
        );
        this.setState({
          config_sum: config_sumresults,
        });
      } else {
        this.setState({
          config_sum: result,
        });
      }
    }
  };
  findSumByKey = (key, input) => {
    const entry = input.find((item) => item.key === parseInt(key.dropdown2));
    return entry
      ? { sum: entry.sum, name: entry.name }
      : { sum: 0, name: key.name };
  };
  calculateConfigurations = (data, input) => {
    return data.map((config) => {
      let total = 0; // Initialize the total for each config
      let breakdown = [];
      config.items.forEach((item, index) => {
        const { sum, name } = this.findSumByKey(item, input);
        if (name == "Unknown") return;
        if (index === 0) {
          // Start with the first value
          total = sum;
        } else {
          // Apply operator for subsequent items
          switch (item.operator) {
            case "+":
              total += sum;
              break;
            case "-":
              total -= sum;
              break;
            case "*":
              total *= sum;
              break;
            case "/":
              total /= sum;
              break;
            default:
              total += sum; // If no operator, default to addition
              break;
          }
        }
        // Add each step's details to breakdown
        breakdown.push({
          dropdown2: item.dropdown2,
          sum,
          name: item.name ? item.name : name,
          operator: item.operator,
          isEditable: item.isEditable ? 1 : 0,          
          popupfield: item.popupfield,
        });
      });

      return {
        color: config.color,
        name: config.name,
        sum: total,
        breakdown: breakdown,
      };
    });
  };
  getItemNameByValue = (value, fieldname) => {
    const foundItem = this.state.Reasonlist[fieldname] && this.state.Reasonlist[fieldname].find(
      (item) => item.item_value === value
    );
    return foundItem ? foundItem.item_name : null; // Return null if not found
  };
  // processData = (data) => {
  //   const placeholderRegex = /{{.*?}}/;
  //   return data.map((row) =>
  //     row.map((value) => {
  //       if (placeholderRegex.test(value)) {
  //         return this.checkPlaceholders(value);
  //       }
  //       return value;
  //     })
  //   );
  // };
  checkPlaceholders = (value) => {
    const regex = /{{(.*?)}}/g;
    const matches = value.match(regex);
    if (matches) {
      let result = value;
      for (let match of matches) {
        const key = match
          .replace("{{", "")
          .replace("}}", "")
          .replaceAll(/"/g, "")
          .trim();
        let replacement = null;
        const dataItem = this.state.payload_config.datasource[0].formdata.find(item => item.formdatakey === key);
        //let replacement = null;

        if (dataItem) {
            replacement = dataItem.formdatavalue;
        } else {
            // Fallback to localStorage if key is not found in data
            if (key === "branch_id") {
                replacement = parseInt(localStorage.getItem("branch_id"), 10);
            } else if (key === "effective_designation") {
                replacement = parseInt(localStorage.getItem("e_designation_id"), 10);
            } else if (key === "userid" || key === "user_id") {
                replacement = parseInt(localStorage.getItem("in_userid"), 10);
            }
        }

        if (replacement !== null) {
          result = result.replace(match, replacement);
        }
      }
      return result;
    }
    return value; // Return original value if no placeholders are found
  };
  getNestedValue = (obj, path) => {

    return path
      .split(/[\[\]\.]+/)
      .filter(Boolean)
      .reduce((acc, part) => acc && acc[part], obj);
  };
  filterUniqueData(data) {
    const uniqueData = {};

    data.forEach((item) => {
      const segments = item.split(/[.:]/);
      let key = segments[0];

      // Check if the key exists in uniqueData
      if (!uniqueData[key]) {
        uniqueData[key] = true;
      }
    });

    // Convert uniqueData object keys to array
    return Object.keys(uniqueData);
  }
  formatQuerybuild = (query) => {
    if (!query) return {};
    const mongoQuery = {};
    if (query.combinator === "and") {
      if (query.rules && query.rules.length > 0) {
        mongoQuery.$and = query.rules.map(this.formatQuerybuild);
      }
    } else if (query.combinator === "or") {
      if (query.rules && query.rules.length > 0) {
        mongoQuery.$or = query.rules.map(this.formatQuerybuild);
      }
    }

    if (query.field && query.operator) {
      const field = query.field;
      const operator = query.operator;
      const queryvalue = query.value;
      let value;
      const createdAtObject = this.state.payload_config.field_mapping.find(
        (item) => item.BucketField == field
      );
      if (createdAtObject) {
        if (createdAtObject.datatype == "int") {
          value = parseInt(queryvalue);
        } else if (createdAtObject.datatype == "float") {
          value = parseFloat(queryvalue);
        } else {
          value = queryvalue;
        }
      } else {
        value = queryvalue;
      }

      if (!isNaN(queryvalue) && value !== "") {
        // Check if the value is a number
        if (Number.isInteger(parseFloat(queryvalue))) {
          value = parseInt(queryvalue); // Convert to integer
        } else {
          value = parseFloat(queryvalue); // Convert to float
        }
      } else if (typeof queryvalue === "string") {
        // If the value is a string, leave it as is
        value = queryvalue;
      } else {
        // If the value doesn't match any of the above types, keep it as is
        value = queryvalue;
      }

      if (operator === "=") {
        mongoQuery[field] = value;
      } else if (operator === "!=") {
        mongoQuery[field] = { $ne: value };
      } else if (operator === ">") {
        mongoQuery[field] = { $gt: value };
      } else if (operator === "<") {
        mongoQuery[field] = { $lt: value };
      } else if (operator === ">=") {
        mongoQuery[field] = { $gte: value };
      } else if (operator === "<=") {
        mongoQuery[field] = { $lte: value };
      }

      if (operator === "exists") {
        mongoQuery[field] = {
          $exists: value.toLowerCase() == "true" ? true : false,
        };
      }
      // as per discussion with kunj sir change condition for both below. for > use $lt and $gt for <
      if (operator === "TDHG") {
        mongoQuery[field] = {
          $lt: moment()
            .subtract(value, "hours")
            .unix(),
        };
      }
      if (operator === "TDHL") {
        mongoQuery[field] = {
          $gt: moment().subtract(value, "hours").unix(),
        };
      }
      if (operator === "isNthDayBeforeToday") {
        mongoQuery[field] = {
          $gte: moment()
            .subtract(value, "days")
            .startOf("day")
            .unix(),
          $lte: moment()
            .subtract(value, "days")
            .endOf("day")
            .unix(),
        };
      }
      if (operator === "NDaysBefore") {
        mongoQuery[field] = {
          $lte: moment()
            .subtract(value, "days").startOf("day").unix()
        };
      }
      if (operator === "lastNDays") {
        mongoQuery[field] = {
          $lte: moment().subtract(0, "days").endOf("day").unix(),  
          $gte: moment()
            .subtract(value, "days").startOf("day").unix(),
        };
      }
      if (operator === "nextNDays") {
        mongoQuery[field] = {
          $gte: moment().subtract(0, "days").startOf("day").unix(),  
          $lte: moment().add(value, "days").endOf("day").unix()
        };
      }
      if (operator === "NDaysAfter") {
        mongoQuery[field] = {
          $gte: moment().add(value, "days").startOf("day").unix()
        };
      }
    }
    return mongoQuery;
  };
  setDisabled = (value) => {
    this.setState({ disabled: value });
  };
  changedate = (date, dateString) => {
    var ts = moment(dateString, dateFormatList).unix();
    var m = moment.unix(ts);
    let inputDate = moment(date).format("YYYY-MM-DD");
    this.setState({
      filterDate: moment(inputDate).unix(),
      isDateSel: "1",
    });
  };

  update_search_filter = (field, value) => {

    if (this.state.payload_config.payload_type == "M") {
      if (
        this.props.location.state.payload_config.field_mapping[0].search_query
      ) {
        const updatedRule = { ...this.state.searchconfig };

        const findRule = (rules) => {
          for (let rule of rules) {
            if (rule.field === field) {
              rule.value = value;
              return;
            }
            if (rule.rules) {
              findRule(rule.rules);
            }
          }
        };

        findRule(updatedRule.rules);

        this.setState({ searchconfig: updatedRule });
      } else {
        const { searchconfig } = this.state;
        let operator = "=";
        const existingRuleIndex = searchconfig.rules.findIndex(
          (rule) => rule.field == field
        );

        if (existingRuleIndex != -1) {
          // Update the existing rule
          searchconfig.rules[existingRuleIndex] = { field, operator, value };
        } else {
          // Insert a new rule
          searchconfig.rules.push({ field, operator, value });
        }

        // Update the state with the modified search_new object
        this.setState({ searchconfig });
      }
    }
    if (this.state.payload_config.payload_type == "B") {
      const existingIndex = this.state.searchconfig.findIndex(
        (item) => item[0] == field
      );

      if (existingIndex !== -1) {
        // If the field exists, update the corresponding value
        const updatedKeyValues = [...this.state.searchconfig];
        updatedKeyValues[existingIndex][2] = value;

        this.setState({
          searchconfig: updatedKeyValues,
        });
      } else {
        // If the key doesn't exist, create a new entry
        const newKeyValue = [field, "==", value];
        this.setState((prevState) => ({
          searchconfig: [...prevState.searchconfig, newKeyValue],
        }));
      }
    }
  };
  eventNameSearch = (e, columnName) => {

    const updatedFilters = {
      ...this.state.filters,
      [columnName]: e.target.value,
    };
    this.setState({ filters: updatedFilters });
  };
  // filterData = () => {
  //   return this.state.payload_data.filter((row) => {
  //     for (const columnName in this.state.filters) {
  //       const filterText = this.state.filters[columnName].toLowerCase();
  //       const cellText = String(row[columnName]).toLowerCase();
  //       if (!cellText.includes(filterText)) {
  //         return false;
  //       }
  //     }
  //     return true;
  //   });
  // };

  filterData = () => {
    const { updatedPayloadData, payload_data, filters, dateRangeFilters } = this.state;
    let _payload_data;
    if(updatedPayloadData && updatedPayloadData.length > 0){
      _payload_data = updatedPayloadData;
    } else {
      _payload_data = payload_data;
    }
    let filteredData = _payload_data.filter((row) => {
      if (!row) {
        return false;
      }
  
      if (filters) {
        const textFilterPassed = Object.keys(filters).every((columnName) => {
          const filterValues = filters[columnName];
          const config = this.state.payload_config.field_mapping.find(
            (config) => config.BucketField === columnName
          );
  
          if (!config) {
            return false;
          }
  
          const cellText = this.deepGet(row, columnName);
  
          if (Array.isArray(filterValues)) {
            return filterValues.some((filterText) => {
              if (typeof cellText === "number" && filterText != "") {
                const cellContainsFilter = new RegExp(
                  `\\b${cellText}\\b`,
                  "i"
                ).test(filterText);
                console.log("filterText if", cellContainsFilter);
                return cellContainsFilter;
              } else {
                console.log(
                  "filterText else",
                  String(cellText)
                    .toLowerCase()
                    .includes(filterText)
                );
                return String(cellText)
                  .toLowerCase()
                  .includes(filterText.toLowerCase());
              }
              // if (cellText === undefined) {
              //   return false;
              // }
            });
          } else {
            const filterText = filterValues.toLowerCase();
            if (typeof cellText === "number" && filterText !== "") {
              const cellContainsFilter = new RegExp(
                `\\b${cellText}\\b`,
                "i"
              ).test(filterText);
              return cellContainsFilter;
            } else {
              return String(cellText)
                .toLowerCase()
                .includes(filterText);
            }
          }
        }); 
        if (!textFilterPassed) {
          return false;
        }
      }
  
      if (dateRangeFilters && Object.keys(dateRangeFilters).length > 0) {
        const dateFilterPassed = Object.keys(dateRangeFilters).every((columnName) => {
          const { startDate, endDate } = dateRangeFilters[columnName];
          if (!startDate || !endDate) {
            return true;
          }
          const cellDate = this.deepGet(row, columnName);
          if (!cellDate) {
            return false;
          }
  
          const cellTimestamp = typeof cellDate === "number" ? cellDate * 1000 : new Date(cellDate).getTime();
  
          const startOfDay = new Date(startDate).setHours(0, 0, 0, 0); 
          const endOfDay = new Date(endDate).setHours(23, 59, 59, 999); 
  
          return cellTimestamp >= startOfDay && cellTimestamp <= endOfDay;
        });
  
        if (!dateFilterPassed) {
          return false;
        }
      }
  
      return true;
    });
  
    const filterdata = {};
    this.state.payload_config.field_mapping.forEach((item) => {
      if (item.ExcludeValueflag === "1" && item.ExcludeValue !== "") {
        filterdata[item.BucketField] = item.ExcludeValue;
      }
    });
  
    const parsedFilter = {};
    for (const key in filterdata) {
      parsedFilter[key] = filterdata[key]
        .split(",")
        .map((value) => value.trim());
    }
  
    const shouldExclude = (item) => {
      for (const key in parsedFilter) {
        if (parsedFilter[key].includes(String(item[key]))) {
          return true;
        }
      }
      return false;
    };
  
    const filtered_record = filteredData.filter((item) => !shouldExclude(item));
    
    // this.setState({
    //   filtered_data: filtered_record,
    // });
    return filtered_record;
  };

  deepGet = (obj, path) => {
    const keys = path.split(".");
    return keys.reduce(
      (acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
      obj
    );
  };

  converttoarray = (key, data) => {
    const keys = key.split(/[.:]/);

    // Initialize a variable to store the current data object
    let currentData = data;

    // Loop through each part of the key
    for (const k of keys) {
      // Check if the currentData is an object and has the key
      if (currentData && currentData.hasOwnProperty(k)) {
        currentData = currentData[k]; // Update currentData to the nested object
      } else {
        return ""; // Return a default value if any part of the key is not present
      }
    }
    return currentData;
  };
  showpopup = () => {
    this.setState({
      isSettings: true,
    });
  };
  updateFilter = () => {
    let payload = {};
    payload.view_config = this.state.updatecheckbox;
    payload.search_config = this.state.updatesearchconfig;
    payload.filtername = this.state.updatefiltername;
    //const jsonObject = JSON.stringify(payload);
    this.setState(
      {
        updated_filterdata: payload,
      },
      () => this.editFilter()
    );
  };
  savepreferences = async () => {
    try {
      const url = process.env.REACT_APP_URL + "/add/callbacks/store_user_view";
      let payload = {};
      payload.user_id = localStorage.getItem("in_userid");
      payload.view_id = this.state.payload_config.view_id;
      payload.view_config = this.state.checkedCheckboxes;
      payload.search_config = this.state.searchconfig;
      payload.filtername = this.state.filtername;
      const jsonObject = [payload];

      let method = "POST";
      let generate_Id = generateId(10);
      const requestOptions = {
        method: method,
        headers: {
          "api-token": this.state.api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: "PaylodView",
          user_id: localStorage.getItem("in_userid"),
        },
        body: JSON.stringify(jsonObject),
      };

      const response = await customFetch(url, requestOptions);

      const data = await response.json();
      if (response.status != 200) throw data;

      Modal.success({
        content: <pre id="json">{JSON.stringify(data)}</pre>,
        title: "Success",
      });
    } catch (e) {
      Modal.error({
        content: <pre id="json">{JSON.stringify(e)}</pre>,
        title: "Error",
      });
    } finally {
      this.setState({ searchconfig: this.state.defaultsearchconfig });
      //this.getPayloads();
      this.getviewcolconfig();
    }
  };

  updateUniqueData = () => {
    const uniqueBucketFields = new Set();

    // Filter the data array to keep only unique items
    const uniqueData =
      this.state.massUpdateData &&
      this.state.massUpdateData.filter((item) => {
        const isUnique = !uniqueBucketFields.has(item.BucketField);

        // If the BucketField is unique, add it to the Set
        if (isUnique) {
          uniqueBucketFields.add(item.BucketField);
        }
        return isUnique;
      });
    if (uniqueData.some((item) => item.datatype === "selectoption")) {
      const selctdata = uniqueData.filter(
        (item) => item.datatype === "selectoption"
      );
      // this.setState({
      //   selectoption:
      // })
      this.selectoptiondata(selctdata);
    }
    if (uniqueData.some((item) => item.datatype === "dropdownlist")) {
      // console.log(
      //   "isUnique",
      //   uniqueData.some((item) => item.datatype === "dropdownlist")
      // );
      const formassupdate = 1;
      let massupdate = this.state.payload_data;
      if (massupdate.length > 0) {
        this.getfirstrowstatus(massupdate);
      }
    }
    if (uniqueData.some((item) => item.datatype === "selectoption") && this.state.search_click == "1" && this.state.payload_config.payload_type == "A") {
      const selctdata = uniqueData.filter(
        (item) => item.datatype === "selectoption"
      );
      const transformedData = selctdata.map((item) => ({
        formdatakey: item.BucketField,
        getdata_from: item.selectoption,
      }));
console.log("transformedData--",transformedData);
      transformedData.map((get_apidata)=>
        {this.selecapiinputdata(get_apidata);}
      )
    }
    this.setState({
      updateUniqueData: uniqueData,
    });
    return uniqueData;
    // Update the state with unique data
    // this.setState({
    //   massUpdateData: uniqueData,
    // });
  };

  selectoptiondata = async (getitem) => {
    const getapi = this.state.payload_config.get_api_mapping.filter(
      (item) => item.action_name === getitem[0].selectoption
    );
  
    let massupdate = this.state.payload_data[0];
    let cnt = 1;
  
    if (massupdate && getapi.length > 0) {
      for (const apiItem of getapi) {
        const url = this.getDataBetweenBraces(apiItem.api_url, massupdate);
        let generate_Id = generateId(10);
        const requestOptions = {
          method: "GET",
          headers: {
            "api-token": this.state.api_token,
            component_id: generate_Id,
            apiId: generate_Id,
            api_group_id: "",
            component: "payloadview",
            user_id: localStorage.getItem("in_userid"),
          },
        };
  
        if (!this.state[getitem[0].BucketField]) {
          try {
            const response = await customFetch(
              `${process.env.REACT_APP_URL}/${url}`,
              requestOptions
            );
  
            const data = await response.json();
            if (data.length > 0) {
              const respinsedata = data.map((item) =>
                this.getDataFromresponse(apiItem.getoutputdata, item)
              );
  
              const transformedData = respinsedata.map((item) => ({
                key: item[0].value,
                value: item[1].value,
              }));
  
              this.setState((prevState) => ({
                ...prevState,
                [getitem[0].BucketField]: transformedData,
              }));
            }
          } catch (error) {
            console.error(`API call failed for ${apiItem.action_name}:`, error);
          }
        }
        cnt++;
      }
    }
  };
  selecapiinputdata = async (getitem) => {
    const getapi = this.state.payload_config.get_api_mapping.filter(
      (item) => item.action_name == getitem.getdata_from
    );
    let cnt = 1;
    if (getapi) {
      const url = this.checkPlaceholders(getapi[0].api_url);
      let generate_Id = generateId(10);
      const requestOptions = {
        method: "GET",
        headers: {
          "api-token": this.state.api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: "payloadview",
          user_id: localStorage.getItem("in_userid"),
        },
      };      
        const response = await customFetch(
          process.env.REACT_APP_URL + "/" + url,
          requestOptions
        );

        const data = await response.json();
        let upddata=[];
        if(getapi[0].output_path){
          upddata = this.getNestedValue(data, getapi[0].output_path);
        } else {
          upddata = data;
        }
        if (upddata.length > 0) {
          const respinsedata = upddata.map((item) =>
            this.getDataFromresponse(getapi[0].getoutputdata, item)
          );
          const transformedData = respinsedata.map((item) => ({
            key: item[0].value,
            value: item[1].value,
          }));
          this.setState(
            {
              [getitem.formdatakey]: transformedData,
            }
          );
          return transformedData;
          // console.log(
          //   "jsonjson",
          //   data.map((item) =>
          //     this.getDataFromresponse(getapi[0].getoutputdata, item)
          //   )
          // );
        }
      
      cnt++;
    } else {
      return [];
    }
  };
  getDataFromresponse = (template, data) => {
    const regex = /{{(.*?)}}/g;
    let match;
    const result = [];
 
    // Extract all placeholders from the template
    const placeholders = [];
    while ((match = regex.exec(template)) !== null) {
        placeholders.push(match[1].trim());
    }
 
    // Replace placeholders in the template with actual values from data
    let dynamicTemplate = template;
    placeholders.forEach(key => {
        if (data.hasOwnProperty(key)) {
            dynamicTemplate = dynamicTemplate.replace(`{{${key}}}`, data[key]);
        }
    });
 
    // Extract key-value pairs based on the dynamic template
    const keyValuePairs = dynamicTemplate.split(/,|\n/).map(pair => pair.trim());

    keyValuePairs.forEach(pair => {
        if (pair.includes(":")) {
            // Split the key and value parts
            const [keyPart, valuePart] = pair.split(":").map(part => part.trim());

            // Extract the key and value, removing any curly braces
            const key = keyPart.replace(/^{|}$/g, "").trim();
            const value = valuePart.replace(/^{|}$/g, "").trim();

            let typedValue;

            if (data.hasOwnProperty(key)) {
                // If key exists in data, convert value to the type of data[key]
                const dataType = typeof data[key];

                switch (dataType) {
                    case "number":
                        typedValue = Number(value);
                        break;
                    case "boolean":
                        typedValue = value.toLowerCase() === "true";
                        break;
                    case "object":
                        try {
                            typedValue = JSON.parse(value);
                        } catch (e) {
                            typedValue = value; // Fallback to string if parsing fails
                        }
                        break;
                    default:
                        typedValue = value; // Default to string
                }
            } else {
                // If key doesn't exist in data, infer the type from the value itself
                if (!isNaN(value)) {
                    // If value is a valid number, convert to number
                    typedValue = Number(value);
                } else if (value.toLowerCase() === "true" || value.toLowerCase() === "false") {
                    // If value is "true" or "false", convert to boolean
                    typedValue = value.toLowerCase() === "true";
                } else if ((value.startsWith("{") && value.endsWith("}")) || (value.startsWith("[") && value.endsWith("]"))) {
                    // If value is a valid JSON object or array, parse it
                    try {
                        typedValue = JSON.parse(value);
                    } catch (e) {
                        typedValue = value; // Fallback to string if parsing fails
                    }
                } else {
                    // Otherwise, keep as string
                    typedValue = value;
                }
            }

            result.push({ key, value: typedValue });
        } else {
            // Handle standalone placeholders like "{{employee_code}}"
            const key = pair.replace(/^{|}$/g, "").trim();

            if (data.hasOwnProperty(key)) {
                // If key exists in data, use the value from data and convert to the appropriate type
                const dataType = typeof data[key];
                let typedValue;

                switch (dataType) {
                    case "number":
                        typedValue = Number(data[key]);
                        break;
                    case "boolean":
                        typedValue = Boolean(data[key]);
                        break;
                    case "object":
                        try {
                            typedValue = JSON.parse(JSON.stringify(data[key])); // Deep copy for objects/arrays
                        } catch (e) {
                            typedValue = data[key]; // Fallback to original value if parsing fails
                        }
                        break;
                    default:
                        typedValue = data[key]; // Default to string or other types
                }

                result.push({ key: data[key], value: typedValue });
            } else {
                // If key doesn't exist in data, keep the placeholder as a string
                result.push({ key, value: pair });
            }
        }
    });
 
    return result;
  };

  setEditfc=(val)=>{
    this.setState({ editval: val });
  }
  setupdpayloadfc=(val)=>{
    this.setState({ updpayload: val });
  }
  setchangevalfc=(val)=>{
    if(val !== "" && val != undefined)
    {
      this.setState((prevState) => ({
        change_val: {
          ...prevState.change_val, // Spread the previous state to retain existing keys
          ...val,              // Merge the new dynamic updates
        },
      }));
    } else {
      this.setState({ change_val: {} });
    }
  }

  getDataBetweenBraces = (template, data, manual_input="") => {
    if (template) {
      return template.replace(/{{(.*?)}}/g, (_, key) => {
        const trimmedKey = key.trim();
        if (trimmedKey === "user_id") {
          return localStorage.getItem("in_userid");
        }
        if (trimmedKey === "branch_id") {
          return localStorage.getItem("branch_id");
        }
        if (
          trimmedKey === "effective_designation_id" ||
          trimmedKey === "effective_designation"
        ) {
          return localStorage.getItem("e_designation_id");
        }
        if (
          manual_input != "" && (trimmedKey === "INPUT" ||
          trimmedKey === "input")
        ) {
          return manual_input;
        }
        if (this.state.componentGlobalState &&
          this.state.componentGlobalState.hasOwnProperty(trimmedKey) &&
          this.state.componentGlobalState[trimmedKey] != null) {
          return this.state.componentGlobalState[trimmedKey];
        }
        if(data){
          //return this.getValue(data, trimmedKey);
          const queryParam = this.getValue(data, trimmedKey);

          // Check if queryParam is an object
          if (typeof queryParam === "object" && queryParam !== null) {
              // If the query parameter is an object, convert it to a query string
              return Object.entries(queryParam)
                  .map(
                      ([key, value]) =>
                          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
                  )
                  .join("&");
          } else {
              // If not an object, return the value as-is
              return encodeURIComponent(queryParam);
          }
        }
      });
    }
  };

  getValue = (data, path) => {
    const keys = path.split(".");
    let value = data;
    for (const key of keys) {
      if (value === undefined || value === null) {
        return ""; // Return empty string if value is undefined or null
      }
      // Check if key contains array notation, e.g., "cash_transfer_log[0]"
      if (key.includes("[")) {
        const [arrayKey, index] = key.split(/[[\]]/).filter(Boolean);
        value = value[arrayKey];
        if (!value) return ""; // Early return if array key is not found
        value = value[Number(index)];
      } else {
        value = value[key];
      }
    }
    return value !== undefined ? value : "";
  };
  combineUniqueNextPossibleValues = (data) => {
    const seenValues = new Set();
    const combinedOutput = [
      data.reduce(
        (acc, entry) => {
            if (entry.next_possible_values && entry.next_possible_values_path) {
                entry.next_possible_values.forEach((value, index) => {
                    if (!seenValues.has(value)) {
                        seenValues.add(value);
                        acc.next_possible_values.push(value);
                        acc.next_possible_values_path.push(entry.next_possible_values_path[index]);
                    }
                });
            }
            return acc;
        },
        { next_possible_values: [], next_possible_values_path: [] }
      )
    ];
    return combinedOutput;
  };
  getfirstrowstatus = async (massupdate) => {
    const filteredList = this.state.payload_config.field_mapping.filter(
      (item) => {
        const result =
          item.datatype === "dropdownlist" &&
          item.dropdowncondition &&
          item.dropdowncondition.length > 0;
        return result;
      }
    );
    this.setState({ dropdowncondition: filteredList });
    //console.log("--Reasonlist", filteredList);

    let dropdown_id = "";
    let item_value = "";
    let fieldname = "";
    if (filteredList.length > 0) {
      fieldname = filteredList[0].BucketField;
      // item_value = massupdate[0][fieldname];
      dropdown_id = filteredList[0].dropdownlist;
    }
    if (dropdown_id) {
      const getuniquedata = [
        ...new Set(massupdate.map((item) => item[fieldname])),
      ];
      let generate_Id = generateId(10);
      if (this.state.isGetFirstdata == 0) {
        const requestOptions = {
          method: "GET",
          headers: {
            "api-token": this.state.api_token,
            component_id: generate_Id1,
            apiId: generate_Id,
            api_group_id: "",
            component: "payloadview",
            user_id: localStorage.getItem("in_userid"),
          },
        };

        try {
          // Make all API calls and combine their results
          const Massresults = await Promise.all(
            getuniquedata.map(async (data) =>
              this.callApiForStatus(dropdown_id, data, requestOptions)
            )
          );
          // Filter out null results and combine the responses
          const combinedResults = Massresults.filter(
            (result) => result !== null
          ).reduce((acc, current) => {
            return acc.concat(current);
          }, []);

          const ReasonlistObject = {
            [fieldname]: combinedResults,
          };
          this.setState(
            {
              Reasonlist_default: ReasonlistObject,
            }
          );
          let finalResults =
            this.combineUniqueNextPossibleValues(combinedResults);

          // Your existing code for processing the combined and filtered results
          finalResults.forEach((json) => {
            let resultObject = "";
            if (json.next_possible_values) {
              const filteredArray =
                this.state.Reasonlist &&
                this.state.Reasonlist[fieldname].filter((item) =>
                  json.next_possible_values.includes(item.item_value)
                );

              resultObject = {
                [fieldname]: filteredArray,
              };
            } else {
              resultObject = {
                [fieldname]: "",
              };
            }
            const next_possible_values = json.next_possible_values;
            let next_possible_values_path = json.next_possible_values_path;

            if (next_possible_values_path) {
              if (!Array.isArray(next_possible_values_path)) {
                try {
                  // Try to parse the data as JSON
                  next_possible_values_path = JSON.parse(
                    next_possible_values_path
                  );

                  // Check again if the parsed data is an array
                  if (!Array.isArray(next_possible_values_path)) {
                    throw new Error("Data is not an array");
                  }
                } catch (error) {
                  console.error("Error parsing data as JSON:", error);
                }
              }
              let finaldropdownResults = resultObject[fieldname];
              if (
                filteredList[0].dropdowncondition &&
                filteredList[0].dropdowncondition.length > 0
              ) {
                finaldropdownResults[fieldname] = resultObject[
                  fieldname
                ].filter((item) => {
                  return filteredList[0].dropdowncondition.some((condition) => {
                    return condition.filtereddropdownlist === item.item_value && (condition.usedfor == "MU" || condition.usedfor == "BD");
                  });
                });
                // resultObject[fieldname].forEach((status) => {
                //   const index = next_possible_values.indexOf(status.item_value);
                //   if (index !== -1) {
                //     status.item_name = next_possible_values_path[index];
                //   }
                // });
                const updatedStatuses = finaldropdownResults[fieldname].map(
                  (status) => {
                    const index = next_possible_values.indexOf(
                      status.item_value
                    );
                    if (index !== -1) {
                      return {
                        ...status,
                        item_name: next_possible_values_path[index],
                      };
                    }
                    return status;
                  }
                );

                finaldropdownResults[fieldname] = updatedStatuses;
              } else {
                finaldropdownResults = resultObject;
              }
              this.setState({
                MassReasonlist: finaldropdownResults,
                isGetFirstdata: 1,
              });
            } else {
              this.setState({
                MassReasonlist: resultObject,
                isGetFirstdata: 1,
              });
            }
          });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }
  };
  findCommonElements = (array1, array2) => {
    return array1.filter((element) => array2.includes(element));
  };
  callApiForStatus = async (dropdown_id, item_value, requestOptions) => {
    try {
      const res = await customFetch(
        `${process.env.REACT_APP_URL}/get/dropdown/item/master/data/${dropdown_id}/${item_value}`,
        requestOptions
      );
      return await res.json();
    } catch (error) {
      console.error(`Failed to fetch status ${item_value}`, error);
      return null;
    }
  };

  checkanyoperation = (operationfield) => {
    const findglobalconfig = this.state.payload_config.field_mapping.filter(
      (item) =>
        (item.manuall_condition_field === operationfield ||
          item.manuall_condition_field_with === operationfield) &&
        item.manuall_operator !== "sum"
    );
  
    console.log("findglobalconfig", findglobalconfig); // Debug log
  
    if (findglobalconfig.length > 0) {
      findglobalconfig.forEach((global_field) => {
        if (this.state.componentGlobalState) {
          // Extract and clean value1
          const numericString1 = String(this.state.componentGlobalState[
            global_field.manuall_condition_field
          ]).replace(/(?!^-)[^0-9]/g, "");
          const cleanedString1 = numericString1.replace(/(?!^-)\D/g, "");
          const value1 = parseInt(cleanedString1, 10);
  
          // Extract and clean value2
          const numericString2 = String(this.state.componentGlobalState[
            global_field.manuall_condition_field_with
          ]).replace(/(?!^-)[^0-9]/g, "");
          const cleanedString2 = numericString2.replace(/(?!^-)\D/g, "");
          const value2 = parseInt(cleanedString2, 10);
  
          const operator = global_field.manuall_operator;
  
          console.log("value1", value1); // Debug log
          console.log("value2", value2); // Debug log
          console.log("operator", operator); // Debug log
  
          let computedResult;
          switch (operator) {
            case "+":
              computedResult = value1 + value2;
              break;
            case "-":
              computedResult = value1 - value2;
              break;
            case "*":
              computedResult = value1 * value2;
              break;
            case "/":
              computedResult = value1 / value2;
              break;
            default:
              computedResult = 0;
              break;
          }
  
          console.log("computedResult", computedResult); // Debug log
  
          // Update state with the computed result
          this.updateComponentGlobalState(
            { [global_field.BucketField]: computedResult },
            () => {
              console.log("State updated for", global_field.BucketField); // Debug log
            }
          );
        } else {
          console.error("componentGlobalState is undefined or null"); // Debug log
        }
      });
    } else {
      console.log("No matching global config found"); // Debug log
    }
  };
  convertInput = (value, dataType) => {
    if (dataType === "int") {
      const cleanedValue = value.replace(/\D+/g, ""); // Remove all non-digits
      return cleanedValue ? cleanedValue : ""; // Return cleaned value or empty string
    } else if (dataType === "float") {
      const cleanedValue = value.replace(/[^0-9.]/g, ""); // Keep only numbers and dots
      const validFloat = cleanedValue.match(/^\d*\.?\d*$/) ? cleanedValue : "";
      return validFloat;
    }
    return value; // Return original value if no valid data type is found
  };
  handlemassInputChange = (index, key, value) => {    
    const findglobalconfig = this.state.payload_config.field_mapping.filter(
      (item) => item.BucketField === key
    );

    const resultvalue = this.convertInput(value,(findglobalconfig[0].valueType || ""));
    if(findglobalconfig[0].custom_field == "1"){
      //this.updateComponentGlobalState({ [key]: value });
      this.updateComponentGlobalState({ [key]: resultvalue }, () => {
        console.log('State has been updated!');
        this.checkanyoperation(key);
      });
      
    } else {
      this.setState((prevState) => {
        const newData = [...prevState.massUpdateInput];
        if (!newData[index]) {
          // If the array element doesn't exist yet, create it
          newData[index] = { my_key: key, resultvalue: "" };
        }
        newData[index].my_key = key;
        newData[index].value = resultvalue; // Set value to e.target.value
        for (let i = 0; i < newData.length; i++) {
          if (newData[i] === null || !newData[i]) {
            newData[i] = { my_key: "", value: "" };
          }
        }
        let mascheckboxsts = true;
        if (newData.length > 0 && newData.every(item => 
          item.value !== null && !Number.isNaN(item.value) 
        ) ) {
          mascheckboxsts = false;        
        }
        return {
          massUpdateInput: newData,
          defaultCheckbox: mascheckboxsts
        };      
      });
    }
    const findconfig = this.state.payload_config.field_mapping.filter(
      (item) => item.BucketField === key
    );
    findconfig.forEach((conf_data) => {
      if (conf_data.datatype == "dropdownlist" && value != NaN) {
        this.setState({ filters: {} });
        const current_data = this.state.Reasonlist[key].filter((item) => {
          if (item.next_possible_values) {
            const possibleValues = item.next_possible_values;
            return possibleValues.includes(value);
          }
          return false;
        });
        const filtername = this.state.MassReasonlist[key].filter(
          (item) => item.item_value == value
        );
        if (filtername.length > 0) {
          this.setState({
            filter_name: filtername.length > 0 ? filtername[0].item_name : "",
          });
        } else {
          this.setState({ filter_name: "" });
        }
        if (current_data.length > 1) {
          // this.setState({
          //   filters: {},
          //   filtered_cnt: this.state.payload_data.length,
          // });
          // current_data.map((cur_data) => {
          //   const updatedFilters = {
          //     ...this.state.filters,
          //     [key]: Array.isArray(this.state.filters[key])
          //       ? [...this.state.filters[key], cur_data.item_value !== null ? cur_data.item_value.toString() : undefined]
          //       : [cur_data.item_value !== null ? cur_data.item_value.toString() : undefined],
          //   };

          //   this.setState({ filters: updatedFilters }, () => {
          //     console.log("updatedFilters", updatedFilters);
          //     this.getfiltercnt();
          //   });
          // });
          const filters = current_data.reduce((acc, item) => {
            acc[key] = (acc[key] || []).concat(item.item_value);
            return acc;
          }, {});
          this.setState({ filters: filters }, () => {
            this.getfiltercnt(value);
          });
        } else {
          current_data.map((cur_data) => {
            const updatedFilters = {
              ...this.state.filters,
              [key]: cur_data.item_value.toString(),
            };

            this.setState(
              {
                filters: updatedFilters,
              },
              () => this.getfiltercnt(value)
            );
          });
        }
      }
    });
  };
  getfiltercnt = (value) => {
    const filtercnt = this.filterData();
    const dropdownCondition =
      this.state.dropdowncondition[0].dropdowncondition.filter(
        (item) => item.filtereddropdownlist == value
      );

    this.setState(
      {
        filtered_cnt: filtercnt.length,
        dropdoenFiltered_data: filtercnt,
        filtered_condition: dropdownCondition,
      },
      () => this.getconfigsum()
    );
  };
  // checkanduploadfile = () => {
  //   return new Promise((resolve, reject) => {
  //     const filteredList = this.state.payload_config.field_mapping.filter(
  //       (item) => item.isupload === "1"
  //     );
  //     if (filteredList.length === 0) {
  //       resolve(false); // No file to upload, resolve with false
  //       return;
  //     }

  //     const file = this.state[filteredList[0].BucketField];
  //     if (file) {
  //       const fileExtension = file.name.split(".").pop();
  //       let filename = filteredList[0].UploadName
  //         ? `${filteredList[0].UploadName}.${fileExtension}`
  //         : file.name;
  //       const filepath = `${filteredList[0].UploadPath}/${filename}`;

  //       const formdata = new FormData();
  //       formdata.append("file", file);
  //       formdata.append(
  //         "blob_url",
  //         `deposit_slips_by_transaction_officer/${file.name}`
  //       );

  //       const requestOptions = {
  //         method: "POST",
  //         headers: {
  //           "api-token": this.state.api_token,
  //         },
  //         body: formdata,
  //       };

  //       customFetch(
  //         process.env.REACT_APP_URL + "/save/files/bucket",
  //         requestOptions
  //       )
  //         .then((response) => response.json())
  //         .then((data) => {
  //           const newItem = {
  //             my_key: filteredList[0].BucketField,
  //             value: data[0].blob_url,
  //           };
  //           const newData = [...this.state.massUpdateInput, newItem];

  //           this.setState({ isSpin: false, massUpdateInput: newData }, () => {
  //             console.log("massUpdateInput", this.state.massUpdateInput);
  //             resolve(newData); // Resolve with true to indicate state update
  //           });
  //         })
  //         .catch((error) => {
  //           console.log("error", error);
  //           reject(error);
  //         });
  //     } else {
  //       resolve(false); // Resolve with false if no file to upload
  //     }
  //   });
  // };

  checkanduploadfile =async (payloaddata = [], fileUploadData = []) => {
    this.setState({ isspin: true });
    let filedata = fileUploadData;

    return new Promise((resolve, reject) => {
      const filteredList = this.state.payload_config.field_mapping.filter(
        (item) => item.isupload === "1"
      );

      if (filteredList.length === 0) {
        resolve(false);
        return;
      }

      if (filedata.length == 0) {
        filedata = this.state[filteredList[0].BucketField];
      }

      // Flatten file data from nested structure
      const filesArray = filedata.flatMap((fileConfig) => {
        const { selectType, UploadPath, UploadName } = fileConfig;

        return fileConfig.files.flatMap((fileItem) => {
          if (fileItem && fileItem.filelistdata) {
            return fileItem.filelistdata.map((file) => {
              const fileExtension = file.name.split(".").pop();
              let filename = UploadName
                ? `${UploadName}.${fileExtension}`
                : file.name;

              if (payloaddata && UploadName) {
                filename =
                  UploadName.replace(/{{(.*?)}}/g, (_, key) => {
                    const trimmedKey = key.trim();
                    switch (trimmedKey) {
                      case "user_id":
                        return localStorage.getItem("in_userid");
                      case "branch_id":
                        return localStorage.getItem("branch_id");
                      case "effective_designation_id":
                      case "effective_designation":
                        return localStorage.getItem("e_designation_id");
                      default:
                        const val = this.getValue(payloaddata, trimmedKey);
                        if (val) {
                          return val;
                        } else {
                          return filename;
                        }
                    }
                  }) + `.${fileExtension}`;
              }

              return {
                selectType,
                UploadPath,
                fileName: filename,
                file,
              };
            });
          }
          return []; // Return empty array if no filelistdata
        });
      });
      const documentTypes = filesArray.map((item) => item.selectType).join(",");
      const remotePaths = filesArray.map((item) => item.UploadPath).join(",");

      const uniqueFilenames = new Set();
      filesArray.forEach((file) => {
        let uniqueFileName = file.fileName;
        let counter = 1;

        while (uniqueFilenames.has(uniqueFileName)) {
          const parts = uniqueFileName.split(".");
          const filename = parts[0];
          const extension = parts[1];
          uniqueFileName = `${filename}_${counter}.${extension}`;
          counter++;
        }

        uniqueFilenames.add(uniqueFileName);
        file.fileName = uniqueFileName;
      });

      // Create FormData and append files
      const formdata = new FormData();
      // formdata.append("household_id", 0);
      filesArray.forEach((item, index) => {
        if (item.file) {
          const fileToUpload =
            item.file instanceof File
              ? item.file.originFileObj
              : new File([item.file.originFileObj], item.fileName || "unknown");
          formdata.append("file", fileToUpload, item.fileName);
        } else {
          console.error(`Invalid or missing file at index ${index}`);
        }
      });
      formdata.append("uploaded_by", localStorage.getItem("in_userid"));
      formdata.append("document_type", documentTypes);
      formdata.append("remote_path", remotePaths);
      formdata.append("family_member_id", 0);

      let path = "";
      this.state.payload_config.field_mapping.forEach((field) => {
        if (field.payloadpathIsupload && field.payloadpathIsupload != "") {
          path = field.payloadpathIsupload;
        }
      });

      const householdIdStr = this.getDataBetweenBraces(path, payloaddata);
      console.log(`householdIdStr: ${householdIdStr}`); // Debugging line

      const householdId = isNaN(householdIdStr)
        ? 0
        : parseInt(householdIdStr, 10);
      formdata.append("household_id", householdId);

      // Append each file with unique key in FormData

      const requestOptions = {
        method: "POST",
        headers: {
          "api-token": this.state.api_token,
        },
        body: formdata,
      };

      customFetch(
        process.env.REACT_APP_URL + "/multiple/file/upload",
        requestOptions
      )
        .then((response) => response.json())
        .then(async(data) => {
          const groupedData = {};

          filedata.forEach((item) => {
            item.files.forEach((file) => {
              if (file.filelistdata) {
                const { docType, selectType } = file;
                const filePath = data[0].uploaded_files.shift(); // Assuming correct order

                if (!groupedData[selectType]) {
                  groupedData[selectType] = [];
                }

                groupedData[selectType].push({
                  file_path: filePath,
                  retention_period: item.UploadRetention,
                });
              }
            });
          });
          const doc_group = this.state.payload_config.field_mapping;

          const fileUploadField = doc_group.find(
            (field) => field.BucketField === filteredList[0].BucketField
          );

          const docGroupValue = fileUploadField.doc_group;
          // if (fileUploadField) {
          //   console.log('Doc Group:', docGroupValue);
          //   docGroupValue = fileUploadField.doc_group;
          // }
          console.log("groupedData", fileUploadField);
          let newgroupedData = [];
          if (docGroupValue != "") {
            newgroupedData = [{
              [docGroupValue]: [groupedData],
            }];
          } else {
            newgroupedData = groupedData;
          }
          const newItem = {
            my_key: filteredList[0].BucketField.split(/[.:]/)[0],
            // value: data[0] ? data[0].blob_url : "",
            value: newgroupedData,
          };
          const transformed_data_old = await this.transformDataStructure([
            newItem,
          ]);
          
          const transformed_data = {
            my_key: filteredList[0].BucketField,
            value: transformed_data_old,
          };
          // this.transformDataStructure([newItem]).then((transformed_data) => {
          const newData = [...this.state.massUpdateInput, transformed_data];

          //this.setState({ isSpin: false, massUpdateInput: newData });
          this.setState({ isSpin: false, massUpdateInput: newData }, () => {
            console.log("massUpdateInput", this.state.massUpdateInput);
            resolve(newData); // Resolve with true to indicate state update
          });
        })
        .catch((error) => {
          console.log("error", error);
          reject(error);
        });
    });
  };

  transformDataStructure = async (processedData) => {
    const result = {};

    // Wrap single objects into an array for consistent processing
    const dataArray = Array.isArray(processedData)
      ? processedData
      : [processedData];

    dataArray.forEach((item) => {
      if (item.my_key && item.value) {
        // Split `my_key` dynamically into parts using both dot and colon as separators
        const pathParts = item.my_key.split(/[.:]/);
        let currentLevel = result;

        // Dynamically traverse and create the nested structure
        for (let i = 0; i < pathParts.length; i++) {
          const part = pathParts[i];
          if (i === 0) {
            // Handle the first key as an array containing objects
            if (!currentLevel[part]) {
              currentLevel[part] = [{}];
            }
            currentLevel = currentLevel[part][0]; // Always work with the first object

            // If the first key is also the last key, directly assign the value
            if (pathParts.length === 1) {
              Object.assign(currentLevel, item.value);
            }
          } else {
            // Create nested objects as needed
            if (!currentLevel[part]) {
              currentLevel[part] = {};
            }
            currentLevel = currentLevel[part];
          }
        }
      }
    });

    return result;
  };

  arrayToObjectWithEmptyValues = (arr) => {
    return arr.reduce((acc, item) => {
      acc[item] = ""; // Set each key to an empty string
      return acc;
    }, {});
  };

  updatemassdata = async () => {
    // if (
    //   this.state.partial_amt_by_cso != "" &&
    //   this.state.partial_amt_by_cso.partial_amt != undefined
    // ) {
    //   let dbname, collectionname;
    //   if (
    //     !this.state.payload_config.db_name ||
    //     this.state.payload_config.db_name == ""
    //   ) {
    //     dbname = "callbacks";
    //   } else {
    //     dbname = this.state.payload_config.db_name;
    //   }
    //   if (
    //     !this.state.payload_config.collection_name ||
    //     this.state.payload_config.collection_name == ""
    //   ) {
    //     collectionname = "callback_payloads";
    //   } else {
    //     collectionname = this.state.payload_config.collection_name;
    //   }
    //   let partial_payload = this.arrayToObjectWithEmptyValues(
    //     this.state.payload_config.selectedFieldForPartial.split(",")
    //   );
    //   let insertPayload = {};
    //   const res = this.state.payload_config.field_mapping[0].entries.map(
    //     (item) => {
    //       if (item.partialfield === "amount_collected") {
    //         insertPayload[item.partialfield] = item.defaultvalue
    //           ? item.defaultvalue
    //           : this.state.editfieldsumm - this.state.totalSum;
    //       } else {
    //         insertPayload[item.partialfield] = item.defaultvalue
    //           ? item.defaultvalue
    //           : this.state.payload_data[0][item.partialfield];
    //       }
    //     }
    //   );
    //   this.setState({ isspin: true });
    //   const response = await insert_mongopayload(
    //     this.state.api_token,
    //     dbname,
    //     collectionname,
    //     insertPayload
    //   );
    //   this.setState({ isspin: false });

    //   this.setState({
    //     partial_amt_by_cso: {},
    //   });
    // }
    // if (this.state.hasFile == 1) {
    //   const checkanduploadfile = await this.checkanduploadfile();
    // }
    let txn_no = "NDD" + generateId(7) + "_" + generateId(5);
    let isFileUploaded = false;
    let uploadedFileData = [];
    if (this.state.hasFile === 1) {
      try {
        uploadedFileData = await this.checkanduploadfile();
      } catch (error) {
        console.error("File upload failed:", error);
        return;
      }
    } else {
      isFileUploaded = true;
    }
    if (isFileUploaded || uploadedFileData.length > 0) {
      const filteredData = this.filterData();
      const usethisfield = this.state.payload_config.field_mapping
        .filter((field) => field.useasinput === "1")
        .map((field) => field.BucketField);
      var filtered_Data = [];
      var targetIds = this.state.selectedMassUpdateData;
      if (
        this.state.payload_config.payload_type == "M" &&
        this.state.selectedMassUpdateData
      ) {
        filtered_Data =
          filteredData &&
          filteredData.filter(function(item) {
            return item._id && targetIds.includes(item._id.$oid);
          });
      } else if (usethisfield) {
        filtered_Data =
          filteredData &&
          filteredData.filter(function (item) {
            return item[usethisfield] && targetIds.includes(item[usethisfield]);
          });
      }

      const autogeneratetxn = this.state.payload_config.field_mapping.filter(
        (item) => item.autogeneratetxn === "1"
      );
      
      const objectsEqual = (obj1, obj2) =>
        JSON.stringify(obj1) === JSON.stringify(obj2);
      
      const changedData = [];
      
      const filteredmassUpdateInput = this.state.massUpdateInput.filter(
        (item) => item !== null && item !== undefined
      );
      
      const generateValueFromRegex = (regexPattern) => {
        if (!regexPattern || typeof regexPattern !== "string") return "";
      
        try {
          const RandExp = require("randexp").default || require("randexp");
          const randexp = new RandExp(regexPattern);
          randexp.defaultRange.add(0, 65535);
          return randexp.gen();
        } catch (error) {
          console.error("Invalid regex pattern:", regexPattern, error);
          return "";
        }
      };
      
      filteredmassUpdateInput.forEach((item) => {
        if (item && item.my_key !== undefined && item.value !== undefined) {
          filtered_Data.forEach((filteredItem) => {
            let changedItem = {};
      
            if (autogeneratetxn.length > 0) {
              let txn_ref_no = "";
              autogeneratetxn.forEach((autogenConfig) => {
                
      
                if (["transaction_no", "random"].includes(autogenConfig.selectConfig)) {
                  const regex = autogenConfig.regularExpression || ".*";
                  console.log("Using regularExpression:", regex);
                  txn_ref_no = generateValueFromRegex(regex);
      
                  if (autogenConfig.isappendtxn === "1") {
                    txn_ref_no = `${filteredItem[autogenConfig.BucketField]}||${txn_ref_no}`;
                  }
                } else if (autogenConfig.selectConfig === "date_time") {
                  const dateTimeFormat = autogenConfig.dateTimeFormat;
                  const currentDateTime = (() => {
                    switch (dateTimeFormat) {
                      case "iso":
                        return new Date().toISOString();
                      case "us":
                        return new Date().toLocaleString("en-US");
                      case "intl":
                        return new Date().toLocaleString("en-GB");
                      case "unix":
                        return Math.floor(Date.now() / 1000);
                      case "rfc2822":
                        return new Date().toUTCString();
                      case "custom":
                        return new Date().toLocaleString("en-IN", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        });
                      default:
                        return new Date().toISOString();
                    }
                  })();
      
                  txn_ref_no = autogenConfig.isappendtxn === "1"
                    ? `${filteredItem[autogenConfig.BucketField]}||${currentDateTime}`
                    : currentDateTime;
                } else if (autogenConfig.selectConfig === "user_id") {
                  const userId = localStorage.getItem("in_userid");
                  txn_ref_no = autogenConfig.isappendtxn === "1"
                    ? `${filteredItem[autogenConfig.BucketField]}||${userId}`
                    : userId;
                }
                this.updateComponentGlobalState({ txn_ref_no: txn_ref_no });
                // Check if the field value has changed
                if (
                  filteredItem &&
                  filteredItem[item.my_key] !== undefined &&
                  filteredItem[item.my_key] !== item.value
                ) {
                  changedItem = {
                    ...changedItem, // Preserve existing fields
                    object_id: filteredItem._id.$oid,
                    [autogenConfig.BucketField]: txn_ref_no,
                    [item.my_key]: filteredItem[item.my_key], // Old value
                    [item.my_key]: item.value, // New value
                  };
                } else {
                  changedItem = {
                    ...changedItem, // Preserve existing fields
                    object_id: filteredItem._id.$oid,
                    [autogenConfig.BucketField]: txn_ref_no,
                    [item.my_key]: item.value, // New value
                  };
                }
              });
            } else {
              // If no autogeneratetxn configuration, check if the field value has changed
              if (
                filteredItem &&
                filteredItem[item.my_key] !== undefined &&
                filteredItem[item.my_key] !== item.value
              ) {
                changedItem = {
                  object_id: filteredItem[usethisfield]
                    ? filteredItem[usethisfield]
                    : filteredItem._id.$oid,
                  [item.my_key]: filteredItem[item.my_key], // Old value
                  [item.my_key]: item.value, // New value
                };
              } else {
                changedItem = {
                  object_id: filteredItem[usethisfield]
                    ? filteredItem[usethisfield]
                    : filteredItem._id.$oid,
                  [item.my_key]: item.value, // New value
                };
              }
            }
      
            // Add the changedItem to changedData if it doesn't already exist
            if (!changedData.some((changed) => objectsEqual(changed, changedItem))) {
              changedData.push(changedItem);
            }
          });
        }
      });      
            
      this.setState({ DataForMassUpdate: filtered_Data });
      //this.update_mass_data(filtered_Data);
      let confirmValues = [];

      for (const dataItem of changedData) {
        for (const configItem of this.state.payload_config.field_mapping) {
          const {
            BucketField,
            isConfirm,
            mass_condition_field,
            mass_condition_value,
            showcondition,
          } = configItem;

          if (dataItem[BucketField] !== undefined && isConfirm === "1") {
            confirmValues.push({
              [BucketField]: dataItem[BucketField],
              confirm: "your_confirm_value_here", // Add your confirm value here
            });
            // Exit the loop if isConfirm is 1
            break;
          }

          // if (
          //   showcondition == 1 &&
          //   mass_condition_value
          //     .split(",")
          //     .includes(
          //       this.state.massUpdateInput.length > 0 &&
          //         this.state.massUpdateInput
          //           .find((item) => item.my_key == mass_condition_field)
          //           .value.toString()
          //     )
          // ) {
          //   console.log("configItem", this.state.massUpdateInput);

          // }
          // if (configItem.massrequire == 1) {
          //   //BucketField
          //   if (
          //     this.state.massUpdateInput.some(
          //       (item) => item.my_key === configItem.BucketField
          //     ) == false
          //   ) {
          //     this.setState({
          //       isMassupdclick: false,
          //     });
          //     alert(`Please enter value for ${configItem.fieldname}`);
          //     return;
          //   }
          // }
          if (configItem.massrequire === "1") {
            // Check if mass_condition_field exists in massUpdateInput
            // let massConditionFieldValue = this.state.massUpdateInput.find(
            //   (item) => item.my_key === configItem.mass_condition_field
            // );

            let massConditionFieldValue = this.state.massUpdateInput.find(
              (item) =>
                item.my_key === configItem.mass_condition_field &&
                mass_condition_value.includes(item.value)
            );

            if (massConditionFieldValue) {
              // Get the corresponding BucketField
              let bucketField = configItem.BucketField;
              let fieldname = configItem.fieldname;

              // Check if BucketField exists in massUpdateInput
              let bucketFieldItem = this.state.massUpdateInput.find(
                (item) => item.my_key === bucketField
              );
              if (bucketFieldItem) {
                // Check if the value exists and is not empty
                if (bucketFieldItem.value) {
                  console.log(`BucketField '${bucketField}' has a value.`);
                } else {
                 
                  this.setState({
                    isMassupdclick: false,
                  });
                  alert(`Please enter value for ${fieldname}`);
                  return;
                }
              } else {
                
                this.setState({
                  isMassupdclick: false,
                });
                alert(`Please enter value for ${fieldname}`);
                return;
              }
            } else {
              if (configItem.mass_condition_field) {
                console.log(
                  `mass_condition_field '${configItem.BucketField}' does not exist in massUpdateInput.`
                );
              } else {
                console.log(
                  `mass_condition_field '${configItem.BucketField}' does not exist in massUpdateInput else.`
                );

                if (
                  this.state.massUpdateInput.some(
                    (item) => item.my_key === configItem.BucketField
                  ) == false
                ) {
                  this.setState({
                    isMassupdclick: false,
                  });
                  alert(`Please enter value for ${configItem.fieldname}`);
                  return;
                }
              }
            }
          }
        }
      }

      // check for condition
      let isvalid = true;
      if (this.state.filtered_condition && this.state.filtered_condition.length > 0) {
        if (this.state.filtered_condition[0].filteredCondition) {
          if (this.state.filtered_condition[0].filteredCondition == "1") {
            // if (filteredData.length != changedData.length) {
            if (filteredData.length != this.state.filtered_cnt) {
              isvalid = false;
              this.setState({ isMassupdclick: false });
            }
          }
        }

        if (
          this.state.filtered_condition[0].massValidationKey &&
          this.state.filtered_condition[0].massValidationvalue
        ) {
          const uniqueStatuses = Array.from(
            new Set(
              this.state.payload_data.map(
                (item) =>
                  item[this.state.filtered_condition[0].massValidationKey]
              )
            )
          );
          if (
            uniqueStatuses.some((value) =>
              this.state.filtered_condition[0].massValidationvalue.includes(
                value
              )
            )
          ) {
            isvalid = false;
          }
        }
      }
      if (isvalid) {
        const updatemassapi = this.state.payload_config.action_mapping.filter(
          (item) => item.action_event === "massupdate"
        );
        const combinedata = this.mergeDataByObjectId(changedData);

        if (confirmValues.length > 0) {
          const isConfirmed = window.confirm(
            "Are you sure you want to update the value?"
          );
          if (isConfirmed) {
            if (updatemassapi.length > 0) {
              this.updatemassapi(changedData, updatemassapi);
            } else {
              combinedata.map((massdata, index) => {
                let object_id = massdata["object_id"];
                delete massdata["object_id"];

                this.update_mass_data(
                  object_id,
                  massdata,
                  combinedata.length,
                  index
                );
              });
            }
          }
        } else {
          if (updatemassapi.length > 0) {
            this.updatemassapi(changedData, updatemassapi);
          } else {
            combinedata.map((massdata, index) => {
              let object_id = massdata["object_id"];
              delete massdata["object_id"];

              this.update_mass_data(
                object_id,
                massdata,
                combinedata.length,
                index
              );
            });
          }
        }
      } else {
        alert("You are not allowed to perform this action");
      }
    }
  };
  mergeDataByObjectId = (data) => {
    return Object.values(
      data.reduce((acc, item) => {
        if (!acc[item.object_id]) {
          acc[item.object_id] = { object_id: item.object_id };
        }
        Object.assign(acc[item.object_id], item);
        return acc;
      }, {})
    );
  };
  updatemassapi = async (data, apiconfig) => {
    this.setState({
      isspin: true,
    });
    const result = {};

    data.forEach((item) => {
      const { object_id, ...rest } = item;
      if (!result["all"]) {
        result["all"] = { object_id: [] };
      }
      if (!result["all"].object_id.includes(object_id)) {
        result["all"].object_id.push(object_id);
      }
      for (const [key, value] of Object.entries(rest)) {
        result["all"][key] = value;
      }
    });
    const payload_configdata = this.state.payload_config;
    const previousOutput = Object.values(result);
    const updatedFormData = this.createNewFormData(
      payload_configdata,
      previousOutput
    );
    if (updatedFormData) {
      const form = new FormData();

      updatedFormData.forEach((field) => {
        form.append(
          field.formdatakey,
          this.checkinputtype(field) //field.formdatavalue ? field.formdatavalue : field.defaultvalue
        );
      });
      const requestOptions = {
        method: "POST",
        headers: {
          "api-token": this.state.api_token,
        },
        body: form,
      };
      let url;
      if (apiconfig.length > 0 && apiconfig[0].url_type == "N") {
        url = `${process.env.REACT_APP_URL}/${apiconfig[0].api_url}`;
      } else {
        url = apiconfig[0].api_url;
      }

      const response = await customFetch(url, requestOptions);
      const responsedata = await response.json();
      //alert(responsedata.message);
      this.setState({
        isspin: false,
        isError: true,
        isErrorMessage: responsedata.message,
      });
    }
  };
  checkinputtype = (data) => {
    if (data.formdatavalue !== undefined && data.formdatavalue !== null) {
      if (data.inputtype == "datetime") {
        return moment(data.formdatavalue).format("YYYY-MM-DD HH:MM:ss");
      } else if (data.inputtype == "json") {
        return JSON.stringify(data.formdatavalue);
      } else if (data.inputtype == "int") {
        return parseInt(data.formdatavalue);
      } else {
        return data.formdatavalue;
      }
    } else {
      return data.defaultvalue;
    }
  };
  createNewFormData(data, previousOutput) {
    if (!data || !data.action_mapping || !previousOutput) {
      throw new Error("Invalid input data or previous output.");
    }

    const actionMapping = data.action_mapping.find(
      (action) => action.action_event === "massupdate"
    );

    if (!actionMapping) {
      return [];
    }

    const newFormData = actionMapping.formdata.map((item) => {
      let value;

      // Check if value exists in previousOutput
      const previousData = previousOutput[0];
      if (previousData.hasOwnProperty(item.formdatakey)) {
        value = previousData[item.formdatakey];
      }

      // If the value is still not found, check field_mapping
      if (value === undefined) {
        const fieldMapping = data.field_mapping.find(
          (field) => field.fieldname == item.formdatavalue
        );
        if (fieldMapping) {
          if (fieldMapping.useasinput == "1") {
            value = previousData.object_id; // Use object_id from previousOutput
          } else if (fieldMapping.fieldname === item.formdatavalue) {
            value = previousData[fieldMapping.BucketField]; // Use user_refer from previousOutput
          }
        }
        // if (fieldMapping && fieldMapping.useasinput === "1") {
        //     value = previousData.object_id;  // Pick object_id from previousOutput
        // } else {
        //     value = item.formdatavalue;
        // }

        // Check if defaultvalue has placeholders and replace with corresponding value from previousOutput
        if (item.defaultvalue && item.defaultvalue.includes("{{")) {
          value = this.checkPlaceholders(item.defaultvalue, previousData);
        }
      }

      return {
        ...item,
        formdatavalue: value || item.formdatavalue,
      };
    });

    return newFormData;
  }
  
  updateData(condition, cd, ud,payload=[]) {
    if (typeof ud !== "object" || Array.isArray(ud)) {
        throw new Error("ud must be an object");
    }
    
    const operators = {
        "=": (a, b) => a === b,
        "!=": (a, b) => a !== b,
        ">": (a, b) => a > b,
        "<": (a, b) => a < b,
        ">=": (a, b) => a >= b,
        "<=": (a, b) => a <= b
    };
    
    condition.forEach(cond => {
      let dataset = cond.check_data_from === "CD" ? cd : [ud];
      if (!Array.isArray(dataset)) return;
      
      let conditionMet = dataset.some(entry => {
          let fieldValue = entry.hasOwnProperty(cond.field) 
              ? entry[cond.field] 
              : (entry.add_data_before && entry.add_data_before.hasOwnProperty(cond.field))
                  ? entry.add_data_before[cond.field] 
                  : undefined;
          return fieldValue !== undefined && operators[cond.operator](fieldValue, cond.condition_value);
      });
      
      if (conditionMet) {
        cond.fields_to_update.forEach(updateField => {
            let valueToUpdate = updateField.field_value.includes("{{") ? this.getDataBetweenBraces(updateField.field_value,payload) : updateField.field_value;
            ud[updateField.field_name] = valueToUpdate;
        });
      }
    });
    
    return ud;
  }

  updatemassapis = async (oid) => {
    this.setState({
      isspin: true,
    }); 
  
    const action = this.state.payload_config.action_mapping.filter((item) => {
      
      return item.action_event == "onsave";
    })[0];
    if (action) {          
      const foundObject = this.state.payload_data.find(item => item._id.$oid === oid);
 
      try{
        const updatedFormData = await this.generate_modaldata(action.id,foundObject)
      
      // this.selecapiinputdata(frmdata)
      // .then((data) => {
      //   this.setState({ [frmdata.formdatakey]: data });
      // })
      // .catch((error) => {
      //   console.error("Error:", error);
      //   this.setState({ loading: false });
      // });
  
      let requestOptions = [];
      if (updatedFormData) {
        if (action.inputType == "formrow") {
          requestOptions = {
            method: "POST",
            headers: {
              "api-token": this.state.api_token,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedFormData),
          };
        } else {
        const form = new FormData();
        
        updatedFormData.forEach((field) => {
          form.append(
            field.formdatakey,
            this.checkinputtype(field)
          );
        });
        requestOptions = {
          method: "POST",
          headers: {
            "api-token": this.state.api_token,
          },
          body: form,
        };
      }
        let url;
        if (action.id > 0 && action.url_type == "N") {
          url = `${process.env.REACT_APP_URL}/${action.api_url}`;
        } else {
          url = action.api_url;
        }
  
        const response = await customFetch(url, requestOptions);
        const responsedata = await response.json();
        //alert(responsedata.message);        
        this.setState({
          isspin: false,
          // isError: true,
          // isErrorMessage: responsedata.message,
        });
        if(responsedata.status == 0){
          this.setState({
            isError: true,
            isErrorMessage: responsedata.message,
          });
        }
      }
      } catch (error) {
        console.error("Error in updatemassapis:", error);
        this.setState({ isspin: false });
      }
    } 
  };
  
  generate_modaldata = async (
    action_id,
    payload_data,    
  ) => {
    const action = this.state.payload_config.action_mapping.filter((item) => {
      console.log("item", item);
      
      return item.id == action_id;
    })[0];
    const url = action.api_url;
    // let payload = null;
    // let method = null;
    // Helper function to resolve placeholders
    const checkNullData = (key_name) => {
      if (key_name === "user_id") {
        return localStorage.getItem("in_userid");
      } else if (key_name === "employee_name") {
        return localStorage.getItem("employee_name");
      } else if (key_name === "branch_id") {
        return localStorage.getItem("branch_id");
      } else if (key_name === "transaction_id") {
        return localStorage.getItem("transaction_id");
      } else if (key_name === "updated_at") {
        const now = moment();
        const unixTimestamp = now.unix();
        return unixTimestamp;
      } else if (this.state.componentGlobalState[key_name] !== undefined && this.state.componentGlobalState[key_name] !== null) {
        return this.state.componentGlobalState[key_name];
      } else if (payload_data[key_name] && payload_data[key_name] !== "") {
        return payload_data[key_name];
      }
      return ""; // Default value if no match found
    };
    if (action["formdata"][0].formdatakey !== "") {
      let form = [];
    
      // Iterate over each formdata item
      action["formdata"].forEach((item) => {
        let result;
    
        // Check if defaultvalue contains a placeholder like {{key_name}}
        if (item.defaultvalue && item.defaultvalue.startsWith("{{") && item.defaultvalue.endsWith("}}")) {
          // Extract the key_name from the placeholder
          const key_name = item.defaultvalue.replace(/^{{|}}$/g, "").trim();
    
          // Use the checkNullData function to get the value for the key_name
          result = checkNullData(key_name);
    
          // If no value is found, use the defaultvalue as-is
          if (result === "") {
            result = item.defaultvalue;
          }
        } else {
          // If no placeholder is found, use the defaultvalue as-is
          result = item.defaultvalue;
        }
    
        // Find the corresponding field mapping (if any)
        const fieldMapping = this.state.payload_config.field_mapping.find(
          (field) => field.fieldname === item.formdatakey
        );
    
        // Push the updated formdatavalue into the form array
        form.push({
          formdatakey: item.formdatakey,
          formdatavalue: result,
          isRequired: fieldMapping && fieldMapping.isRequired ? fieldMapping.isRequired : false,
          datatype: fieldMapping && fieldMapping.datatype ? fieldMapping.datatype : "text",
          isEdit: fieldMapping && fieldMapping.isEdit ? fieldMapping.isEdit : false,
          fieldlabel: fieldMapping && fieldMapping.fieldname ? fieldMapping.fieldname : item.formdatakey,
          isError: "",
        });
      });
    
      return form;
    } else if (action["formrow"]) {
      // Function to extract variables from a string
      const extractVariables = (str) => {
        const regex = /{{(.*?)}}/g;
        const matches = str.match(regex);
        return matches ? matches.map((match) => match.slice(2, -2).trim()) : [];
      };
  
      // Function to check and get the replacement values
      const checkNullData = (key_name) => {
        if (key_name === "user_id") {
          return localStorage.getItem("in_userid");
        } else if (key_name === "employee_name") {
          return localStorage.getItem("employee_name");
        } else if (key_name === "branch_id") {
          return localStorage.getItem("branch_id");
        } else if (key_name === "transaction_id") {
          return localStorage.getItem("transaction_id");
        } else if (key_name === "updated_at") {
          const now = moment();
          const unixTimestamp = now.unix();
          return unixTimestamp;
        } else if (this.state.componentGlobalState[key_name] !== undefined && this.state.componentGlobalState[key_name] !== null) {
          return this.state.componentGlobalState[key_name];
        }
        return ""; // Default value if no match found
      };
  
      // Function to replace variables in the object
      const replaceVariablesInObject = (obj, replacementValues) => {
        if (typeof obj === "object" && obj !== null) {
          const result = {};
          for (const key in obj) {
            result[key] = replaceVariablesInObject(obj[key], replacementValues);
          }
          return result;
        } else if (typeof obj === "string") {
          return obj.replace(/{{(.*?)}}/g, (match, variable) => {
            return replacementValues[variable.trim()] || match;
          });
        } else {
          return obj;
        }
      };
  
      // Extract all placeholder keys from the formrow object
      const placeholderKeys = [];
      const extractPlaceholderKeys = (obj) => {
        if (typeof obj === "object" && obj !== null) {
          for (const key in obj) {
            extractPlaceholderKeys(obj[key]);
          }
        } else if (typeof obj === "string") {
          placeholderKeys.push(...extractVariables(obj));
        }
      };
      extractPlaceholderKeys(action["formrow"]);
  
      // Populate replacementValues with actual data
      const replacementValues = {};
      placeholderKeys.forEach((key) => {
        replacementValues[key] = checkNullData(key);
      });
  
      // Replace placeholders with actual values
      const updatedObject = replaceVariablesInObject(action["formrow"], replacementValues);
  
      // Return the updated object
      return updatedObject;
    }
  };  

  update_mass_data = async (
    objectid,
    data,
    allchangedData,
    changedDataIndex
  ) => {
    this.setState({ isspin: true });
    console.log("upddata",data);
    data = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== null && value !== undefined && value !== "")
    );
    if (this.state.payload_config.payload_type == "M" && objectid && data) {
      let dbname, collectionname;
      if (this.state.payload_config.db_name == "") {
        dbname = "callbacks";
      } else {
        dbname = this.state.payload_config.db_name;
      }

      if (this.state.payload_config.collection_name == "") {
        collectionname = "callback_payloads";
      } else {
        collectionname = this.state.payload_config.collection_name;
      }
      if (
        this.state.payload_config.setupdated_at &&
        this.state.payload_config.setupdated_at
      ) {
        data = this.addupdateddata(data);
      }
      const bucketFields = this.state.payload_config.field_mapping.map(
        (item) => item.BucketField
      );
      const addDataBeforeField = this.state.payload_config.field_mapping.filter(
        (item) => item.add_data_before === "1"
      );

      // Initialize the result object
      const result = { add_data_before: {} };

      // Flag to check if any key matched
      let anyKeyMatched = false;

      // Iterate over data keys
      for (const key in data) {
        if (bucketFields.includes(key)) {
          if (addDataBeforeField && addDataBeforeField.find((item)=>item.BucketField === key)) {
            result["add_data_before"][key] = data[key];
            anyKeyMatched = true;
          } else {
            result[key] = data[key];
          }
        } else {
          result[key] = data[key];
        }
      }

      // If no keys matched, ensure add_data_before is an empty object
      if (!anyKeyMatched) {
        result["add_data_before"] = {};
      }
      
      let response = {};
      let updatedData = [];
      if (this.state.payload_config.view_type == "AV") {
        let committype;
        if (this.state.payload_config.commit_type) {
          committype = this.state.payload_config.commit_type;
        } else {
          committype = "1";
        }
        const allResponses = this.getGroupForEntry(result, "SubmitData");
        const updateasresultData = allResponses.map((entry) => {
          return {
            ...result,
            object_id: entry._id.$oid,
          };
        });
        updatedData = updateasresultData.map((item) => {
          return {
            ...item, // Spread the existing properties of the item
            commit_flag: committype, // Add the new property commit with value 1
          };
        });
        let upd_data = [];
        if(this.state.conditional_update.length > 0 && this.state.conditional_update[0].check_data_from !=""){
          upd_data = this.updateData(this.state.conditional_update,this.state.updateUniqueData,updatedData);
        } else {
          upd_data = updatedData;
        }
        response = await update_mongogrouppayload(
          this.state.api_token,
          dbname,
          collectionname,
          upd_data
        );
      } else {
        let upd_data = [];
        if(this.state.conditional_update.length > 0 && this.state.conditional_update[0].check_data_from !=""){
          upd_data = this.updateData(this.state.conditional_update,this.state.updateUniqueData,result);
        } else {
          upd_data = result;
        }

        response = await update_mongopayload(
          this.state.api_token,
          dbname,
          collectionname,
          objectid,
          upd_data
        );
      }
      if (allchangedData - 1 == changedDataIndex) {
        if (response.status == 1) {
          this.updatemassapis(objectid);
          this.setState({ isspin: false });
          let update_data=[];
          if (this.state.payload_config.view_type != "AV") {
            result["object_id"]=objectid;
            update_data = result;
          } else {
            update_data = updatedData;
          }
          this.check_cache_data(update_data);  
          Modal.success({
            content: <pre id="json">{response.message}</pre>,
            title: "Success",
            //onOk: () => window.location.reload(),
          });
          return true;
        } else {
          this.setState({ isspin: false });
          Modal.error({
            content: <pre id="json">{response.message}</pre>,
            title: "Error",
          });
          return false;
        }
      }      
    }
    this.setState({
      massUpdateInput: [],
    });
    //this.getPayloads();    
  };
  check_cache_data = (update_data) => {
    if(this.state.payload_config.reload_api_data=="1"){
      this.getPayloads();
    } else {
      const cached_data = cache_payload_data(update_data,this.state.payload_data,this.state.isLoginuser,this.state.defaultfilter,this.state.search_by_pair,this.state.searchconfig).then((res) => {
      console.log("cached_data",res);
      let parsed_data = [];
      if (
        this.state.payload_config.view_type &&
        this.state.payload_config.view_type == "AV"
      ) {
        parsed_data = this.processAggData(
          res,
          this.state.payload_config.field_mapping
        );
        
      } else {
        parsed_data = res;
      }
      this.setState({ payload_data: parsed_data, selectedMassUpdateData: [], massUpdateInput: [], filter_name:"", filters: {}, },()=>this.filterData());
      })
      .catch((error) => {console.log("error", error);});
    }
  }
  addupdateddata = (data) => {
    const now = moment();
    const unixTimestamp = now.unix();
    let updateddata = {
      ...data,
      updated_at: unixTimestamp,
      updated_by: parseInt(localStorage.getItem("in_userid")),
    };
    return updateddata;
  };
  handleClick = () => {
    this.setState({ isExporting: true }, () => {
      document.getElementById("table-xls-button").click();
      document
        .getElementById("table-xls-button")
        .addEventListener("click", this.handleStateUpdate());
    });
  };
  handleStateUpdate = () => {
    // Update state
    // This will be executed after the export is completed
    this.setState({ isExporting: false });
  };
  storegroupid = (groupid, index, bucket_field) => {
    let generate_Id = generateId(10);
    this.setState({ group_id: groupid });
    const form = new FormData();
    form.append("group_id", groupid);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": this.state.api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: "PaylodView",
        user_id: localStorage.getItem("in_userid"),
      },
      body: form,
    };
    const response = customFetch(
      process.env.REACT_APP_URL + "/partner/add/groupid",
      requestOptions
    );
    // const newgroupid = [...updategroupid, { group_id: groupid }];
    // setupdategroupid(newgroupid);

    // Update the state with the new array
    Modal.success({
      content: <pre id="json">{JSON.stringify(response.message)}</pre>,
      title: "Success",
    });
    this.handlemassInputChange(index, bucket_field, groupid);
    // customFetch(process.env.REACT_APP_URL + "/add/groupid", requestOptions)
    //   .then((res) => {
    //     return res.json();
    //   })
    //   .then((json) => {
    //     setImageView(json.public_url);
    //     setisImageView(true);
    //   });
  };

  adduniqueid = (index, bucket_field) => {
    let maxNumericValue = Number.NEGATIVE_INFINITY;
    let maxGroupID = null;
    this.state.unique_groups &&
      this.state.unique_groups.forEach((item) => {
        if (item.group_id.includes(this.state.supervisor_branch_code)) {
          const numericValue = parseInt(
            item.group_id.replace(this.state.supervisor_branch_code + "G", ""),
            10
          );
          if (numericValue > maxNumericValue) {
            maxNumericValue = numericValue;
            maxGroupID = item.group_id;
          }
        }
      });
    let maxValue;
    if (maxGroupID != null) {
      maxValue = maxGroupID;
    } else {
      maxValue = 0;
    }
    let newUniqueID;
    if (maxValue == 0) {
      newUniqueID = this.state.supervisor_branch_code + "G000001";
    } else {
      let splitid = maxValue.split("G");
      const numericPart = parseInt(splitid[1], 10) + 1;

      // Construct the new group_id
      newUniqueID = splitid[0] + "G" + numericPart.toString().padStart(6, "0");
    }

    // setgroupid(newUniqueID);
    // console.log("groupid", groupid);
    this.storegroupid(newUniqueID, index, bucket_field);
  };
  CustomValueEditor = (props) => {
    if (props.fieldData.datatype === "date") {
      return (
        <div>
          <DatePicker
            dateFormat={dateFormatList}
            onChange={(date) => {
              // Update the query with the selected date
              var ts = moment(date, dateFormatList).unix();
              //var m = moment.unix(ts);
              // const updatedQuery = {
              //   ...props.value,
              //   [props.fieldData.field]: ts,
              // };
              props.handleOnChange(ts);
            }}
          />
        </div>
      );
    }
    return <ValueEditor {...props} />;
  };
  DropdownFilter = (val) => {    
    let bucket_field = val.bucketfield.BucketField.replace(/\./g, "_");
    const getuniquedata = [
      ...new Set(
        this.state.payload_data &&
          this.state.payload_data.map((item) => item[bucket_field])
      ),
    ];
    const newData = getuniquedata.filter(item => (item !== null && item !== undefined));    
    return(<select
      className="form-control"
      style={{
        width: 120,
      }}
      //defaultValue={val.value}
      value={this.state.filters[bucket_field]}      
      onChange={(e) => this.eventNameSearch(e, bucket_field)}
    >
      <option value="">Select</option>
      {
          newData.map((list) => (
            <option value={list}>
              {list}
            </option>
          ))
      }
    </select>)
  }
  SearchDropdownlist = (val) => {
    let dropdown_data = "";
    if (val.bucketfield.dropDownOptions) {
      dropdown_data = val.bucketfield.dropDownOptions;
    }
    let bucket_field = val.bucketfield.BucketField.replace(/\./g, "_");
    const getuniquedata = [
      ...new Set(
        this.state.payload_data &&
          this.state.payload_data.map((item) => item[bucket_field])
      ),
    ];
    const filteredData =
      this.state.Reasonlist_default &&
      this.state.Reasonlist_default[bucket_field] &&
      this.state.Reasonlist_default[bucket_field].filter((item) =>
        getuniquedata.includes(item.item_value)
      );

    let isedits = this.state.Reasonlist_default && val.isedit == true && (
      <select
        className="form-control"
        style={{
          width: 120,
        }}
        defaultValue={val.value}
        value={this.state.filters[bucket_field]}
        // onChange={(event) => {
        //   handlePayloadChange(parseInt(event.target.value), val.bucketfield);
        //   //setReasonData(event.target.value);
        // }}
        onChange={(e) => this.eventNameSearch(e, bucket_field)}
      >
        <option value="">Select</option>
        {this.state.Reasonlist[bucket_field] ? (
          this.state.Reasonlist[bucket_field].map((list) => {
            let color;
                        {/* const findColor = (list) => {
                          this.props.location.state.payload_config.field_mapping[0].search_query.rules.forEach((item) => {
                            console.log('color item', item);
                            if(parseInt(item.value) === list.item_value){
                              color = item.color
                            }
                          })
                        }
                        findColor(list); */}
           return <option value={list.item_value} style={{backgroundColor: color}}>
              {dropdown_data == "V"
                ? list.item_value
                : dropdown_data == "L"
                ? list.item_name
                : dropdown_data == "B"
                ? list.item_value + "-" + list.item_name
                : list.item_name}
            </option>
        })
        ) : (
          <>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </>
        )}
      </select>
    );
    if (val.isedit == false) {
      // let iseditfalse;
      // iseditfalse = Reasonlist.map(
      //   (list) => list.item_value == val.value && list.item_name
      // );
      if (
        this.state.Reasonlist_default &&
        this.state.Reasonlist_default[bucket_field]
      ) {
        const matchingItem = this.state.Reasonlist_default[bucket_field].find(
          (item) => item.item_value == val.value
        );

        // Check if a matching item was found if not then show value
        if (matchingItem) {
          const itemName = matchingItem.item_name;
          isedits = itemName;
        } else {
          isedits = val.value;
        }
      } else {
        isedits = "Select";
      }
    }
    return isedits;
  };
  onLayoutChangeBody(layout, layouts) {
    this.state.bodyLayout = layout;
    this.state.bodyLayout.map((item, index) => {
      this.state.payload_config.component_mapping.body[index]["x"] = item["x"];
      this.state.payload_config.component_mapping.body[index]["y"] = item["y"];
      this.state.payload_config.component_mapping.body[index]["w"] = item["w"];
      this.state.payload_config.component_mapping.body[index]["h"] = item["h"];
    });
    this.state.bodyLayouts = layouts;
  }
  updateFilterval = (val) => {
    if (val) {
      const json = this.state.savedFilter.filter(
        (item) => item._id.$oid === val
      );
      //json[0].view_config
      this.setState({
        updatecheckbox: json[0].view_config,
        updatefiltername: json[0].filtername,
        updatesearchconfig: json[0].search_config,
        updateObjectid: json[0]._id.$oid,
      });

      //      console.log("BucketField", chkdata);
      // this.setState({
      //   checkedCheckboxes: chkdata,
      // });
    }
  };
  filterchange = (val) => {
    if (val) {
      this.setState({
        viewfilter: val,
      });
      const json = this.state.savedFilter.filter(
        (item) => item._id.$oid === val
      );
      const updatedData = this.state.payload_config.field_mapping.map(
        (item) => {
          // Check if the BucketField is present in data1
          const isBucketFieldInData1 = json[0].view_config.includes(
            item.BucketField
          );

          // Update isVisible property based on the presence in data1
          return {
            ...item,
            isVisible: isBucketFieldInData1 ? "1" : "0",
          };
        }
      );

      this.setState(
        (prevState) => ({
          payload_config: {
            ...prevState.payload_config,
            field_mapping: updatedData,
          },
          searchconfig: json[0].search_config,
          search_click: 1,
        }),
        () => this.getPayloads()
      );
    } else {
      this.setState(
        {
          payload_config: this.state.defaultpayload_config,
          searchconfig: this.state.defaultsearchconfig,
          search_click: 1,
        },
        () => this.getPayloads()
      );
    }
  };

  editFilter = async () => {
    let dbname, collectionname;
    dbname = "callbacks";
    collectionname = "store_user_view";

    let objectid = this.state.updateObjectid;
    let upd_data = this.state.updated_filterdata;
    if (
      this.state.payload_config.setupdated_at &&
      this.state.payload_config.setupdated_at
    ) {
      upd_data = this.addupdateddata(upd_data);
    }

    const bucketFields = this.state.payload_config.field_mapping.map(
      (item) => item.BucketField
    );
    const addDataBeforeField = this.state.payload_config.field_mapping.filter(
      (item) => item.add_data_before === "1"
    );

    // Initialize the result object
    const result = { add_data_before: {} };

    // Flag to check if any key matched
    let anyKeyMatched = false;

    // Iterate over upd_data keys
    for (const key in upd_data) {
      if (bucketFields.includes(key)) {
        if (addDataBeforeField && addDataBeforeField.find((item)=>item.BucketField === key)) {
          result["add_data_before"][key] = upd_data[key];
          anyKeyMatched = true;
        } else {
          result[key] = upd_data[key];
        }
      } else {
        result[key] = upd_data[key];
      }
    }

    // If no keys matched, ensure add_data_before is an empty object
    if (!anyKeyMatched) {
      result["add_data_before"] = {};
    }
    this.setState({ isspin: true });
    const response = await update_mongopayload(
      this.state.api_token,
      dbname,
      collectionname,
      objectid,
      result
    );

    if (response.status == 1) {
      this.setState({ isspin: false });
      this.getviewcolconfig();
      this.setState({
        updatecheckbox: "",
        updatefiltername: "",
        updatesearchconfig: "",
        updateObjectid: "",
        filtercrtupd: "",
      });
      Modal.success({
        content: <pre id="json">{response.message}</pre>,
        title: "Success",
      });
      return true;
    } else {
      this.setState({ isspin: false });
      Modal.error({
        content: <pre id="json">{response.message}</pre>,
        title: "Error",
      });
      return false;
    }
  };
  getfileDetail = (fileinfo, configdata) => {
    //fileList
    this.setState({
      [configdata.BucketField]: fileinfo.fileList,
    });
  };
  onRemove = (configdata) => {
    this.setState({
      [configdata.BucketField]: "",
      hasFile: 0,
    });
  };
  beforeUpload = (file, configdata) => {
    const fileExtension = file.name.split(".").pop();

    const dataArray = (
      configdata.UploadType || configdata.fileupload[0].UploadType
    )
      .split(",")
      .map((item) => item.toLowerCase());
    const isValidFileType = dataArray.includes(fileExtension.toLowerCase());

    // const isValidFileType =
    //   fileExtension.toLowerCase() == configdata.UploadType.toLowerCase();
    //const isPDF = file.type === 'application/pdf';
    const isValidSize =
      file.size / 1024 / 1024 <
      (configdata.UploadSize || configdata.fileupload[0].UploadSize); // 2MB

    if (!isValidFileType) {
      message.error(
        `${file.name} is not a ${configdata.UploadType ||
          configdata.fileupload[0].UploadType} file`
      );
      return Upload.LIST_IGNORE; // Prevent file from being added
    }

    if (!isValidSize) {
      message.error(`${file.name} must be smaller than 2MB`);
      return Upload.LIST_IGNORE; // Prevent file from being added
    }

    this.setState({
      // [configdata.BucketField]: file,
      hasFile: 1,
    });
    const uploadDataConfig = configdata.fileupload[0] || configdata;
    this.setState((prevState) => ({
      [configdata.BucketField]: [
        {
          ...uploadDataConfig,
          files: [
            {
              docType: configdata.doc_group,
              selectType:
                uploadDataConfig.selectType ||
                configdata.fileupload[0].selectType, // Preserve the selectType property
              size: file.size,
              filelistdata: [
                {
                  name: file.name,
                  originFileObj: file,
                },
              ],
            },
          ], // Update the filelistdata property
        },
      ],
    }));
    return true; // Return true to allow upload if file is valid
  };
  handleOnPartialAmount = async (field) => {
    this.setState({partial_amt: this.state.componentGlobalState[field]});
  };
  checkifalert = (field,payload=[]) => {
    if(field.alert_condition && field.alert_condition!=""){
      for (const condition of field.alert_condition) {
        const { condition_field_1, condition_field_2, conditional_operator, alert_message, message_type } = condition;

        const numericString1 = String(this.state.componentGlobalState[condition_field_1]).replace(/(?!^-)[^0-9]/g, "");
        const cleanedString1 = numericString1.replace(/(?!^-)\D/g, "") || 0;
        const value1 = parseInt(cleanedString1, 10);

        // Extract and clean value2
        const numericString2 = String(this.state.componentGlobalState[condition_field_2]).replace(/(?!^-)[^0-9]/g, "");
        const cleanedString2 = numericString2.replace(/(?!^-)\D/g, "") || 0;
        const value2 = parseInt(cleanedString2, 10);
  
        let isConditionMet = false;
        switch (conditional_operator) {
          case ">":
            isConditionMet = value1 > value2;
            break;
          case "<":
            isConditionMet = value1 < value2;
            break;
          case "==":
            isConditionMet = value1 == value2;
            break;
          case "!=":
            isConditionMet = value1 != value2;
            break;
          case ">=":
            isConditionMet = value1 >= value2;
            break;
          case "<=":
            isConditionMet = value1 <= value2;
            break;
          default:
            break;
        }
  
        if (isConditionMet) {
          //setAlertState(1);
          
          // this.setState({
          //   isErrorMessage: this.getDataBetweenBraces(alert_message),
          //   isError: true
          // });
          if(message_type == "err")
          {
            this.setState({
              process_err: true,
            });
            message.error(
              this.getDataBetweenBraces(alert_message)
            );            
          } else {
            this.setState({
              process_err: false,
            });
            message.warning(
              this.getDataBetweenBraces(alert_message)
            );            
          }
          return;
        } else {
          this.setState({
            process_err: false,
          });
        }
      }
  
    }
  }
  getmassfield = (cell, index) => {
    let dropdown_data = "";
    if (cell.dropDownOptions) {
      dropdown_data = cell.dropDownOptions;
    }

    return !cell["showcondition"] || cell["showcondition"] == 0 ? (
      <div className="form-group col-auto">
        {/* {cell["fieldname"]} */}
        {cell["datatype"] === "group_id" ? (
          <div className="d-flex">
            <input
              className="form-control"
              readOnly
              value={this.state.group_id}
            />
            <button
              className="btn btn-primary ml-1"
              onClick={() => this.adduniqueid(index, cell["BucketField"])}
            >
              +
            </button>
          </div>
        ) : cell["datatype"] === "dropdownlist" ? (
          <>
            {this.state.Reasonlist && (              
              <select
                className="form-control"
                value={this.state.massUpdateInput.length > 0 && this.state.massUpdateInput[index]["my_key"]==cell["BucketField"] ? this.state.massUpdateInput[index]["value"]:""}
                //defaultValue={ReasonData}
                style={{
                  width: 120,
                }}
                onChange={(e) => {
                  const value = e.target.value;
                  const parsedValue = isNaN(value) ? value : parseInt(value, 10);
                  this.handlemassInputChange(index, cell["BucketField"], parsedValue);
                }}
              >
                <option value="">{cell["fieldname"]}</option>
                {this.state.MassReasonlist[cell["BucketField"]] ?
                  this.state.MassReasonlist[cell["BucketField"]].map((list) => (
                    <option value={list.item_value}>
                      {dropdown_data == "V"
                        ? list.item_value
                        : dropdown_data == "L"
                        ? list.item_name
                        : dropdown_data == "B"
                        ? list.item_value + " - " + list.item_name
                        : list.item_name}
                    </option>
                  )) : this.state.payload_data.length > 0 ?<option value="">Preparing Data</option>:""}
              </select>
            )}
          </>
        ) : cell["datatype"] === "textbox" ? (
          <input
            className="form-control"
            placeholder={cell["fieldname"]}
            value={this.state.massUpdateData[index].value}            
            onChange={(e) =>
              this.handlemassInputChange(
                index,
                cell["BucketField"],
                e.target.value
              )
            }
          />
        ) : cell["datatype"] === "Downloadfile" ? (
          cell["isupload"] == "1" && (
            <>
              <Upload
                className="form-control"
                //fileList={this.state.upload_filepath}
                //onChange={(e) => this.getfileDetail(e, cell)}
                beforeUpload={(e) => this.beforeUpload(e, cell)}
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>Upload File</Button>
              </Upload>

              {/* {
                this.state[cell["BucketField"]] && (
                  //this.state[cell["BucketField"]].map((file) => (
                  <div
                    key={this.state[cell["BucketField"]].uid}
                    style={{ marginBottom: "8px" }}
                  >
                    <span>
                      {this.state[cell["BucketField"]].name} -{" "}
                      {(this.state[cell["BucketField"]].size / 1024).toFixed(2)}{" "}
                      KB
                    </span>
                  </div>
                )
                //))
              } */}
              <span>Allow {cell["fileupload"][0]["UploadType"]}</span>
            </>
          )
        ) : cell["datatype"] === "selectoption" ? (
          <select
            className="form-control"                        
            onChange={(e) => {
              const value = e.target.value;
              const parsedValue = isNaN(value) ? value : parseInt(value, 10);
              this.handlemassInputChange(index, cell["BucketField"], parsedValue);
            }}
          >
            <option value="">{cell["fieldname"]}</option>
            {this.state[cell["BucketField"]] &&
              this.state[cell["BucketField"]].map((list) => (
                <option value={list.key}>
                  {dropdown_data == "V"
                    ? list.value
                    : dropdown_data == "L"
                    ? list.value
                    : dropdown_data == "B"
                    ? list.key + " - " + list.value
                    : list.value}
                </option>
              ))}
          </select>
        ) : (
          cell["datatype"] === "date" && (
            <input
              type="date"              
              onChange={(e) =>
                this.handlemassInputChange(
                  index,
                  cell["BucketField"],
                  e.target.value
                )
              }
              placeholder={cell["fieldname"]}
              className="form-control"
            />
          )
        )}
      </div>
    ) : (
      <>
        {/* {console.log(
          "mass_condition_field",
          cell["mass_condition_value"]
            .split(",")
            .includes(
              this.state.massUpdateInput.length > 0 &&
                this.state.massUpdateInput
                  .find((item) => item.my_key == cell["mass_condition_field"])
                  .value.toString()
            )
        )} */}
        {cell["mass_condition_value"]
          .split(",")
          .includes(
            (
              this.state.massUpdateInput.find(
                (item) => item.my_key === cell["mass_condition_field"]
              ) || {}
            ).value
              ? (
                  this.state.massUpdateInput.find(
                    (item) => item.my_key === cell["mass_condition_field"]
                  ) || {}
                ).value.toString()
              : ""
          ) == true && (
          <div className="col-md-3">
            {cell["fieldname"]}
            {cell["datatype"] === "group_id" ? (
              <div className="d-flex">
                <input
                  className="form-control"
                  readOnly
                  value={this.state.group_id}
                />
                <button
                  className="btn btn-primary ml-1"
                  onClick={() => this.adduniqueid(index, cell["BucketField"])}
                >
                  +
                </button>
              </div>
            ) : cell["datatype"] === "dropdownlist" ? (
              <>
                {this.state.Reasonlist && (
                  <select
                    className="form-control"
                    //defaultValue={val.value}
                    //value={ReasonData}                    
                    style={{
                      width: 120,
                    }}                    
                    onChange={(e) => {
                      const value = e.target.value;
                      const parsedValue = isNaN(value) ? value : parseInt(value, 10);
                      this.handlemassInputChange(index, cell["BucketField"], parsedValue);
                    }}
                  >
                    <option value="">{cell["fieldname"]}</option>
                    {this.state.MassReasonlist[cell["BucketField"]] &&
                      this.state.MassReasonlist[
                        cell["BucketField"]
                      ].map((list) => (
                        <option value={list.item_value}>
                          {dropdown_data == "V"
                            ? list.item_value
                            : dropdown_data == "L"
                            ? list.item_name
                            : dropdown_data == "B"
                            ? list.item_value + " - " + list.item_name
                            : list.item_name}
                        </option>
                      ))}
                  </select>
                )}
              </>
            ) : cell["datatype"] === "textbox" ? (
              <input
                className="form-control"
                placeholder={cell["fieldname"]}
                value={
                  this.state.componentGlobalState[cell["BucketField"]] !== null &&
                  this.state.componentGlobalState[cell["BucketField"]] !== undefined
                    ? this.state.componentGlobalState[cell["BucketField"]]
                    : this.state.massUpdateData[index].value !== null &&
                      this.state.massUpdateData[index].value !== undefined
                    ? this.state.massUpdateData[index].value
                    : ""
                }
                onChange={(e) => {
                  const value = e.target.value;
                  const parsedValue = isNaN(value) ? value : parseInt(value, 10);
                  this.handlemassInputChange(index, cell["BucketField"], parsedValue);
                }}
                onBlur={()=> this.checkifalert(cell)}
              />
            ) : cell["datatype"] === "Uploadfile" ? (
              <>
                <Upload
                  className="form-control"
                  //fileList={this.state.upload_filepath}
                  //onChange={(e) => this.getfileDetail(e, cell)}
                  beforeUpload={(e) => this.beforeUpload(e, cell)}
                  maxCount={1}
                  onRemove={() => this.onRemove(cell)}
                >
                  <Button icon={<UploadOutlined />}>Upload File</Button>
                  {/* {
                    this.state[cell["BucketField"]] && (
                      <div style={{ marginBottom: "8px" }}>
                        <span>
                          {(
                            this.state[cell["BucketField"]].size / 1024
                          ).toFixed(2)}{" "}
                          KB
                        </span>
                      </div>
                    )

                    //console.log("filefile",this.state[cell["BucketField"]].size)
                  } */}
                </Upload>
                <span>Allow {cell["UploadType"]}</span>
              </>
            ) : cell["datatype"] === "Downloadfile" ? (
              cell["isupload"] == "1" && (
                <>
                  <Upload
                    className="form-control"
                    //fileList={this.state.upload_filepath}
                    //onChange={(e) => this.getfileDetail(e, cell)}
                    beforeUpload={(e) => this.beforeUpload(e, cell)}
                    maxCount={1}
                  >
                    <Button icon={<UploadOutlined />}>Upload File</Button>
                  </Upload>

                  {/* {
                    this.state[cell["BucketField"]] && (
                      //this.state[cell["BucketField"]].map((file) => (
                      <div
                        key={this.state[cell["BucketField"]].uid}
                        style={{ marginBottom: "8px" }}
                      >
                        <span>
                          {this.state[cell["BucketField"]].name} -{" "}
                          {(
                            this.state[cell["BucketField"]].size / 1024
                          ).toFixed(2)}{" "}
                          KB
                        </span>
                      </div>
                    )
                    //))
                  } */}
                  <span>Allow {cell["fileupload"][0]["UploadType"]}</span>
                </>
              )
            ) : cell["datatype"] === "selectoption" ? (
              <select
                className="form-control"                
                onChange={(e) => {
                  const value = e.target.value;
                  const parsedValue = isNaN(value) ? value : parseInt(value, 10);
                  this.handlemassInputChange(index, cell["BucketField"], parsedValue);
                }}
              >
                <option value="">{cell["fieldname"]}</option>
                {this.state[cell["BucketField"]] &&
                  this.state[cell["BucketField"]].map((list) => (
                    <option value={list.key}>
                      {dropdown_data == "V"
                        ? list.value
                        : dropdown_data == "L"
                        ? list.value
                        : dropdown_data == "B"
                        ? list.ky + " - " + list.value
                        : list.value}
                    </option>
                  ))}
              </select>
            ) : (
              cell["datatype"] === "date" && (
                <input
                  type="date"                  
                  onChange={(e) =>
                    this.handlemassInputChange(
                      index,
                      cell["BucketField"],
                      e.target.value
                    )
                  }
                  placeholder={cell["fieldname"]}
                  className="form-control"
                />
              )
            )}
          </div>
        )}
      </>
    );
  };
  updateglobalvaronCheckbox = () => {
    const updfilterdata = this.filterData() || [];
    const filtercustom_field =
      this.props.location.state.payload_config.field_mapping.filter(
        (item) => item.custom_field === "1"
      );
    const filteredData = updfilterdata.filter((item) => {
      return Object.values(item).some((value) => {          
        if (typeof value === "object" && value !== null && value.$oid) {
          return this.state.selectedMassUpdateData.includes(value.$oid);
        }          
      });
    });
    console.log("filteredData",filteredData);
    filtercustom_field.forEach((item) => {          
      if(item.manuall_operator == "sum"){
        const sumresult = this.getfieldsum(filteredData, item.manuall_condition_field);            
        this.updateComponentGlobalState({ [item.BucketField]: sumresult });
      }
    
      setTimeout(() => {if(item.manuall_condition_field && item.manuall_condition_field_with){
        const result = this.performOperation(item);
        this.updateComponentGlobalState({ [item.BucketField]: result });
      }},1000);
    });
  }
  handlemassCheckboxChange = (fieldName) => {
    const updatedCheckboxes = this.state.selectedMassUpdateData;
    if (updatedCheckboxes.includes(fieldName)) {
      // If the checkbox is already checked, uncheck it
      const index = updatedCheckboxes.indexOf(fieldName);
      updatedCheckboxes.splice(index, 1);
    } else {
      // If the checkbox is unchecked, check it
      updatedCheckboxes.push(fieldName);
    }
    //setCheckedItems(updatedCheckboxes);
    if (this.state.addtosum) {
      this.getsumdata(updatedCheckboxes);
    }
    this.setState({
      selectedMassUpdateData: updatedCheckboxes,
    },()=>this.updateglobalvaronCheckbox());
    const isCheckAll =
      updatedCheckboxes.length === this.state.payload_data.length;
    this.setState({ CheckAll: isCheckAll });
  };
  handleMasterCheckboxChange = (e) => {
    const isChecked = e.target.checked; // Check if the master checkbox is checked
    const { data } = this.props; // Assuming 'data' is passed as a prop to YourTableComponent

    // Update CheckAll state based on master checkbox state
    this.setState({ CheckAll: isChecked });

    // If master checkbox is checked, update checkedValues state with _id.$oid values
    if (isChecked) {
      const data = this.filterData();
      const allValues = data.map((payload) => this.getPayloadId(payload));
      this.setState({ selectedMassUpdateData: allValues },()=>this.updateglobalvaronCheckbox());
      if (this.state.addtosum) {
        this.getsumdata(allValues);
      }
    } else {
      // If master checkbox is unchecked, reset checkedValues state to empty array
      this.setState({ selectedMassUpdateData: [] },()=>this.updateglobalvaronCheckbox());
      if (this.state.addtosum) {
        const allValues = [];
        this.getsumdata(allValues);
      }
    }
  };

  getPayloadId = (payload) => {
    const usethisfield = this.state.payload_config.field_mapping
      .filter((field) => field.useasinput === "1")
      .map((field) => field.BucketField);
    return usethisfield.length > 0
      ? payload[usethisfield]
      : this.state.payload_config.payload_type === "M"
      ? payload["_id.$oid"] || payload._id.$oid
      : payload["key"];
  };
  getsumdata = (updatedCheckboxes) => {
    const { payload_data, addtosum } = this.state;
    const bucketField = addtosum.BucketField;
    const filteredData3 = payload_data.filter((item) =>
      updatedCheckboxes.includes(item._id.$oid)
    );
    const sum = filteredData3.reduce((acc, item) => acc + item[bucketField], 0);
    this.setState({
      [addtosum.BucketField]: sum,
    });
  };
  updateAfterRemove = () => {
    let filteredData = [];
    // if(!this.state.defaultCheckbox){
    //   const filtered_Data = this.filterData();
    //   filteredData = this.state.payload_data.filter(
    //     (item) => !this.state.removedCheckboxesid.includes(item._id.$oid)
    //   );
    // } else {
    //   filteredData = this.state.init_outputdata.filter(
    //     (item) => !this.state.removedCheckboxesid.includes(item._id.$oid)
    //   );
    // }
    filteredData = this.state.init_outputdata.filter(
      (item) => !this.state.removedCheckboxesid.includes(item._id.$oid)
    );
    const lastValidData = [...this.state.init_outputdata];
    
    const updatedData = this.processAggData(
      filteredData,
      this.state.payload_config.field_mapping,
      lastValidData
    );
  
    this.setState({
      payload_data: updatedData,
      payloadglobal_data: filteredData,
    });
  };  
  
  removedCheckboxes = (inputcheckbox) => {
    this.setState(
      (prevState) => {
        const currentIds = prevState.removedCheckboxesid || [];
        const updatedIds = currentIds.slice(); // Create a copy of currentIds
    
        inputcheckbox.forEach((id) => {
          const index = updatedIds.indexOf(id);
          if (index > -1) {
            // If ID exists, remove it
            updatedIds.splice(index, 1);
          } else {
            // If ID doesn't exist, add it
            updatedIds.push(id);
          }
        });
    
        return { removedCheckboxesid: updatedIds };
      },
      () => this.updateAfterRemove()
    );
    
  };
  storesubdata = (inputData) => {
    const key = Object.keys(inputData)[0];
    const newValue = inputData[key];

    this.updateItem(key, newValue);
  };

  updateItem = (key, newValue) => {
    let updatedData = {};
    if (this.state.sub_data.length > 0) {
      updatedData = this.state.sub_data.map((item) => {
        if (item.key === key) {
          return { ...item, value: newValue };
        } else {
          return item;
        }
      });
    } else {
      updatedData = [
        {
          ...this.state.sub_data,
          [key]: newValue,
        },
      ];
    }
    // Update the state or data structure accordingly
    // For example, if using React:
    this.setState({ sub_data: updatedData });
    // Or for a plain JavaScript array:
    //data = updatedData;
  };
  getfieldsum = (filteredData, BucketField) => {
    const fieldsum = filteredData.reduce(
      (acc, item) => acc + item[BucketField],
      0
    );
    return "Rs. " + fieldsum.toLocaleString("en-IN");
  };
  getTotalValue = (filteredData) => {
    const val = this.state.payload_config.field_mapping.filter((item) => {
      if (item.partial_amount && item.partial_amount == "1") {
        this.setState({
          partial_amount: item.partial_amount,
        });
      }
      if (item.morethan_collection && item.morethan_collection == "1") {
        this.setState({
          morethan_collection: item.morethan_collection,
        });
      }
      if (item.addtosum === "1") {
        return item;
      }
    });
    const finalSum = this.state.payload_data.reduce((acc, item, index) => {
      const id = this.state.selectedMassUpdateData.includes(item._id.$oid);
      if (id) {
        return acc + item.amount_collected;
      } else {
        return acc + 0;
      }
    }, 0);
    this.setState({
      fieldsumm: finalSum,
      totalSum: finalSum,
    });
  };
  freeSearch() {
    const results = [];
    const data = this.state.payload_data;
    if (this.state.mobilequery) {
      data.forEach((item) => {
        const keys = Object.keys(item);

        for (let i = 0; i < keys.length; i++) {
          const value = item[keys[i]];

          if (
            typeof value === "string" &&
            value.toLowerCase().includes(this.state.mobilequery.toLowerCase())
          ) {
            results.push(item);
            break;
          } else if (typeof value === "number" || typeof value === "boolean") {
            if (value.toString().includes(this.state.mobilequery)) {
              results.push(item);
              break;
            }
          } else if (Array.isArray(value)) {
            for (let j = 0; j < value.length; j++) {
              const arrayValue = value[j];
              if (
                typeof arrayValue === "string" &&
                arrayValue
                  .toLowerCase()
                  .includes(this.state.mobilequery.toLowerCase())
              ) {
                results.push(item);
                break;
              } else if (
                typeof arrayValue === "number" ||
                typeof arrayValue === "boolean"
              ) {
                if (arrayValue.toString().includes(this.state.mobilequery)) {
                  results.push(item);
                  break;
                }
              }
            }
          }
        }
      });

      return results;
    } else {
      return data;
    }
  }
  onDrag = (e, data) => {
    this.setState({
      currentPosition: { xRate: data.lastX, yRate: data.lastY },
    });
  };
  transformDataStructure = async (processedData) => {
    const result = [];

    // Wrap single objects into an array for consistent processing
    const dataArray = Array.isArray(processedData) ? processedData : [processedData];

    dataArray.forEach((item) => {
        if (item.my_key && item.value) {
            // Split `my_key` dynamically into parts using both dot and colon as separators
            const pathParts = item.my_key.split(/[.:]/);

            // Start with the value as the base
            let currentValue = item.value;

            // Traverse the path parts in reverse to create nested structure
            for (let i = pathParts.length - 1; i >= 0; i--) {
                const part = pathParts[i];
                currentValue = { [part]: [currentValue] };
            }

            // Push the constructed value into the result array
            result.push(currentValue[pathParts[0]]);
        }
    });

    return result;
  };
  ismobileSearch = () => {
    this.setState({
      showSearch: !this.state.showSearch,
    })
  }

  getColor = (row) => {
    let findColor;
    const matchingColor = this.state.payload_config.field_mapping.find((item) => {
      if (item.datecondition && item.datecondition.length > 0) {
        const getMatchingColor = (dateConditions) => {
          const datedata = this.converttoarray(item.BucketField, row);
          let today;
          if (typeof datedata === "number") {
            if (datedata.toString().length === 10) {
              today = moment.unix(datedata).startOf('day').valueOf();
            } else if (datedata.toString().length === 13) {
              today = moment(datedata).startOf('day').valueOf();
            } else {
              today = moment(datedata).startOf('day').valueOf();
            }
          } else {
            today = moment(datedata).startOf('day').valueOf();
          }
          for (const condition of dateConditions) {
            let findtargetDate;
            if (condition.day >= 0) {
              findtargetDate = moment().add(condition.day, 'days').startOf('day').valueOf();
            } else {
              findtargetDate = moment().subtract(Math.abs(condition.day), 'days').startOf('day').valueOf();
            }
            let isMatch = false;
            switch (condition.operator) {
              case "==":
                isMatch = today === findtargetDate;
                break;
              case ">":
                isMatch = today > findtargetDate;
                break;
              case "<":
                isMatch = today < findtargetDate;
                break;
              case "!=":
                isMatch = today !== findtargetDate;
                break;
              default:
                console.warn(`Unsupported operator: ${condition.operator}`);
            }
            if (isMatch) {
              return condition.color;
            }
          }
          return null;
        };
        findColor = getMatchingColor(item.datecondition);
      }
      return false;
    });
    // If a matching color was found, return it, else return null
    if (findColor) {
      return findColor;
    }
    return null;
  }

  handleDateRangeChange = (dates, dateStrings, bucketField) => {
    this.setState((prevState) => {
      const updatedDateFilters = { ...prevState.dateRangeFilters };
  
      if (!dateStrings[0] || !dateStrings[1]) {
        delete updatedDateFilters[bucketField];
      } else {
        updatedDateFilters[bucketField] = {
          startDate: dateStrings[0],
          endDate: dateStrings[1],
        };
      }
  
      return { dateRangeFilters: updatedDateFilters };
    }, this.filterData);
  };  

  performSearchOrFilter = () => {
    const { dateRangeFilters } = this.state;
  
    Object.keys(dateRangeFilters).forEach((bucketField) => {
      const { startDate, endDate } = dateRangeFilters[bucketField];
      console.log(`Filtering ${bucketField} from ${startDate} to ${endDate}`);
    });
  };

  CustomRule = (props) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>        
        <div style={{ display: props.rule.hidden ? "none" : "block" }}>
          <Rule {...props} disabled={!props.rule.editable} />
        </div>        
      </div>
    );
  };

  // Function to receive state from Child
  updatePayloadDataFromChild = (payloadId, newPayload) => {
    const _newpayload = this.state.payload_data.map((item) => {
      if (item._id.$oid === payloadId) {
        const item1 = { ...item, ...newPayload };
        return item1;
      } else {
        return;
      }
    }).filter((item) => item !== undefined);
    
    let flag = 0;
    this.state.updatedPayloadData.map((item) => {
      if(item._id.$oid === payloadId) {
        flag = 1;
      }
    })
    if(flag == 0) {
      this.setState((prevState) => ({
        updatedPayloadData: [...prevState.updatedPayloadData, _newpayload[0]],
      }));
    }
  };

  render() {
    const { expandedSections } = this.state;
    const updateUniqueData = this.state.updateUniqueData || [];
    const filteredData = this.filterData();
    const { payload_config, isExporting } = this.state;
    let mobilefilterdata = [];
    if (this.state.isMobile) {
      mobilefilterdata = this.freeSearch();
    }
    return (
      // <div className="container">
      <div className="mr-2 ml-2">
        <Modal
          open={this.state.isExprow}
          onCancel={() =>
            this.setState({
              isExprow: false,
            })
          }
          footer={[]}
          width="auto"
          id={this.state.selectedrowindex}
        >
          <div
            id={this.state.selectedrowindex}
            class="cell-1 row-child"
            style={{ display: "block" }}
          >
            <DetailComponent
              detailData={this.state.ExpandedRow}
              payload_config={this.state.subpayload_config}
              api_token={this.state.api_token}
              effective_designation_id={
                this.props.location.state.effective_designation_id
              }
              fetch_onload={true}
              storesubdata={this.storesubdata}
              selectedrowindex={this.state.selectedrowindex}
              filtered_condition={this.state.filtered_condition}
              sub_data={this.state.sub_data || []}
              removedCheckboxes={this.removedCheckboxes}
              removedCheckboxesid={this.state.removedCheckboxesid}
            />
          </div>
        </Modal>
        <div
          className={
            this.state.sumDetail
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          inert
          id="confirm-boxsum"
          style={{ position: "absolute" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({
                    sumDetail: false,
                  });
                }}
              >
                <span inert>&times;</span>
              </button>
              <div className="row">
                {this.state.config_sum &&
                  this.state.config_sum.map((totdata, index) => {
                    return (
                      <>
                        <div
                          className="col-md-12 detail-amt-border p-1"
                          style={{ color: totdata.color || "#000000" }}
                        >
                          <div className="w-50 float-left">
                            <span
                              className="mr-3"
                              style={{ color: totdata.color || "#000000" }}
                            >
                              <span>{totdata.name}</span>
                            </span>
                          </div>
                          <div className="w-50 text-right float-left">
                            <span
                              className="mr-3 onclick-menu"
                              style={{ color: totdata.color || "#000000" }}
                            >
                              <strong>
                                {totdata.sum.toLocaleString("en-IN")}
                              </strong>
                            </span>
                          </div>
                        </div>
                        <div className="row category-data w-100">
                          {totdata.breakdown &&
                            totdata.breakdown.map((breakdata) => (
                              <>
                                <div className="col-md-12 categorysub-data">
                                  <div className="w-75 float-left">
                                    <span>{breakdata.name}</span>
                                    {!this.state.partialAmt && (
                                      <span className="ml-2">
                                        {this.state.partial_amount &&
                                          breakdata.isEditable == 1 && (
                                            <Button
                                              onClick={() => {
                                                this.setState({
                                                  partialAmt: true,
                                                });
                                              }}
                                            >
                                              <EditOutlined />
                                            </Button>
                                          )}
                                      </span>
                                    )}
                                  </div>
                                  <div className="w-25 text-right float-left">
                                    {!this.state.partialAmt && (
                                      <strong
                                        style={{
                                          color:
                                            breakdata.operator &&
                                            breakdata.operator == "+"
                                              ? "#008000"
                                              : breakdata.operator == "-"
                                              ? "#FF0000"
                                              : "#000000",
                                        }}
                                      >
                                        {breakdata.operator &&
                                          "(" + breakdata.operator + ")"}

                                        {breakdata.isEditable ? (
                                          !this.state.partialAmt ? (
                                            `₹ ${
                                              this.state.editfieldsumm > 0
                                                ? this.state.editfieldsumm.toLocaleString(
                                                    "en-IN"
                                                  )
                                                : this.state.fieldsumm.toLocaleString(
                                                    "en-IN"
                                                  )
                                            }`
                                          ) : (
                                            <></>
                                          )
                                        ) : this.state.editfieldsumm > 0 ? (
                                          `${(
                                            parseInt(this.state.editfieldsumm) -
                                            parseInt(this.state.totalSum)
                                          ).toLocaleString("en-IN")}`
                                        ) : (
                                          breakdata.sum.toLocaleString("en-IN")
                                        )}
                                      </strong>
                                    )}

                                    {this.state.partialAmt && breakdata.isEditable == 1 && (
                                      <span>
                                        <Input
                                          type="number"
                                          value={this.state.componentGlobalState && this.state.componentGlobalState.pettycash}
                                          onChange={(e) => {                                           
                                            const value = e.target.value;
                                            this.updateComponentGlobalState({ [breakdata.popupfield]: value });
                                          }}
                                        />
                                        
                                        <Button
                                          onClick={() => {
                                            this.setState({
                                              partialAmt: false,
                                            });
                                            this.handleOnPartialAmount(breakdata.popupfield);
                                          }}
                                          // disabled={
                                          //   this.state.morethan_collection ==
                                          //   "1"
                                          //     ? false
                                          //     : true
                                          // }
                                        >
                                          Save
                                        </Button>
                                        <Button onClick={() => {
                                            this.setState({
                                              partialAmt: false,
                                            });
                                          }}><CloseOutlined/></Button>
                                        
                                        {/*<span className="red">
                                          {this.state.morethan_collection == "1"
                                            ? ""
                                            : "Deposit Amount can't be more"}
                                        </span>*/}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </>
                            ))}
                        </div>
                      </>
                    );
                  })}
              </div>
              {this.state.partial_amount && (
                <div className="d-flex justify-content-end">
                  {this.state.totalSum != NaN ? (
                    <div
                      className={
                        `${parseInt(this.state.componentGlobalState && this.state.componentGlobalState.pettycash) -
                          parseInt(this.state.totalSum)}` > 0
                          ? "font-weight-bold green"
                          : `${parseInt(this.state.componentGlobalState && this.state.componentGlobalState.pettycash) -
                              parseInt(this.state.totalSum)}` < 0
                          ? "font-weight-bold red"
                          : ""
                      }
                    >
                      {this.state.editfieldsumm > 0
                        ? `${(
                            parseInt(this.state.componentGlobalState && this.state.componentGlobalState.pettycash) -
                            parseInt(this.state.totalSum)
                          ).toLocaleString("en-IN")}`
                        : this.state.componentGlobalState && this.state.componentGlobalState.pettycash}
                    </div>
                  ) : (
                    <span></span>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={
            this.state.isError
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          inert
          id="confirm-box"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({
                    isError: false,
                  });
                  //window.location.reload();
                }}
              >
                <span inert>&times;</span>
              </button>
              <div>{this.state.isErrorMessage}</div>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.isMassUpdatePopup
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          inert
          id="confirm-box"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({
                    isMassUpdatePopup: false,
                  });
                }}
              >
                <span inert>&times;</span>
              </button>
              <div className="form-row">
                {this.state.updateUniqueData.length > 0 &&
                  this.state.updateUniqueData.map((cell, index) => {
                    // let massF = this.getmassfield(cell, index);
                    return (
                      <>
                        {cell["showon"] &&
                          cell["showon"] == "0" &&
                          this.getmassfield(cell, index)}
                      </>
                    );
                  })}
              </div>
              <div>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.setState({
                      isMassupdate: true,
                      isMassupdclick: true,
                      isMassUpdatePopup: false,
                    });
                    this.updatemassdata();
                  }}
                  disabled={this.state.process_err}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.isSettings
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          inert
          id="confirm-box"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() =>
                  this.setState({
                    isSettings: false,
                    searchconfig: this.state.defaultsearchconfig,
                    filtercrtupd: "",
                  })
                }
              >
                <span inert>&times;</span>
              </button>
              {/* <p>Do you want to register the household for NACH?</p> */}
              <div className="row">
                {!this.state.filtercrtupd && (
                  <>
                    <div className="col-md-2">
                      <button
                        className="btn btn-primary form-control"
                        onClick={(e) =>
                          this.setState({
                            filtercrtupd: "C",
                          })
                        }
                      >
                        Create
                      </button>
                    </div>
                    <div className="col-md-2">
                      <button
                        className="btn btn-primary form-control"
                        onClick={(e) =>
                          this.setState({
                            filtercrtupd: "U",
                          })
                        }
                      >
                        Update
                      </button>
                    </div>
                  </>
                )}
              </div>
              {this.state.filtercrtupd == "C" && (
                <div className="row">
                  <div className="col-md-12 mb-2">
                    <input
                      className="form-control"
                      placeholder="Enter Name"
                      onChange={(e) =>
                        this.setState({ filtername: e.target.value })
                      }
                    />
                  </div>
                  {this.state.search_fields.length > 0 && (<div className="col-md-12 mb-2">
                    <QueryBuilder
                      fields={
                        this.state.search_fields.length > 0 &&
                        this.state.search_fields
                      }
                      operators={this.state.Operators}
                      addRuleTonewgroupid
                      controlClassnames={{
                        queryBuilder: "queryBuilder-branches",
                      }}
                      query={this.state.searchconfig}
                      onQueryChange={(q) =>
                        this.setState({
                          searchconfig: q,
                        })
                      }
                      controlElements={{
                        valueEditor: this.CustomValueEditor,
                        addRuleAction: () => null, // Hides "Add Rule" button
                        addGroupAction: () => null, // Hides "Add Group" button
                        removeRuleAction: () => null, // Hides "Remove Rule" button
                        removeGroupAction: () => null, // Hides "Remove Group" button
                        combinatorSelector: () => null, // Hide "Combinator" (AND/OR) dropdown
                        //rule: this.CustomRule,
                      }}
                    />
                  </div>)}
                  {this.state.HeaderData.map((field, index) => (
                    <div key={index} className="col-md-4">
                      <div>
                        <input
                          type="checkbox"
                          className="mr-1 mobilecheckbox"
                          checked={this.state.checkedCheckboxes.includes(
                            field.BucketField
                          )}
                          onChange={() =>
                            this.handleCheckboxChange(field.BucketField)
                          }
                        />
                        <label>{field.fieldname}</label>
                      </div>
                    </div>
                  ))}
                  <div className="col-md-12 row">
                    <div className="col-md-2">
                      <button
                        type="button"
                        className="btn btn-primary form-control"
                        disabled={this.state.checkedCheckboxes.length == 0}
                        onClick={() => {
                          this.savepreferences();
                          this.setState({
                            isSettings: false,
                            filtercrtupd: "",
                          });
                        }}
                      >
                        Create
                      </button>
                    </div>
                    <div className="col-md-2">
                      <button
                        type="button"
                        className="btn btn-secondary form-control"
                        onClick={() =>
                          this.setState({
                            isSettings: false,
                            searchconfig: this.state.defaultsearchconfig,
                            filtercrtupd: "",
                          })
                        }
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {this.state.filtercrtupd == "U" && (
                <div className="row">
                  <div className="col-md-12 mb-2">
                    <select
                      className="form-control"
                      onChange={(e) => this.updateFilterval(e.target.value)}
                    >
                      <option value="">Select</option>
                      {this.state.savedFilter &&
                        this.state.savedFilter.map((data) => (
                          <option value={data._id.$oid}>
                            {data.filtername}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-md-12 mb-2">
                    <input
                      className="form-control"
                      placeholder="Enter Name"
                      value={this.state.updatefiltername}
                      onChange={(e) =>
                        this.setState({ updatefiltername: e.target.value })
                      }
                    />
                  </div>
                  {this.state.search_fields.length > 0 && (<div className="col-md-12 mb-2">
                    <QueryBuilder
                      fields={
                        this.state.search_fields.length > 0 &&
                        this.state.search_fields
                      }
                      operators={this.state.Operators}
                      addRuleTonewgroupid
                      controlClassnames={{
                        queryBuilder: "queryBuilder-branches",
                      }}
                      query={this.state.updatesearchconfig}
                      onQueryChange={(q) =>
                        this.setState({
                          updatesearchconfig: q,
                        })
                      }
                      controlElements={{
                        valueEditor: this.CustomValueEditor,
                        addRuleAction: () => null, // Hides "Add Rule" button
                        addGroupAction: () => null, // Hides "Add Group" button
                        removeRuleAction: () => null, // Hides "Remove Rule" button
                        removeGroupAction: () => null, // Hides "Remove Group" button
                        combinatorSelector: () => null, // Hide "Combinator" (AND/OR) dropdown
                        //rule: this.CustomRule,
                      }}
                    />
                  </div>)}
                  {this.state.HeaderData.map((field, index) => (
                    <div key={index} className="col-md-4">
                      <div>
                        <input
                          type="checkbox"
                          className="mr-1 mobilecheckbox"
                          checked={
                            this.state.updatecheckbox &&
                            this.state.updatecheckbox.includes(
                              field.BucketField
                            )
                          }
                          onChange={() =>
                            this.handleCheckboxChangeupdate(field.BucketField)
                          }
                        />
                        <label>{field.fieldname}</label>
                      </div>
                    </div>
                  ))}
                  <div className="col-md-12 row">
                    <div className="col-md-2">
                      <button
                        type="button"
                        className="btn btn-primary form-control"
                        disabled={this.state.checkedCheckboxes.length == 0}
                        onClick={() => {
                          this.updateFilter();
                          this.setState({ isSettings: false });
                        }}
                      >
                        Update
                      </button>
                    </div>
                    <div className="col-md-2">
                      <button
                        type="button"
                        className="btn btn-secondary form-control"
                        onClick={() =>
                          this.setState({
                            isSettings: false,
                            searchconfig: this.state.defaultsearchconfig,
                            filtercrtupd: "",
                          })
                        }
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {this.state.isspin && (
          <div className="preloader">
            <div className="loader" ng-hide="data.length > 0"></div>
          </div>
        )}
        <div className="payload_view_navbar text-center">
          <button
            className="button back_button"
            onClick={() => this.props.history.goBack()}
          >
            <img src={require("../assets/images/back.png")} alt="" />
          </button>
          <h4>
            {this.state.view_name ? this.state.view_name : "View"} (
            {this.state.payload_data && this.state.payload_data.length})
          </h4>
          <button
            className="button"
            onClick={() =>
              logout(this.state.otherScreen, this.state.no_of_page)
            }
          >
            <img src={require("../assets/images/power-off.png")} alt="" />
          </button>
        </div>

        <div className="payload_view_main_block">
          <div className="payload_view_table_block">
            {this.state.isMobile &&
            (this.state.payload_config.hide_filter == "2" ||
              this.state.payload_config.hide_filter == "3") ? (
              ""
            ) : (<>
              <div id="accordion">
                <div class="card">
                  <div class="card-header p-0" id="headingOne">
                    <h5 class="mb-0">
                      <button
                        class="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Filters
                      </button>
                    </h5>
                  </div>

                <div
                  id="collapseOne"
                  class="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <div className="row">
                      {this.state.payload_config.payload_type == "B" ? (
                        <>
                          <div className="col-md-3">
                            <DatePicker
                              //defaultValue={moment().subtract(6, "months")}
                              format={dateFormatList}
                              onChange={this.changedate}
                              placeholder="Start Date"
                            />
                            <button
                              className="btn btn-primary ml-2"
                              disabled={!this.state.filterDate}
                              onClick={() => {
                                this.setState({ search_click: 1 }, () =>
                                  this.getPayloads()
                                );
                              }}
                            >
                              Search
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-md-12">
                            <div className="d-flex flex-row-reverse col-md-4 float-right">
                              <div>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => this.showpopup()}
                                >
                                  <SettingOutlined />
                                </button>
                              </div>
                              <div className="mr-2">
                                <select
                                  className="form-control"
                                  onChange={(e) =>
                                    this.filterchange(e.target.value)
                                  }
                                >
                                  <option value="">Select</option>
                                  {this.state.savedFilter &&
                                    this.state.savedFilter.map((data) => (
                                      <option value={data._id.$oid}>
                                        {data.filtername}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                            </div>
                            <div className="col-md-12">
                            {this.state.payload_config.hide_filter == "1" ? (
                              ""
                            ) : this.state.payload_config.payload_type == "A"? (
                              <div className="col-md-8 row">
                                {
                                  this.state.payload_config.datasource[0].formdata.map((frmdata)=>{
                                    if(frmdata.manual_input=="1"){
                                      if(frmdata.getdata_from !=""){
                                        return (<div className="col-md-3">
                                          <label>{frmdata.formdatakey}</label>
                                          <select
                                            className="form-control"
                                            onChange={(e) =>
                                              this.setState({
                                                [`select_${frmdata.formdatakey}`]: e.target.value, // Correct usage of computed property names
                                              })
                                            }
                                          >
                                          <option value="">Select</option>
                                          {this.state[frmdata.formdatakey] && this.state[frmdata.formdatakey].map((itemdata, index) => (
                                            <option key={index} value={itemdata.key}>
                                              {itemdata.value}
                                            </option>
                                          ))}
                                        </select></div>)
                                      } else {
                                        return (<div className="col-md-3"><label>{frmdata.formdatakey}</label><input className="form-control" onChange={(e) =>
                                          this.setState({
                                            [`select_${frmdata.formdatakey}`]: e.target.value, // Correct usage of computed property names
                                          })
                                        } /></div>)
                                      }
                                    }
                                  })                                  
                                }
                                <div className="col-md-3">
                                    <button
                                      className="btn btn-primary mt-2"
                                      disabled={!this.state.filterDate}
                                      onClick={() => {
                                        this.setState({ search_click: 1 }, () =>
                                          this.getPayloads()
                                        );
                                      }}
                                    >
                                      Search
                                    </button>
                                  </div>
                              </div>
                            ) : (
                              <>                                
                                {this.state.search_fields.length > 0 && 
                                  <><div className="col-md-12">
                                   <QueryBuilder
                                          fields={
                                            this.state.search_fields.length >
                                              0 && this.state.search_fields
                                          }
                                          operators={this.state.Operators}
                                          addRuleTonewgroupid
                                          controlClassnames={{
                                            queryBuilder:
                                              "queryBuilder-branches",
                                          }}
                                          query={
                                            this.state.isSettings
                                              ? this.state.defaultsearchconfig
                                              : this.state.searchconfig
                                          }
                                          onQueryChange={(q) =>
                                            this.setState({
                                              searchconfig: q,
                                            })
                                          }
                                          controlElements={{
                                            valueEditor: this.CustomValueEditor,
                                            addRuleAction: () => null, // Hides "Add Rule" button
                                            addGroupAction: () => null, // Hides "Add Group" button
                                            removeRuleAction: () => null, // Hides "Remove Rule" button
                                            removeGroupAction: () => null, // Hides "Remove Group" button
                                            combinatorSelector: () => null, // Hide "Combinator" (AND/OR) dropdown
                                            //rule: this.CustomRule,
                                          }}
                                        />

                                </div>
                                <div className="col-md-3">
                                  <button
                                    className="btn btn-primary mt-2"
                                    disabled={!this.state.filterDate}
                                    onClick={() => {
                                      this.setState({ search_click: 1 }, () =>
                                        this.getPayloads()
                                      );
                                    }}
                                  >
                                    Search
                                  </button>
                                </div></> }
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row-reverse mt-1">
              <button
                className="download-table-xls-button mb-1 btn btn-primary col-md-1"
                onClick={() => this.handleClick()}
              >
                <FileExcelOutlined />
              </button>
              <div className="col-md-2">
                <strong>
                  Total Record :{" "}
                  {this.state.payload_data && this.state.payload_data.length}
                </strong>
              </div>
                <div style={{ display: "none" }}>
                  <ReactHTMLTableToExcel
                    id="table-xls-button"
                    className="download-table-xls-button mb-2 btn btn-primary"
                    table="my-table"
                    filename={
                      this.state.view_name +
                      "_" +
                      moment().format("YYYYMMDD_HH_mm_ss")
                    }
                    sheet="sheet1"
                    buttonText="Export to Excel"
                    isExporting={this.state.isExporting}
                  />
                </div>
              </div>
            </>)}
            {/* {this.state.effective_designation_id == "1" && (
              <div className="text-center m-2">
                <h3>Waiting For FCM</h3>
              </div>
            )} */}
            <div className="form-row">
              <div className="col-md-6 row">
                {this.state.updateUniqueData.length > 0 &&
                  this.state.updateUniqueData.map((cell, index) => (
                    <>
                      {cell["showon"] &&
                        cell["showon"] == "1" &&
                        this.getmassfield(cell, index)}
                    </>
                  ))}
                {this.state.updateUniqueData.length > 0 && (
                  <div className="form-group col-auto">
                    <button
                      className="btn btn-primary"
                      disabled={
                        this.state.isMassupdclick ||
                        this.state.selectedMassUpdateData.length == 0 ||
                        this.state.massUpdateInput.length == 0
                      }
                      onClick={() => {
                        this.setState({
                          isMassUpdatePopup: true,
                        });
                      }}
                    >
                      Update
                    </button>
                  </div>
                )}
                {this.state.isMobile && <><Button type="primary" onClick={()=>this.ismobileSearch()} shape="circle" icon={<SearchOutlined />} /></>}
              </div>
              <div className="col-md-6 row">
                {                  
                  this.props.location.state.payload_config.field_mapping.filter(
                    (item) => item.custom_field === "1"
                  ).map((item)=> {
                    if(item.show_on_screen == "1"){
                      return (
                        <div className="col">
                        {item.fieldname}:{
                          this.state.componentGlobalState && this.state.componentGlobalState[item.BucketField]
                        }
                        </div>
                      )
                    }
                })
                }
              </div>
            </div>
            <div className="col-md-6">
              <strong>
                {this.state.filter_name &&
                  `Entries eligible for ${this.state.filter_name} (${this.state.filtered_cnt} Records)`}
              </strong>
            </div>
            { this.state.payload_config.marquee_flag && this.state.payload_config.marquee_flag==1 &&
              (<div>
               <marquee style= {{color: this.state.payload_config.marquee_message_color || "#000000"}}>{this.state.payload_config.marquee_message}</marquee> 
              </div>)
            }
            {this.state.config_sum && (
              <Draggable
                ref={ref}
                position={{
                  x: this.state.currentPosition.xRate,
                  y: this.state.currentPosition.yRate,
                }}
                axis="x"
                onDrag={this.onDrag}
                onTouchStart={() => {
                  //setborder(true);
                }}
                onTouchEnd={() => {
                  //setborder(false);
                }}
                onMouseUp={() => {
                  //setborder(true);
                }}
                onMouseDown={() => {
                  //setborder(false);
                }}
                handle=".handle"
              >
                <div className="support_section">
                  <div className="handle support_drag_handle support_drag_handle_hovered">
                    <img src={DragIcon} draggable={false} alt="" />
                  </div>
                  <img
                    src={require("../assets/images/cash_status.svg")}
                    alt=""
                    onClick={() => {
                      this.getTotalValue(filteredData);
                      this.setState({
                        sumDetail: true,
                      });
                    }}
                    height="40px"
                    width="40px"
                  />
                </div>
              </Draggable>
            )}

            {this.state.isMobile && (
              <div className="form-row">
                {this.state.showSearch && (
                  <div className="form-group col-md-12">
                    <input
                      className="form-control"
                      onChange={(e) =>
                        this.setState({ mobilequery: e.target.value })
                      }
                      placeholder="Search Here"
                    />
                  </div>
                )}
                <div className="form-group col-auto w-75">
                  {this.state.addtosum && (
                    <strong>
                      Checked Total :{" "}
                      {this.state[this.state.addtosum.BucketField] &&
                        this.state[
                          this.state.addtosum.BucketField
                        ].toLocaleString("en-IN")}
                    </strong>
                  )}
                </div>
                {this.state.updateUniqueData.length > 0 && <div className="form-group col-auto w-25 text-right">
                  <input
                    type="checkbox"
                    className="mobilecheckbox"
                    checked={this.state.CheckAll}
                    onChange={this.handleMasterCheckboxChange}
                    ref={(ref) => {
                      this["checkall"] = ref;
                    }}
                    style={{ display: "none" }}
                  />
                  {/* <Button
                    icon={
                      this.state.CheckAll ? (
                        <CloseOutlined />
                      ) : (
                        <CheckOutlined />
                      )
                    }
                    onClick={() => {
                      this["checkall"].click();
                    }}
                    type={!this.state.CheckAll ? "primary" : "default"}
                  ></Button> */}
                  {this.state.CheckAll ? (
                    ""
                  ) : (
                    <img
                      src={require("../assets/images/checked.svg")}
                      className="checked_img"
                      alt=""
                      onClick={() => {
                        this["checkall"].click();
                      }}
                    />
                  )}
                </div>}
              </div>
            )}
            {(this.state.payload_config.component_mapping != null ||
              this.state.payload_config.component_mapping != undefined) &&
            this.state.isMobile ? (
              mobilefilterdata.map((rowdata, rowindex) => {
                let color = this.getColor(rowdata);
                return (
                  <div className="mt-2 mb-3" key={rowindex}>
                  <div onClick={() => this.toggleSection(rowindex)}  style={{ cursor: "pointer" }}>
                    <ResponsiveReactGridLayout                      
                      style= {{zIndex: 1, cursor: "pointer", backgroundColor: color || "#007BFF"}}
                      cols={{ lg: 10, md: 10, sm: 6, xs: 4, xxs: 2 }}
                      breakpoints={{
                        lg: 1200,
                        md: 996,
                        sm: 768,
                        xs: 480,
                        xxs: 0,
                      }}
                      rowHeight={30}
                      layouts={this.state.layouts}
                      // onLayoutChange={(layout, layouts) =>
                      // this.onLayoutChange(layout, layouts)
                      // }
                      isDraggable={false}
                      isResizable={false}
                    >
                      {updateUniqueData.length > 0 && (
                        <div
                          key="0"
                          // data-grid={{
                          //   x: 0,
                          //   y: 0,
                          //   w: 1,
                          //   h: 1,
                          // }}
                          className="headerText notransform w-100 text-right pt-1"
                        >
                          <input
                            checked={this.state.selectedMassUpdateData.includes(
                              this.getPayloadId(rowdata)
                            )}
                            type="checkbox"
                            className="mobilecheckbox"
                            disabled={this.state.defaultCheckbox}
                            value={
                              this.state.payload_config.field_mapping.filter(
                                (field) => field.useasinput === "1"
                              ) == true
                                ? rowdata[
                                    this.state.payload_config.field_mapping
                                      .filter(
                                        (field) => field.useasinput === "1"
                                      )
                                      .map((field) => field.BucketField)
                                  ]
                                : this.state.payload_config.payload_type == "M"
                                ? rowdata["_id.$oid"]
                                  ? rowdata["_id.$oid"]
                                  : rowdata._id.$oid
                                : rowdata["key"]
                            }
                            onChange={(e) =>
                              this.handlemassCheckboxChange(e.target.value)
                            }
                          />
                          {this.state.payload_config.view_type == "AV" && this.state.payload_config.isexpand == "1" && (
                            <span
                              data-toggle="collapse"
                              data-target={"#" + rowindex}
                              onClick={() => {
                                this.updaterowindex(rowindex);
                                this.handleRowClick(rowdata);
                              }}
                              className="exprowbtn"
                            >
                              {/* <img src={require("../assets/images/plusicon.png")} alt="" /> */}
                              +
                            </span>
                          )}
                        </div>
                      )}
                      {this.state.payload_config.component_mapping.header.sort((a, b) => (a.y - b.y) || (a.x - b.x)).map(
                        (rowhead, rowindexhead) => {
                          // console.log(
                          //   "rowindexbodyCnt",
                          //   this.state.payload_config.component_mapping.body
                          //     .length
                          // );
                          // const fielddataArray = this.state.payload_config.component_mapping.body.map(
                          //   (item) => JSON.parse(item.fielddata)
                          // );

                            // const filtered = this.state.payload_config.field_mapping.filter(item =>
                            //   fielddataArray.some(d2 => d2.BucketField === item.BucketField)
                            // );

                            //console.log("fielddataArray", filtered);
                            // const fielddataJsonString = {
                            //   field_mapping: filtered,
                            // };
                            // const parsedFielddata = JSON.parse(rowbody.fielddata);
                            // const bucketFieldToMatch =
                            //   parsedFielddata.BucketField;

                            // // Find the corresponding object in data array
                            // const body_data = this.state.payload_config.field_mapping.find(
                            //   (item) => item.BucketField === bucketFieldToMatch
                            // );

                          const fielddataObj = JSON.parse(rowhead.fielddata);

                            // Find the corresponding object in the data array
                            const matchingData = this.state.payload_config.field_mapping.find(
                              (item) =>
                                item.BucketField === fielddataObj.BucketField
                            );

                          // If a matching object is found, update the DisableOnFieldValue
                          if (matchingData) {
                            fielddataObj.showLabel = matchingData.showLabel;
                            // Update initialData2 with the modified fielddata
                            rowhead.fielddata = JSON.stringify(fielddataObj);
                          }
                          const showColorForDate = () => {
                            this.state.payload_config.field_mapping.forEach((item) => {
                              if(item.datecondition) {
                                if(item.datecondition.value == rowhead[item.field]){
                                  color = item.row;
                                }
                              }
                            })
                          }
                          // Log the updated initialData2
                          return (
                            <div
                              key={rowindexhead}
                              data-grid={{
                                x: rowhead.x,
                                y: rowhead.y,
                                w: rowhead.w,
                                h: rowhead.h,
                              }}
                              className="headerText mt-2"
                            >
                              <EditableRow
                                key={rowindexhead}
                                //payload_config={fielddataJsonString}
                                payload_config={this.state.payload_config}
                                payload={rowdata}
                                disabled={this.state.disabled}
                                setDisabled={this.setDisabled}
                                api_token={this.state.api_token}
                                getPayloads={this.getPayloads}
                                DeletePayload={this.DeletePayload}
                                DeleteMongoPayload={this.DeleteMongoPayload}
                                rowindex={rowindex}
                                effective_designation_id={
                                  this.state.effective_designation_id
                                }
                                Reasonlist={this.state.Reasonlist_default}
                                subpayload_config={this.state.subpayload_config}
                                unique_groups={this.state.unique_groups}
                                supervisor_branch_code={
                                  this.state.supervisor_branch_code
                                }
                                isMassupdate={this.state.isMassupdate}
                                selectedMassUpdateData={
                                  this.handlemassCheckboxChange
                                }
                                updateUniqueData={updateUniqueData}
                                CheckAll={this.state.CheckAll}
                                props={this.props}
                                isspin={this.state.isspin}
                                view_type="mobile"
                                view_for="mobhead"
                                totcnt={
                                  this.state.payload_config.component_mapping
                                    .header.length
                                }
                                grid_position={rowhead}
                                rowindexbody={rowindexhead}
                                isChecked={this.state.selectedMassUpdateData.includes(
                                  this.getPayloadId(rowdata)
                                )}
                                geturl={this.getDataBetweenBraces}
                                addupdateddata={this.addupdateddata}
                                handleRowClick={this.handleRowClick}
                                isVisibleEdit={this.state.isVisibleEdit}
                                isMobileView={this.state.isMobile}
                                isExprowstatus={this.state.isExprow}
                                expandedRow={this.state.ExpandedRow}
                                selectedrowindex={this.state.selectedrowindex}
                                updaterowindex={this.updaterowindex}
                                storesubdata={this.storesubdata}
                                filtered_condition={
                                  this.state.filtered_condition
                                }
                                sub_data={this.state.sub_data}
                                removedCheckboxes={this.removedCheckboxes}
                                updateisspin={this.updateisspin}
                                defaultCheckbox={this.state.defaultCheckbox}
                                removedCheckboxesid={this.state.removedCheckboxesid}
                                editval={this.state.editval}
                                setEditfc={this.setEditfc}
                                setchangevalfc={this.setchangevalfc}
                                change_val={this.state.change_val}
                                setupdpayloadfc={this.setupdpayloadfc}
                                updpayload={this.state.updpayload}
                                color={color}
                                check_cache_data={this.check_cache_data}
                                getDataFromresponse={this.getDataFromresponse}
                                conditional_update={this.state.conditional_update}
                                updateDatafc={this.updateData}
                                getGroupForEntry = {this.getGroupForEntry}
                                passPayloadDataToParent={this.updatePayloadDataFromChild}
                              />
                              </div>
                            );
                          }
                        )}
                      </ResponsiveReactGridLayout>
                    </div>
                    {this.state.payload_config.component_mapping.body.length >
                      0 &&
                      expandedSections[rowindex] && (
                        <ResponsiveReactGridLayout
                          className="pv-block-body-dd"
                          cols={{ lg: 10, md: 10, sm: 6, xs: 4, xxs: 2 }}
                          breakpoints={{
                            lg: 1200,
                            md: 996,
                            sm: 768,
                            xs: 480,
                            xxs: 0,
                          }}
                          rowHeight={30}
                          layouts={this.state.bodyLayouts}
                          onLayoutChange={(layout, layouts) =>
                            this.onLayoutChangeBody(layout, layouts)
                          }
                          isResizable={false}
                          isDraggable={false}
                        >
                          {this.state.payload_config.component_mapping.body.map(
                            (rowbody, rowindexbody) => {
                              // console.log(
                              //   "rowindexbodyCnt",
                              //   this.state.payload_config.component_mapping.body
                              //     .length
                              // );
                              // const fielddataArray = this.state.payload_config.component_mapping.body.map(
                              //   (item) => JSON.parse(item.fielddata)
                              // );

                              // const filtered = this.state.payload_config.field_mapping.filter(item =>
                              //   fielddataArray.some(d2 => d2.BucketField === item.BucketField)
                              // );

                              //console.log("fielddataArray", filtered);
                              // const fielddataJsonString = {
                              //   field_mapping: filtered,
                              // };
                              // const parsedFielddata = JSON.parse(rowbody.fielddata);
                              // const bucketFieldToMatch =
                              //   parsedFielddata.BucketField;

                              // // Find the corresponding object in data array
                              // const body_data = this.state.payload_config.field_mapping.find(
                              //   (item) => item.BucketField === bucketFieldToMatch
                              // );

                              const fielddataObj = JSON.parse(
                                rowbody.fielddata
                              );

                              // Find the corresponding object in the data array
                              const matchingData = this.state.payload_config.field_mapping.find(
                                (item) =>
                                  item.BucketField === fielddataObj.BucketField
                              );

                              // If a matching object is found, update the DisableOnFieldValue
                              if (matchingData) {
                                fielddataObj.showLabel = matchingData.showLabel;
                                // Update initialData2 with the modified fielddata
                                rowbody.fielddata = JSON.stringify(
                                  fielddataObj
                                );
                              }
                            let color = this.getColor(rowbody);
                              // Log the updated initialData2
                              console.log("rowindexbody", rowbody);
                              {/* let color;
                          const findColor = (row) => {
                            this.state.payload_config.field_mapping[0].search_query.rules.forEach((item) => {
                              if(item.value == row[item.field]){
                                color = item.color
                              }
                            })
                          }
                          findColor(rowbody);
                          const bgColor = () => {
                            this.state.payload_config.field_mapping.forEach((item) => {
                              if(item.datecondition) {

                              }
                            })
                          }
                          console.log('rowbody passed', rowbody) */}
                          return (
                                <div
                                  key={rowindexbody}
                                  data-grid={{
                                    x: rowbody.x,
                                    y: rowbody.y,
                                    w: rowbody.w,
                                    h: rowbody.h,
                                  }}
                                  className="bodytext"
                                >
                                  <EditableRow
                                    key={rowindexbody}
                                    //payload_config={fielddataJsonString}
                                    payload_config={this.state.payload_config}
                                    payload={rowdata}
                                    disabled={this.state.disabled}
                                    setDisabled={this.setDisabled}
                                    api_token={this.state.api_token}
                                    getPayloads={this.getPayloads}
                                    DeletePayload={this.DeletePayload}
                                    DeleteMongoPayload={this.DeleteMongoPayload}
                                    rowindex={rowindex}
                                    effective_designation_id={
                                      this.state.effective_designation_id
                                    }
                                    Reasonlist={this.state.Reasonlist_default}
                                    subpayload_config={
                                      this.state.subpayload_config
                                    }
                                    unique_groups={this.state.unique_groups}
                                    supervisor_branch_code={
                                      this.state.supervisor_branch_code
                                    }
                                    isMassupdate={this.state.isMassupdate}
                                    selectedMassUpdateData={
                                      this.handlemassCheckboxChange
                                    }
                                    updateUniqueData={updateUniqueData}
                                    CheckAll={this.state.CheckAll}
                                    props={this.props}
                                    isspin={this.state.isspin}
                                    view_type="mobile"
                                    view_for="mobbody"
                                    totcnt={
                                      this.state.payload_config
                                        .component_mapping.body.length
                                    }
                                    grid_position={rowbody}
                                    rowindexbody={rowindexbody}
                                    isChecked={this.state.selectedMassUpdateData.includes(
                                      this.getPayloadId(rowdata)
                                    )}
                                    geturl={this.getDataBetweenBraces}
                                    addupdateddata={this.addupdateddata}
                                    handleRowClick={this.handleRowClick}
                                    isVisibleEdit={this.state.isVisibleEdit}
                                    isMobileView={this.state.isMobile}
                                    isExprowstatus={this.state.isExprow}
                                    expandedRow={this.state.ExpandedRow}
                                    selectedrowindex={
                                      this.state.selectedrowindex
                                    }
                                    updaterowindex={this.updaterowindex}
                                    storesubdata={this.storesubdata}
                                    filtered_condition={
                                      this.state.filtered_condition
                                    }
                                    sub_data={this.state.sub_data}
                                    removedCheckboxes={
                                      this.state.removedCheckboxes
                                    }
                                    updateisspin={this.updateisspin}
                                    beforeUpload={this.beforeUpload}
                                    onRemove={this.onRemove}
                                    hasFile={this.state.hasFile}
                                    checkanduploadfile={this.checkanduploadfile}
                                    defaultCheckbox={this.state.defaultCheckbox}
                                  // color={color}
                                  removedCheckboxesid={this.state.removedCheckboxesid}
                                  editval={this.state.editval}
                                  setEditfc={this.setEditfc}
                                  setchangevalfc={this.setchangevalfc}
                                  change_val={this.state.change_val}
                                  setupdpayloadfc={this.setupdpayloadfc}
                                  updpayload={this.state.updpayload}
                                  color={color}
                                  check_cache_data={this.check_cache_data}
                                  getDataFromresponse={this.getDataFromresponse}
                                  conditional_update={this.state.conditional_update}
                                  updateDatafc={this.updateData}
                                  getGroupForEntry = {this.getGroupForEntry}
                                  passPayloadDataToParent={this.updatePayloadDataFromChild}
                                />
                              </div>
                            );
                          }
                        )}
                      </ResponsiveReactGridLayout>
                    )}
                    {/* <div className="pv-block-body"></div> */}
                  </div>
                );
              })
            ) : (
              <div className="tableFixHead">
                <table
                  className="payload_view_table text-center table table-hover table-responsive"
                  id="my-table"
                >
                  <thead>
                    <tr>
                      {(updateUniqueData.length > 0 ||
                        this.state.payload_config.view_type == "AV") && (
                        <th>
                          {updateUniqueData.length > 0 ? (
                            <>
                              <input
                                type="checkbox"
                                  disabled={this.state.defaultCheckbox}
                                className="mobilecheckbox"
                                checked={this.state.CheckAll}
                                onChange={this.handleMasterCheckboxChange}
                              />
                              <div>&nbsp;</div>
                            </>
                          ) : (
                            "#"
                          )}
                        </th>
                      )}
                      {this.state.payload_config.field_mapping.map(
                        (cell, index) => {
                          return (
                            <>
                              {cell.isVisible == "1" && (
                                <th key={cell.fieldname}>
                                  {cell.fieldname}
                                  {cell.isRequired == 1 && <span> *</span>}
                                  {!isExporting && (
                                    <>
                                      <br />
                                      {cell.datatype === "date" ? (
                                        <RangePicker
                                          placeholder={["Start Date", "End Date"]}
                                          className={
                                            !isExporting
                                              ? "form-control form-control-sm"
                                              : "hidden"
                                          }
                                          onChange={(dates, dateStrings) =>
                                            this.handleDateRangeChange(dates, dateStrings, cell.BucketField)
                                          }
                                        />
                                      ) : cell.datatype === "dropdownlist" ? (
                                        <this.SearchDropdownlist
                                          className={
                                            !isExporting
                                              ? "form-control form-control-sm"
                                              : "hidden"
                                          }
                                          bucketfield={cell}
                                          isedit={true}
                                        />
                                      ) : cell.dropdown_in_filter == "1" ? (
                                        <this.DropdownFilter
                                          className={
                                            !this.state.isExporting
                                              ? "form-control form-control-sm"
                                              : "hidden"
                                          }
                                          bucketfield={cell}
                                          isedit={true}
                                        />
                                      ) : (
                                        <input
                                          placeholder={cell.fieldname}
                                          name={cell.fieldname}
                                          type="text"
                                          className={
                                            !isExporting
                                              ? "form-control form-control-sm"
                                              : "hidden"
                                          }
                                          value={this.state.searchQuery}
                                          onChange={(e) =>
                                            this.eventNameSearch(e, cell.BucketField)
                                          }
                                        />
                                      )}
                                    </>
                                  )}
                                  {cell.addtosum && cell.addtosum == "1" && filteredData.length > 0 ? (
                                    this.getfieldsum(filteredData, cell.BucketField)
                                  ) : (
                                    <div>&nbsp;</div>
                                  )}
                                </th>
                              )}
                            </>
                          );
                        })
                      }
                      {/* <th onClick={this.handleSort}>
                        Date Time{" "}
                        <span
                          className={
                            this.state.sortOrder == "asc"
                              ? "up-arrow"
                              : "down-arrow"
                          }
                        ></span>
                      </th> */}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.length > 0 ? (
                      filteredData.map((row, rowindex) => {
                        const color = this.getColor(row);
                        return (
                          <EditableRow
                            key={row.key}
                            payload_config={this.state.payload_config}
                            payload={row}
                            // color={color}
                            disabled={this.state.disabled}
                            setDisabled={this.setDisabled}
                            api_token={this.state.api_token}
                            getPayloads={this.getPayloads}
                            DeletePayload={this.DeletePayload}
                            DeleteMongoPayload={this.DeleteMongoPayload}
                            rowindex={rowindex}
                            effective_designation_id={
                              this.state.effective_designation_id
                            }
                            Reasonlist={this.state.Reasonlist_default}
                            subpayload_config={this.state.subpayload_config}
                            unique_groups={this.state.unique_groups}
                            supervisor_branch_code={
                              this.state.supervisor_branch_code
                            }
                            isMassupdate={this.state.isMassupdate}
                            selectedMassUpdateData={
                              this.handlemassCheckboxChange
                            }
                            updateUniqueData={updateUniqueData}
                            CheckAll={this.state.CheckAll}
                            props={this.props}
                            updateisspin={this.updateisspin}
                            view_type="table"
                            view_for="table"
                            grid_position={row}
                            isChecked={this.state.selectedMassUpdateData.includes(
                              this.getPayloadId(row)
                            )}
                            geturl={this.getDataBetweenBraces}
                            addupdateddata={this.addupdateddata}
                            handleRowClick={this.handleRowClick}
                            isVisibleEdit={this.state.isVisibleEdit}
                            isMobileView={this.state.isMobile}
                            isExprowstatus={this.state.isExprow}
                            expandedRow={this.state.ExpandedRow}
                            selectedrowindex={this.state.selectedrowindex}
                            updaterowindex={this.updaterowindex}
                            storesubdata={this.storesubdata}
                            filtered_condition={this.state.filtered_condition}
                            sub_data={this.state.sub_data}
                            removedCheckboxes={this.removedCheckboxes}
                            beforeUpload={this.beforeUpload}
                            onRemove={this.onRemove}
                            hasFile={this.state.hasFile}
                            checkanduploadfile={this.checkanduploadfile}
                            defaultCheckbox={this.state.defaultCheckbox}
                            color={color}
                            removedCheckboxesid={this.state.removedCheckboxesid}
                            editval={this.state.editval}
                            setEditfc={this.setEditfc}
                            setchangevalfc={this.setchangevalfc}
                            change_val={this.state.change_val}
                            setupdpayloadfc={this.setupdpayloadfc}
                            updpayload={this.state.updpayload}                            
                            check_cache_data={this.check_cache_data}
                            getDataFromresponse={this.getDataFromresponse}
                            conditional_update={this.state.conditional_update}
                            updateDatafc={this.updateData}
                            getGroupForEntry = {this.getGroupForEntry}
                            passPayloadDataToParent={this.updatePayloadDataFromChild}
                          />
                        );
                      })
                    ) : (
                      <div className="text-center font-bold">No Data Found</div>
                    )}
                  </tbody>
                </table>
              </div>
            )}
            <div className="text-center">
              {this.state.payload_config.payload_type == "M" &&
                this.state.payload_config.hide_loadmore &&
                this.state.payload_config.hide_loadmore == "0" && (
                  <button
                    className="btn btn-primary mt-2"
                    disabled={
                      this.state.disable_loadmore ||
                      this.state.payload_data.length == 0
                    }
                    onClick={() => {
                      this.setState({ load_more: 1 }, () => this.getPayloads());
                    }}
                  >
                    Load More
                  </button>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function EditableRow({
  api_token,
  payload_config,
  payload,
  disabled,
  setDisabled,
  getPayloads,
  DeletePayload,
  DeleteMongoPayload,
  rowindex,
  effective_designation_id,
  Reasonlist,
  subpayload_config,
  unique_groups,
  supervisor_branch_code,
  isMassupdate,
  selectedMassUpdateData,
  updateUniqueData,
  CheckAll,
  props,
  updateisspin,
  view_type,
  view_for,
  totcnt,
  grid_position,
  rowindexbody,
  isChecked,
  geturl,
  addupdateddata,
  handleRowClick,
  isVisibleEdit,
  isMobileView,
  isExprowstatus,
  expandedRow,
  selectedrowindex,
  updaterowindex,
  storesubdata,
  filtered_condition,
  sub_data,
  removedCheckboxes,
  beforeUpload,
  onRemove,
  hasFile,
  checkanduploadfile,
  defaultCheckbox,
  color,
  removedCheckboxesid,
  editval,
  setEditfc,
  setchangevalfc,
  change_val,
  setupdpayloadfc,
  updpayload,
  check_cache_data,
  getDataFromresponse,
  conditional_update,
  updateDatafc,
  getGroupForEntry,
  passPayloadDataToParent
}) {
  const [defaultpayload_config, setdefaultpayload_config] = useState(
    payload_config
  );
  const [edit, setEdit] = useState(false);
  const [updatedPayload, setUpdatedPayload] = useState({});
  const [ImageView, setImageView] = useState();
  const [isImageView, setisImageView] = useState(false);
  const [isShowData, setisShowData] = useState(false);
  const [isPayloadView, setisPayloadView] = useState(false);
  const [PayloadViewData, setPayloadViewData] = useState();
  const [ShowData, setShowData] = useState(false);
  const [ReasonData, setReasonData] = useState();
  const [isFamily, setisFamily] = useState(false);
  const [familyData, setfamilyData] = useState();
  const [change_data, setchange_data] = useState({});
  const [verify_data, setverify_data] = useState({});
  const [change_blockdelete, setchange_blockdelete] = useState();
  //const [expandedRow, setExpandedRow] = useState(null);
  const [isgroupid, setisgroupid] = useState(false);
  const [groupid, setgroupid] = useState();
  const [blockData, setblockData] = useState();
  const [isblockDataInput, setisblockDataInput] = useState(false);
  const [isBlock, setisBlock] = useState(false);
  const [DeleteBlock, setDeleteBlock] = useState(0);
  const [DeleteBucketField, setDeleteBucketField] = useState();
  const [updategroupid, setupdategroupid] = useState(unique_groups);
  const [updateReasonlist, setupdateReasonlist] = useState();
  const [mand_fld, setmand_fld] = useState();
  const [newfield_mapping, setnewfield_mapping] = useState();
  const [newfieldEdit_mapping, setnewfieldEdit_mapping] = useState();
  const [fieldtoupdate, setfieldtoupdate] = useState();
  const [tenure, settenure] = useState();
  const [productidtoupdate, setproductidtoupdate] = useState();
  const [productlist, setproductlist] = useState();
  const [errorMsg, seterrorMsg] = useState();
  const [isProductList, setisProductList] = useState(false);
  const [isFileUpload, setisFileUpload] = useState(false);
  const [fileUploadData, setfileUploadData] = useState([]);
  const [selectedProduct, setselectedProduct] = useState();
  const [updateAmount, setupdateAmount] = useState(0);
  //const [checkedItems, setCheckedItems] = useState(selectedMassUpdateData);
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [ActionFormType, setActionFormType] = useState();
  const [ModalForm, setModalForm] = useState(false);
  const [replacedFormrow, setreplacedFormrow] = useState();
  const [replacementValues, setreplacementValues] = useState();
  const [isModalFormUpdated, setIsModalFormUpdated] = useState(false);
  //const [isspin, setisspin] = useState(isspindata);
  const [onEdit, setonEdit] = useState(true);
  const [isAdd, setisAdd] = useState(false);
  const [DisableBtn, setDisableBtn] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [PayloadRequired, setPayloadRequired] = useState();
  const [isError, setisError] = useState(false);
  const [isErrorValue, setisErrorValue] = useState();
  const [isOpenModal, setisOpenModal] = useState(false);
  const [actionValue, setactionValue] = useState();
  const [editLoading, setEditLoading] = useState(false);
  const [names, setNames] = useState({});
  const [isExprow, setisExprow] = useState(false);
  const [actionEvent, setActionEvent] = useState("");
  const [openFileDownloadModal, setOpenFileDownloadModal] = useState(false);
  const [isLoadingname, setisLoadingname] = useState(false);
  const [mimeType, setMimeType] = useState("");

  useEffect(() => {        
    const allCheckboxValues = CheckAll ? [] : getDataIds(); // Replace with your dynamic data IDs
    setCheckboxValues(allCheckboxValues);
    setTimeout(() => {
      if (document.readyState === "complete") {
        getdatafromAPI();
      }
    }, 1000);    
  }, []);
  useEffect(() => {
    if(isMobileView){
      setEdit(editval);
    }
  }, [editval]);
  useEffect(() => {
    if(isMobileView){
      setchange_data(change_val);
    }
  }, [change_val]);
  useEffect(() => {
    if(isMobileView){
      setUpdatedPayload(updpayload);
    }
  }, [updpayload]);
  useEffect(() => {
    if (!isExprow && expandedRow.length > 0) {
      setisExprow(isExprowstatus);
    }
  }, [expandedRow]);

  useEffect(() => {
    const getfromapidata = payload_config.field_mapping.filter(
      (item) => {
        const result =
          item.datatype === "dropdownlist";
        return result;
      }
    );
    if(getfromapidata && getfromapidata.length > 0){
      const item_value = converttoarray(getfromapidata[0].BucketField, payload);
      if(payload_config.field_mapping[0].disableedit && payload_config.field_mapping[0].disableedit.includes(item_value.toString())){
        console.log("getfromapidata",item_value);
        setDisableBtn(true);
      }
    }
  }, []);

  const getdatafromAPI = async () => {
    try {
      setisLoadingname(true);
  
      const getfromapidata = payload_config.field_mapping.filter(
        (fields) => fields.getfromapi == "1"
      );
  
      if (getfromapidata.length > 0) {
        const namesMap = {};
  
        // Iterate over each record in getfromapidata
        for (const field of getfromapidata) {
          const getapi = payload_config.get_api_mapping.filter(
            (item) => item.action_name == field.getdatafromapi
          );
  
          if (getapi.length > 0) {
            const apiurl = geturl(getapi[0].api_url, payload);
  
            const regex = /{{(.*?)}}/g;
            let match = regex.exec(getapi[0].getoutputdata);
  
            const requestOptions = {
              method: "GET",
              headers: {
                "api-token": api_token,
              },
            };
  
            const response = await customFetch(
              process.env.REACT_APP_URL + "/" + apiurl,
              requestOptions
            );
  
            if (response.status == 200) {
              const data = await response.json();
  
              // Handle both object and array responses
              if (Array.isArray(data)) {
                // If the response is an array, map through it and extract the required field
                namesMap[field.BucketField] = data.map((item) => item[match[1]]);
              } else if (typeof data === "object" && data !== null) {
                // If the response is an object, extract the required field directly
                namesMap[field.BucketField] = data[match[1]];
              } else {
                // If the response is neither an array nor an object, set a default value
                namesMap[field.BucketField] = " ";
              }
            } else {
              namesMap[field.BucketField] = " ";
            }
          } else {
            namesMap[field.BucketField] = " ";
          }
        }
  
        setNames(namesMap);
        if(Object.keys(namesMap).length > 0) {
          passPayloadDataToParent(payload._id.$oid , namesMap);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setisLoadingname(false);
    }
  };
  const getDataIds = () => {
    // payload_config.field_mapping.map((cell, tdindex) => {
    //   handlemassCheckboxChange(payload_config.payload_type == "M" ? payload._id.$oid : payload["key"]);
    // });
  };
  useEffect(() => {
    setchangevalfc();
    setverify_data({      
      "msg_verify": "", // Dynamic key update
      "sel_verify":""
    });
    setDisabled(edit);
    let _updatedPayload = {
      ...payload,
    };

    if(isMobileView)
    {
      setupdpayloadfc(_updatedPayload);
      const filteredList = payload_config.field_mapping.filter(
        (item) => item.addapi === "1"
      );
      if(filteredList[0].BucketField == JSON.parse(grid_position.fielddata).BucketField && edit==true)
      {
        checkApicall();
      }
    } else {
      if(edit==true){
        checkApicall();
      }
      setUpdatedPayload(_updatedPayload);
    }
    
  }, [edit]);

  const checkApicall = async() => {
    updateisspin(true);
    const filteredList = payload_config.field_mapping.filter(
      (item) => item.addapi === "1" && item.isEdit === "1"
    );

    let dropdown_id = "";
    let item_value = "";
    let fieldname = "";
    if (filteredList.length > 0) {
      item_value = converttoarray(filteredList[0].BucketField, payload);
      if (payload.service_id && converttoarray("service_id", payload) !== "") {
        const service_id = converttoarray("service_id", payload);
          const form = JSON.stringify([["service", "==", service_id]]);
          const url = `${process.env.REACT_APP_URL}/mongo/get/configuration_master/service_configuration`;
          const requestOptions = {
            method: "POST",
            headers: {
              "api-token": api_token,
              "Content-Type": "application/json",
            },
            body: form,
          };
  
          try {
            const response = await customFetch(url, requestOptions);
            const data = await response.json();
  
            if (data && data[0] && data[0].dropdown_id_from_mysql) {
              dropdown_id = data[0].dropdown_id_from_mysql;
              //apiCache[service_id] = dropdown_id; // Cache the dropdown_id
            } else {
              console.error("Invalid response from API for service_id:", service_id);
            }
          } catch (error) {
            console.error("API call failed for service_id:", service_id, error);
            updateisspin(false);
            return;
          }
        
      } else {
        dropdown_id = filteredList[0].dropdownlist;
      }
      fieldname = filteredList[0].BucketField;
    }
    if (dropdown_id && item_value) {
      let generate_Id = generateId(10);
      const requestOptions = {
        method: "GET",
        headers: {
          "api-token": api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: "payloadview",
          user_id: localStorage.getItem("in_userid"),
        },
      };

      customFetch(
        process.env.REACT_APP_URL +
          "/get/dropdown/item/master/data/" +
          dropdown_id +
          "/" +
          item_value,
        requestOptions
      )
        .then((res) => {
          return res.json();
        })
        .then((json) => {

          let resultObject = "";
          if (json.next_possible_values) {
            let filteredArray = Reasonlist[fieldname].filter((item) =>
              json.next_possible_values.includes(item.item_value)
            );

            filteredArray.forEach((item) => {
              const matchIndex = json.next_possible_values.indexOf(
                item.item_value
              );
              if (matchIndex !== -1) {
                // Update `item_name` from `next_possible_values_path`
                item.item_name = json.next_possible_values_path[matchIndex];
              }
            });

            if (
              filteredList[0].dropdowncondition &&
              filteredList[0].dropdowncondition.length > 0
            ) {
              const filterdata = filteredArray.filter((item) => {
                return filteredList[0].dropdowncondition.some((condition) => {
                  return condition.filtereddropdownlist === item.item_value && (condition.usedfor == "RD" || condition.usedfor == "BD");
                });
              });
              filteredArray = filterdata;
          }

            resultObject = {
              ...Reasonlist,
              [fieldname]: filteredArray,
            };
          } else {
            resultObject = {
              ...Reasonlist,
              [fieldname]: "",
            };
          }
          updateisspin(false);
          setupdateReasonlist(resultObject);
          //setmand_fld(json.mand_fld_for_this_status);
          //setisBlock(false);
        });
    } else {
      updateisspin(false);
      setupdateReasonlist(Reasonlist);
    }
  };
  useEffect(() => {
    if (DeleteBlock == 1 && change_blockdelete) {
      handlePayloadChange("", DeleteBucketField);
      let loaddata = 1;
      delete_payloadkey(
        payload_config["bucket_name"],
        payload["key"],
        loaddata
      );
    }
  }, [DeleteBlock]);

  useEffect(() => {
    if (updateAmount == 1 && selectedProduct != "") {
      let splitproductdata = selectedProduct.split("~");

      handlePayloadChange(parseInt(splitproductdata[0]), productidtoupdate);
      handlePayloadChange(parseInt(splitproductdata[1]), fieldtoupdate);
      handlePayloadChange(parseInt(splitproductdata[2]), tenure);
    }
    if (
      updateAmount == 1 &&
      selectedProduct != "" &&
      Object.keys(change_data).length != 0
    ) {
      let loaddata = 1;
      editHandler(payload_config["bucket_name"], payload["key"], loaddata);
      setupdateAmount(0);
      setselectedProduct();
    }
  }, [updateAmount, change_data]);

  const convertToInputType = (value, inputType) => {
    switch (inputType) {
      case "text":
        return String(value);
      case "number":
        return Number(value);
      case "int32":
        return parseInt(value, 10) | 0;
      // Add more cases for other input types as needed
      default:
        return value;
    }
  };
  const checkifverify = async(bucket_field) => {
    updateisspin(true);
    const matchingItem = payload_config.field_mapping.find(
      (item1) => item1.BucketField === bucket_field
    );
    if(matchingItem && matchingItem.verify_input=="1"){
      const getapi = payload_config.get_api_mapping.filter(
        (item) => item.action_name == matchingItem.verify_input_api
      );
      const apiurl = geturl(getapi[0].api_url, payload,change_data[bucket_field]);
      const requestOptions = {
        method: "GET",
        headers: {
          "api-token": api_token,
        },
      };

      const response = await customFetch(
        process.env.REACT_APP_URL +
          "/" +
          apiurl,
        requestOptions
      );
      const data = await response.json();
      if(data.swap_flag) {
        if(data.message){
          setverify_data((prevData) => ({
            ...prevData,
            "msg_verify": data.message, // Dynamic key update
            "sel_verify":""
          }));
          setDisableBtn(true);
        } else {
          const respinsedata = getDataFromresponse(getapi[0].getoutputdata, data);
          const transformedData = [{
            key: respinsedata[0].value,
            value: respinsedata[1].value,
          }];
          setverify_data((prevData) => ({
            ...prevData,
            "sel_verify": transformedData, // Dynamic key update
            "msg_verify":""
          }));
          setchange_data((prevData) => ({
            ...prevData,
            [bucket_field]:transformedData[0].key,
          }));
          setDisableBtn(false);
        }
      } else {
        setverify_data((prevData) => ({
          ...prevData,
          "msg_verify": data.message, // Dynamic key update
        }));
        setDisableBtn(true);
      }
    }
    updateisspin(false);
  }
  const handlePayloadChange = (inputValue, bucket_field) => {
    //if (payload_config.payload_type == "B") {
    //console.log("bucket_field", payload.field_mapping);
    let _updatedPayload = {
      ...updatedPayload,
    };

    let value = convertToInputType(
      inputValue,
      typeof converttoarray(bucket_field, updatedPayload)
    );
    let splitdata_bucket_field = bucket_field.split(".");
    splitdata_bucket_field.reduce((obj, key, i) => {
      if (i === splitdata_bucket_field.length - 1) {
        //obj[key] = JSON.parse(value) ? JSON.parse(value) : value;
        obj[key] = value;
      }
      return obj[key];
    }, _updatedPayload);
    console.log("_updatedPayload",_updatedPayload);        
    console.log("Updating field:", bucket_field, "with value:", value);
    if(isMobileView){
      setchangevalfc({[bucket_field]: value});
      setupdpayloadfc({ ..._updatedPayload });
    } else {
      setchange_data((prevData) => ({
        ...prevData,
        [bucket_field]: value, // Dynamic key update
      }));
      setUpdatedPayload({ ..._updatedPayload });
    }
    checkReason(_updatedPayload);    
    // call below function if save mongo return error
    //checkmand_fld(_updatedPayload);
  };
  const checkmand_fld = (payload_data, req_fld) => {    
    if (req_fld) {
      // set default config
      //console.log("defaultpayload_config", defaultpayload_config.field_mapping);
      const updatedDataDefault = payload_config.field_mapping.map((item) => {
        const matchingItem = defaultpayload_config.field_mapping.find(
          (item1) => item1.BucketField === item.BucketField
        );

        if (matchingItem) {
          // If a matching item is found in data1, update the isRequired value
          return {
            ...item,
            isRequired: matchingItem.isRequired,
          };
        }

        // If no matching item is found, keep the original item
        return item;
      });
            
      // const filteredList = payload_config.field_mapping.filter(
      //   (item) => item.addapi === "1"
      // );
      
      // let item_value = filteredList[0].BucketField;
      
      // let keyToFilter = converttoarray(item_value, payload_data);
      
       //const filteredData = mand_fld[keyToFilter];
       const filteredData = req_fld;
      

      if (filteredData) {
        const updatedData = updatedDataDefault.map((item) => {
          if (filteredData.includes(item.BucketField)) {
            item.isRequired = "1";
          }
          return item;
        });

        // const updatedData = updatedDataDefault.filter(
        //   (item) => item.isRequired === "1"
        // );
        setnewfield_mapping(updatedData);
        validatepayloaddata(updatedData);
      }
    }
  };

  const validatepayloaddata = (updatedData = []) => {
    setPayloadRequired();
    let isValid = true;

    let PayloadRequired = [];
    let mapdata = updatedData && updatedData.length > 0 ? updatedData : payload_config.field_mapping;
    
    mapdata.map((field) => {
      if (field.isRequired === "1") {
        PayloadRequired.push(field);
      }
    });

    const list = [...PayloadRequired];
    //console.log("list", list);
    PayloadRequired.map((bucket_field, index) => {
      //let splitdata_bucket_field = bucket_field.field.split(".");
      let splitdata = bucket_field.BucketField.split(".");
      let getvalue = splitdata.reduce((obj, key) => obj[key], updatedPayload);
      if (!getvalue) {
        isValid = false;
        list[index]["isError"] = "1";
      } else {
        list[index]["isError"] = "0";
      }
    });

    const isError1Fields = list
      .filter((item) => item.isError === "1")
      .map((item) => item.fieldname)
      .join(", ");

    const hasErrors = isError1Fields.length > 0;
    if (hasErrors) {
      setisErrorValue(isError1Fields);
      setisError(true);
    } else {
      setisError(false);
    }
    setPayloadRequired(list);
    return isValid;
  };

  const checkReason = (payload_data) => {
    if (payload_data.advance > 0) {
      const filteredData = Reasonlist["advance"].filter(
        (item) => item.item_value == payload_data.advance
      );
      if (filteredData[0].target_queue != "") {
        let _updatedPayload = {
          ...payload_data,
        };
        let splitqueue = filteredData[0].target_queue.split("|");
        splitqueue.map((splitdata) => {
          let splitpair = splitdata.split("=");
          let bucket_key = splitpair[0].trim();
          let splitdata_bucket_field = bucket_key.split("."); //payload_config[bucket_key].split(".");
          splitdata_bucket_field.reduce((obj, key, i) => {
            if (i === splitdata_bucket_field.length - 1) {
              //obj[key] = JSON.parse(value) ? JSON.parse(value) : value;
              obj[key] = splitpair[1].trim();
            }
            return obj[key];
          }, _updatedPayload);
          console.log("_updatedPayload-->", _updatedPayload);
          
          if(isMobileView){            
            setupdpayloadfc({ ..._updatedPayload });
          } else {
            setUpdatedPayload({ ..._updatedPayload });
          }
        });
      }
    }
  };

  useEffect(() => {
    if (isModalFormUpdated) {
      // Call actionHandler here based on the updated ModalForm
      setIsModalFormUpdated(false);
      checkonsaveapi();
      checkCondition();
    }
  }, [ModalForm, replacedFormrow, isModalFormUpdated]);
  // call the "checkCondition" function when action_event is condition
  useEffect(() => {
    if (actionEvent === "condition") {
      // setActionEvent("");
      setTimeout(() => {
        checkCondition();
      }, 100);
    }
  }, [actionEvent]);
  const checkonsaveapi = async () => {
    const action = payload_config.action_mapping.filter((item) => {
      if (item.action_event == "condition") {
        setActionEvent("condition");
      }
      return item.action_event == "onsave";
    })[0];
    const onsave = true;
    if (action) {
      if (ModalForm || replacedFormrow) {
        if (ActionFormType == "formdata") {
          actionHandler(action.id, ModalForm, ActionFormType);
        }
        if (ActionFormType == "formrow") {
          actionHandler(action.id, replacedFormrow, ActionFormType);
        }
      } else {
        generate_modaldata(action.id, updatedPayload, onsave);
      }
    }
  };
  const checkCondition = async () => {
    const action = payload_config.action_mapping.filter((item) => {
      return item.action_event == "condition";
    })[0];
    const selectedFieldName = payload_config.field_mapping.filter((item) => {
      if (item.fieldname === action.select_field) {
        return item;
      }
    });
    const valueOfSelectedFieldName = converttoarray(
      selectedFieldName[0].BucketField,
      updatedPayload
    );
    const arr1 = action.condition_value
      .split(",")
      .map((item) => item.trim())
      .sort();
    const arr2 = valueOfSelectedFieldName
      .toString()
      .split(",")
      .map((item) => item.trim())
      .sort();
    const onsave = true;
    if (action) {
      if (arr1.filter((value) => arr2.includes(value))) {
        if (ModalForm || replacedFormrow) {
          if (ActionFormType == "formdata") {
            actionHandler(action.id, ModalForm, ActionFormType);
          }
          if (ActionFormType == "formrow") {
            actionHandler(action.id, replacedFormrow, ActionFormType);
          }
        } else {
          generate_modaldata(action.id, updatedPayload, onsave);
        }
      }
    }
  };
  const delete_payloadkey = async (bucket_name, payload_key, loaddata = 0) => {
    if (payload_config.payload_type == "M") {
      let dbname, collectionname;
      if (payload_config.db_name == "") {
        dbname = "callbacks";
      } else {
        dbname = payload_config.db_name;
      }

      if (payload_config.collection_name == "") {
        collectionname = "callback_payloads";
      } else {
        collectionname = payload_config.collection_name;
      }
      let objectid = payload._id.$oid;
      let upd_data;
      if (DeleteBlock == 1) {
        upd_data = change_blockdelete;
      } else {
        upd_data = change_data;
      }
      const response = await delete_mongofield(
        api_token,
        dbname,
        collectionname,
        objectid,
        upd_data
      );

      if (response.status == 1) {
        if (DeleteBlock == 1) {
          setDeleteBlock(0);
        }
        setisblockDataInput(false);
        checkonsaveapi();
        if (loaddata == 1) {
          getPayloads();
        }
        Modal.success({
          content: <pre id="json">{response.message}</pre>,
          title: "Success",
        });
        return true;
      } else {
        Modal.error({
          content: <pre id="json">{response.message}</pre>,
          title: "Error",
        });
        return false;
      }
    }
  };
  const checkAndPush = (obj, newElement) => {
    if (Object.keys(obj).length === 0) {
      // Convert the object to an array
      const array = [obj];

      // Push the new element
      array.push(newElement);

      return array;
    } else {
      // The object is not empty, so return it directly
      return obj;
    }
  };
  const editHandler = async (bucket_name, payload_key, loaddata = 0) => {
    const generateValueFromRegex = (regexPattern) => {
      if (!regexPattern || typeof regexPattern !== "string") return "";
  
      try {
        const RandExp = require("randexp").default || require("randexp");
        const randexp = new RandExp(regexPattern);
        randexp.defaultRange.add(0, 65535);
        return randexp.gen();
      } catch (error) {
        return "";
      }
    };
  
    if (payload_config.payload_type == "M") {
      let dbname, collectionname;
      if (payload_config.db_name == "") {
        dbname = "callbacks";
      } else {
        dbname = payload_config.db_name;
      }
  
      if (payload_config.collection_name == "") {
        collectionname = "callback_payloads";
      } else {
        collectionname = payload_config.collection_name;
      }
  
      let objectid = payload._id.$oid;
      let upd_data;
      if (DeleteBlock == 1) {
        upd_data = change_blockdelete;
      } else {
        upd_data = change_data;
      }
  
      if (payload_config.setupdated_at && payload_config.setupdated_at) {
        upd_data = addupdateddata(upd_data);
      }
  
      const bucketFields = payload_config.field_mapping.map(
        (item) => item.BucketField
      );
      const addDataBeforeField = payload_config.field_mapping.filter(
        (item) => item.add_data_before === "1"
      );
  
      const result = { add_data_before: {} };
      let anyKeyMatched = false;
  
      for (const key in upd_data) {
        if (bucketFields.includes(key)) {
          if (addDataBeforeField && addDataBeforeField.find((item) => item.BucketField === key)) {
            result["add_data_before"][key] = upd_data[key];
            anyKeyMatched = true;
          } else {
            result[key] = upd_data[key];
          }
        } else {
          result[key] = upd_data[key];
        }
      }
  
      if (!anyKeyMatched) {
        result["add_data_before"] = {};
      }
  
      const autogeneratetxn = payload_config.field_mapping.filter(
        (item) => item.autogeneratetxn === "1"
      );
  
      if (autogeneratetxn.length > 0) {
        autogeneratetxn.forEach((autogenConfig) => {
          let generatedValue = "";
  
          if (["transaction_no", "random"].includes(autogenConfig.selectConfig)) {
            const regex = autogenConfig.regularExpression || ".*";
            generatedValue = generateValueFromRegex(regex);
  
            if (autogenConfig.isappendtxn === "1") {
              generatedValue = `${result[autogenConfig.BucketField]}||${generatedValue}`;
            }
          } else if (autogenConfig.selectConfig === "date_time") {
            const dateTimeFormat = autogenConfig.dateTimeFormat;
            const currentDateTime = (() => {
              switch (dateTimeFormat) {
                case "iso":
                  return new Date().toISOString();
                case "us":
                  return new Date().toLocaleString("en-US");
                case "intl":
                  return new Date().toLocaleString("en-GB");
                case "unix":
                  return Math.floor(Date.now() / 1000);
                case "rfc2822":
                  return new Date().toUTCString();
                case "custom":
                  return new Date().toLocaleString("en-IN", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  });
                default:
                  return new Date().toISOString();
              }
            })();
  
            generatedValue = autogenConfig.isappendtxn === "1"
              ? `${result[autogenConfig.BucketField]}||${currentDateTime}`
              : currentDateTime;
          } else if (autogenConfig.selectConfig === "user_id") {
            const userId = localStorage.getItem("in_userid");
            generatedValue = autogenConfig.isappendtxn === "1"
              ? `${result[autogenConfig.BucketField]}||${userId}`
              : userId;
          }
  
          result[autogenConfig.BucketField] = generatedValue;
        });
      }
  
      let response = {};
      let updatedData = [];
      if (payload_config.view_type == "AV") {
        let committype;
        if (payload_config.commit_type) {
          committype = payload_config.commit_type;
        } else {
          committype = "1";
        }
        const get_groupdata = getGroupForEntry(payload, "RowClick",objectid);
        const oidArray = get_groupdata.map((item) => item._id.$oid);
        const allResponses = getGroupForEntry(result, "SubmitRowData", oidArray);
        const updateasresultData = allResponses.map((entry) => {
          return {
            ...result,
            object_id: entry._id.$oid,
          };
        });
        updatedData = updateasresultData.map((item) => {
          return {
            ...item,
            commit_flag: committype,
          };
        });
        let upd_data = [];
        if(conditional_update.length > 0 && conditional_update[0].check_data_from !=""){
          upd_data = updateDatafc(conditional_update,payload,updatedData,payload);
        } else {
          upd_data = updatedData;
        }
        response = await update_mongogrouppayload(
          api_token,
          dbname,
          collectionname,
          upd_data
        );
      } else {
        let upd_data = [];
        if(conditional_update.length > 0 && conditional_update[0].check_data_from !=""){
          upd_data = updateDatafc(conditional_update,updateUniqueData,result,payload);
        } else {
          upd_data = result;
        }
  
        response = await update_mongopayload(
          api_token,
          dbname,
          collectionname,
          objectid,
          upd_data
        );
      }
  
      if (response.status == 0) {
        updateisspin(false);
        if (response.fields) {
          checkmand_fld(updatedPayload, response.fields);
        } else {
          Modal.error({
            content: <pre id="json">{response.message}</pre>,
            title: "Error",
          });
          return false;
        }
      } else if (response.status == 1) {
        updateisspin(false);
        if (DeleteBlock == 1) {
          setDeleteBlock(0);
        }
        setisblockDataInput(false);
        checkonsaveapi();
        let update_data = [];
        result["object_id"] = objectid;
        update_data = [result];
        check_cache_data(update_data);
        Modal.success({
          content: <pre id="json">Payload updated successfully!</pre>,
          title: "Success",
        });
        return true;
      } else {
        Modal.error({
          content: <pre id="json">{response.message}</pre>,
          title: "Error",
        });
        return false;
      }
    }
  
    if (payload_config.payload_type == "B") {
      const response = await update_payload(
        api_token,
        bucket_name,
        payload_key,
        updatedPayload
      );
  
      if (response.status == 1) {
        Modal.success({
          content: <pre id="json">{response.message}</pre>,
          title: "Success",
        });
        return true;
      } else {
        Modal.error({
          content: <pre id="json">{response.message}</pre>,
          title: "Error",
        });
        return false;
      }
    }
  };

  const actionHandler = async (action_id, payload_data, ActionFormType) => {
    let payloadkey = payload.key;
    try {
      setDisabled(true);
      const action = payload_config.action_mapping.filter((item) => {
        return item.id == action_id;
      })[0];
      let URL;
      // if (payload_config.payload_type == "M") {
      //   URL = process.env.REACT_APP_PYTHON_URL;
      // }
      // if (payload_config.payload_type == "B") {
      //   URL = process.env.REACT_APP_URL;
      // }
      if (action.url_type == "B") {
        URL = action.api_url;
      } else {
        URL = process.env.REACT_APP_URL + "/" + action.api_url;
      }
      const url = geturl(URL, payload);
      let payload_input = null;
      let method = null;
      if (payload_data) {
        if (ActionFormType == "formdata") {
          const form = new FormData();
          payload_data.forEach((field) => {
            form.append(field.field, field.value);
          });
          if (payload_config.payload_type == "B") {
            form.append("doc_id", payloadkey);
          }
          payload_input = form;
        }
        if (ActionFormType == "formrow") {
          payload_input = payload_data;
        }
        method = "POST";
      }
      let generate_Id = generateId(10);
      let requestOptions = "";
      if (ActionFormType == "formdata") {
        requestOptions = {
          method: method,
          headers: {
            "api-token": api_token,
            component_id: generate_Id1,
            apiId: generate_Id,
            api_group_id: "",
            component: "PaylodView",
            user_id: localStorage.getItem("in_userid"),
          },
          body: payload_input,
        };
      }
      if (ActionFormType == "formrow") {
        requestOptions = {
          method: method,
          headers: {
            "api-token": api_token,
            component_id: generate_Id1,
            apiId: generate_Id,
            api_group_id: "",
            component: "PaylodView",
            user_id: localStorage.getItem("in_userid"),
            "Content-Type": "application/json",
          },
          body: payload_input,
        };
      }

      const response = await customFetch(url, requestOptions);

      const data = await response.json();
      if (response.status != 200) throw data;

      Modal.success({
        content: <pre id="json">{JSON.stringify(data)}</pre>,
        title: "Success",
      });
    } catch (e) {
      Modal.error({
        content: <pre id="json">{JSON.stringify(e)}</pre>,
        title: "Error",
      });
    } finally {
      setDisabled(false);
      getPayloads();
    }
  };
  const generate_modaldata = async (
    action_id,
    payload_data,
    onsave = false
  ) => {
    const action = payload_config.action_mapping.filter((item) => {
      return item.id == action_id;
    })[0];
    if (onsave) {
      setIsModalFormUpdated(true);
    }
    const url = action.api_url;
    // let payload = null;
    // let method = null;
    if (action["formdata"]) {
      setActionFormType("formdata");
      let form = [];
      for (var k = 0; k < payload_config.field_mapping.length; k++) {
        for (var l = 0; l < action.formdata.length; l++) {
          var exists = false;
          if (
            payload_config.field_mapping[k].fieldname ===
            action.formdata[l].formdatavalue
          ) {
            let splitdaTa =
              payload_config.field_mapping[k].BucketField.split(".");
            let searchdata = "";
            // if (splitdaTa.length > 0) {
            //   for (let index = 0; index < splitdaTa.length; index++) {
            //     searchdata += '["' + splitdaTa[index] + '"]';
            //   }
            // }
            let result = payload_data;
            for (let key of splitdaTa) {
              result = result[key];
            }
            //console.log("field", action.formdata[l].formdatakey);
            //console.log("value", action.formdata);

            form.push({
              field: action.formdata[l].formdatakey,
              //value: payload_data[action.formdata[l].formdatakey],
              value: result,
              isRequired: payload_config.field_mapping[k].isRequired,
              datatype: payload_config.field_mapping[k].datatype,
              isEdit: payload_config.field_mapping[k].isEdit,
              fieldlabel: payload_config.field_mapping[k].fieldname,
              isError: "",
            });
            break;
          }
        }
      }
      setModalForm(form);
      // payload = form;
      // method = "POST";
    }
    if (action["formrow"]) {
      setActionFormType("formrow");
      const extractVariablesFromObject = (obj) => {
        if (typeof obj === "object" && obj !== null) {
          const result = {};
          for (const key in obj) {
            result[key] = extractVariablesFromObject(obj[key]);
          }
          return result;
        } else if (typeof obj === "string") {
          return extractVariables(obj);
        } else {
          return obj;
        }
      };
      // Function to extract variables from a string
      const extractVariables = (str) => {
        const regex = /{{(.*?)}}/g;
        const matches = str.match(regex);
        return matches ? matches.map((match) => match.slice(2, -2)) : [str];
      };

      // Extract variables from the input object
      const result = extractVariablesFromObject(action["formrow"]);
      let replacementValues = {};
      const checkNullData = (key_name) => {
        if (key_name == "user_id") {
          return localStorage.getItem("in_userid");
        }
        if (key_name == "payload") {
          return JSON.stringify(updatedPayload);
        }
      };

      result.map((cell) => {
        replacementValues[cell] = converttoarray(cell, payload)
          ? converttoarray(cell, payload)
          : checkNullData(cell);
      });

      const extractVariablesFroReplace = (obj, replacementValues) => {
        if (typeof obj === "object" && obj !== null) {
          const result = {};
          for (const key in obj) {
            result[key] = extractVariablesFroReplace(
              obj[key],
              replacementValues
            );
          }
          return result;
        } else if (typeof obj === "string") {
          return replaceVariables(obj, replacementValues);
        } else {
          return obj;
        }
      };

      const replaceVariables = (str, replacementValues) => {
        const regex = /{{(.*?)}}/g;
        const replacedStr = str.replace(regex, (match, variable) => {
          return replacementValues[variable] || match;
        });
        return replacedStr;
      };
      let replacedFormrow;

      replacedFormrow = extractVariablesFroReplace(
        action["formrow"],
        replacementValues
      );
      const keysWithNullValues = Object.keys(replacementValues).filter(
        (key) =>
          replacementValues[key] === null || replacementValues[key] === ""
      );
      setreplacementValues(keysWithNullValues);
      setreplacedFormrow(replacedFormrow);
    }
  };
  const converttoarray = (key, data) => {
    console.log("key--", key);
    const keys = key.split(/[.:]/);
    // Initialize a variable to store the current data object
    let currentData = data;

    // Loop through each part of the key
    for (const k of keys) {
      // Check if the currentData is an object and has the key
      if (currentData && currentData.hasOwnProperty(k)) {
        currentData = currentData[k]; // Update currentData to the nested object
      } else {
        return ""; // Return a default value if any part of the key is not present
      }
    }
    return currentData;
  };
  const viewFamily = (hhid) => {
    const cbResponseArray = payload.data.cb_response;

    // Filter ConsumerName and ReferenceNumber based on household_id
    const filteredData = cbResponseArray
      .filter((response) => payload.data.household_id === hhid)
      .map((response) => ({
        ConsumerName: response.ConsumerName,
        ReferenceNumber: response.ReferenceNumber,
      }));

    // Display the result
    setfamilyData(filteredData);
    setisFamily(true);
  };
  const viewImage = (path) => {
    updateisspin(true);
    let files = [];
    if (typeof path === "object") {
      function recursiveSearch(obj) {
        for (let key in obj) {
          if (key === "file_path" && obj[key]) {
            const filePath = obj[key];
            const fileName = filePath.split("/").pop(); // Get the last part of the path
            files.push({
              name: fileName,
              url: filePath,
            });
            return; // Stop further searching once the file_path is found
          }

          if (typeof obj[key] === "object" && obj[key] !== null) {
            recursiveSearch(obj[key]); // Search deeper into the object
          }
        }
      }

      recursiveSearch(path);
    } else {
      let filename = path.substring(path.lastIndexOf("/") + 1);
      files = [{ name: filename, url: path }];
    }
    // const formdata = new FormData();
    // formdata.append("blob_url", path);
    for (const file of files) {
      let generate_Id = generateId(10);
      const requestOptions = {
        method: "POST",
        headers: {
          "api-token": api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: "PaylodView",
          user_id: localStorage.getItem("in_userid"),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({"source_blob_name": file.url}),
      };
      // AT-2109 | AT-2156 | Ankur Makavana | 10-07-2023 | replace blob_url with public filepath
      customFetch(process.env.REACT_APP_FILE_DOWNLOAD + "/gcs/download", requestOptions)
        .then((res) => {
          return res.blob();
        })
        .then((json) => {
          setImageView(URL.createObjectURL(json));
          setMimeType(json.type);
          setisImageView(true);
          updateisspin(false);
        });
    }
    //const data = await response.blob();
  };

  const showdata = async (apiname, tabledata) => {
    let getapiconfig = JSON.parse(payload_config.get_api_mapping);
    let findapi = getapiconfig.find((item) => item.action_name == apiname);

    let form = [];
    for (var k = 0; k < payload_config.field_mapping.length; k++) {
      for (var l = 0; l < findapi.formdata.length; l++) {
        var exists = false;
        if (
          payload_config.field_mapping[k].fieldname ===
          findapi.formdata[l].formdatavalue
        ) {
          form.push({
            field: findapi.formdata[l].formdatakey,
            value: payload[payload_config.field_mapping[k].BucketField],
          });
          break;
        }
      }
    }
    for (var l = 0; l < findapi.formdata.length; l++) {
      if (
        findapi.formdata[l].formdatavalue == "" ||
        findapi.formdata[l].formdatavalue == null ||
        findapi.formdata[l].formdatavalue == undefined
      ) {
        form.push({
          field: findapi.formdata[l].formdatakey,
          value: findapi.formdata[l].defaultvalue,
        });
      }
    }
    const formdata = new FormData();
    form.map((fomdata) => {
      formdata.append(fomdata.field, fomdata.value);
    });
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: "PaylodView",
        user_id: localStorage.getItem("in_userid"),
      },
      body: formdata,
    };

    const response = await customFetch(
      process.env.REACT_APP_URL + "/" + findapi.api_url,
      requestOptions
    );
    if (response.status != 200) throw "Error updating the payload";

    const data = await response.json();
    setShowData(data);
    setisShowData(true);
  };
  const handleCancelimage = () => {
    setisImageView(false);
  };
  const handleCancelfamily = () => {
    setisFamily(false);
  };
  const handleCanceljson = () => {
    setisPayloadView(false);
  };
  const handleCancelblock = () => {
    setisBlock(false);
  };
  const handleCanceldata = () => {
    setisShowData(false);
  };
  const handleCancelgroupid = () => {
    setisgroupid(false);
  };
  // const handleRowClick = (rowData) => {
  //   console.log("rowData", payload);
  //   const subinput = getGroupForEntry(payload);
  //   setExpandedRow(subinput);
  //   if (isMobileView) {
  //     console.log("isMobileView", isMobileView);

  //     setisExprow(true);
  //   }
  // };
  const Dropdownlist = (val) => {
    let dropdown_data = "";
    if (val.bucketfield.dropDownOptions) {
      dropdown_data = val.bucketfield.dropDownOptions;
    }
    let bucket_field = val.bucketfield.BucketField.replace(/\./g, "_");
    let isedits = updateReasonlist && val.isedit == true && (
      <select
        className="form-control dropdata"
        defaultValue={val.value}
        //value={ReasonData}
        style={{
          width: 120,
        }}
        onChange={(event) => {
          handlePayloadChange(
            parseInt(event.target.value),
            val.bucketfield.BucketField
          );
          //setReasonData(event.target.value);
        }}
      >
        <option value="">Select</option>
        {updateReasonlist[bucket_field] &&
          Array.from(new Map(updateReasonlist[bucket_field].map(item => [item.item_value, item])).values()).map((list) => (
            <option value={list.item_value}>
              {dropdown_data == "V"
                ? list.item_value
                : dropdown_data == "L"
                ? list.item_name
                : dropdown_data == "B"
                ? list.item_value + " - " + list.item_name
                : list.item_name}
            </option>
          ))}
      </select>
    );
    if (val.isedit == false) {
      // let iseditfalse;
      // iseditfalse = Reasonlist.map(
      //   (list) => list.item_value == val.value && list.item_name
      // );
      if (Reasonlist && Reasonlist[bucket_field]) {
        const matchingItem = Reasonlist[bucket_field].find(
          (item) => item.item_value == val.value
        );

        // Check if a matching item was found if not then show value
        if (matchingItem) {
          const itemName = matchingItem.item_name;
          isedits = itemName;
        } else {
          isedits = val.value;
        }
      } else {
        isedits = "Select";
      }
    }
    return isedits;
  };
  const downloadfile = async (path,cell) => {
    if (
      path.bank_deposit_slip &&
      Object.keys(path.bank_deposit_slip[0]).length === 0
    ) {
      setOpenFileDownloadModal(true);
      return;
    }
    let files = [];
    if (typeof path === "object") {
      function recursiveSearch(obj) {
        for (let key in obj) {
          if (key === "file_path" && obj[key]) {
            const filePath = obj[key];
            const fileName = filePath.split("/").pop(); // Get the last part of the path
            files.push({
              name: fileName,
              url: filePath,
            });
            return; // Stop further searching once the file_path is found
          }

          if (typeof obj[key] === "object" && obj[key] !== null) {
            recursiveSearch(obj[key]); // Search deeper into the object
          }
        }
      }

      recursiveSearch(path);
    } else {
      let filename = path.substring(path.lastIndexOf("/") + 1);
      files = [{ name: filename, url: path }];
    }
    updateisspin(true);
    const zip = new JSZip();
    let file_download = 0;
    for (const file of files) {
      // const formdata = new FormData();
      // formdata.append("blob_url", file.url);
      let generate_Id = generateId(10);
      const requestOptions = {
        method: "POST",
        headers: {
          "api-token": api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: "PaylodView",
          user_id: localStorage.getItem("in_userid"),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({"source_blob_name": file.url}),
      };
      const response = await customFetch(
        process.env.REACT_APP_FILE_DOWNLOAD + "/gcs/download",
        requestOptions
      );

      //const blob = await response.blob();
      if (!response.ok) {
        console.error(`Failed to download file: ${file.name}`);
        continue; // Skip adding this file to the zip if there's an error
      }

      const blob = await response.blob();
      if (blob.size === 0) {
        console.error(`Downloaded blob is empty for file: ${file.name}`);
        continue;
     }
      
      if(files.length == 1 && cell.singlefile_direct && cell.singlefile_direct == "1") {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file.name);
        document.body.appendChild(link);
        link.click();
        // Clean up
        document.body.removeChild(link);
        file_download = 1;
      } else {
        zip.file(file.name, blob);
      }
    }
    if (file_download == 0) {
      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, `download_${moment().format("MMDDYYYYHHMMSS")}.zip`);
      });
    }
    updateisspin(false);
  };

  const showfileupload = (cell) => {
    console.log("cell-data--", cell);
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "GET",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: "payloadview",
        user_id: localStorage.getItem("in_userid"),
      },
    };
    updateisspin(true);
    const params = {
      upload_document_type_group_id: cell["doc_group"],
    };
    const queryParams = new URLSearchParams(params).toString();
    customFetch(
      process.env.REACT_APP_URL +
        "/fetch/upload/document/type/by/id?" +
        queryParams,
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        const updatedData = cell["fileupload"].map((item) => {
          // Find the matching entry in data based on selectType
          const matchingData = json.response.find(
            (entry) => entry.upload_document_type_id === item.selectType
          );

          // If a match is found, add upload_document_type_name to item
          return {
            ...item,
            upload_document_type_name: matchingData
              ? matchingData.upload_document_type_name
              : null, // or any default value if no match is found
          };
        });
        console.log("updatedData->", updatedData);
        console.log("jsondata->", json);

        // const [uploads, setUploads] = useState(
        //   updatedData.map((item) => ({
        //     ...item,
        //     files: item.UploadIsMultiple === "1" ? [""] : [""],
        //   }))
        // );
        const hasFileData = fileUploadData.some((item) =>
          item.files.some((file) =>
            file.filelistdata ? file.filelistdata.length > 0 : false
          )
        );
        if (!hasFileData) {
          setfileUploadData(
            updatedData.map((item) => ({
              ...item,
              files: item.UploadIsMultiple === "1" ? [""] : [""],
            }))
          );
        }
        updateisspin(false);
        setisFileUpload(true);
      });
  };

  const handleAddFile = (index) => {
    setfileUploadData((prevUploads) =>
      prevUploads.map((upload, i) =>
        i === index ? { ...upload, files: [...upload.files, ""] } : upload
      )
    );
  };

  const handleRemoveFile = (index, fileIndex) => {
    setfileUploadData((prevUploads) =>
      prevUploads.map((upload, i) =>
        i === index
          ? {
              ...upload,
              files: upload.files.filter((_, idx) => idx !== fileIndex),
            }
          : upload
      )
    );
  };

  const handleFileChange = (index, fileIndex, event, configdata) => {
    const file = event.file;
    console.log("event", event);
    console.log("file", file);
    const fileExtension = file.name.split(".").pop();

    const dataArray = configdata.UploadType.split(",").map((item) =>
      item.toLowerCase()
    );
    const isValidFileType = dataArray.includes(fileExtension.toLowerCase());

    // const isValidFileType =
    //   fileExtension.toLowerCase() == configdata.UploadType.toLowerCase();

    //const isPDF = file.type === 'application/pdf';
    const isValidSize = file.size / 1024 / 1024 < configdata.UploadSize; // 2MB

    if (!isValidFileType) {
      message.error(`${file.name} is not a ${configdata.UploadType} file`);
      return Upload.LIST_IGNORE; // Prevent file from being added
    }

    if (!isValidSize) {
      message.error(`${file.name} must be smaller than 2MB`);
      return Upload.LIST_IGNORE; // Prevent file from being added
    }
    const filelistdata = event.fileList;
    setfileUploadData((prevUploads) =>
      prevUploads.map((upload, i) =>
        i === index
          ? {
              ...upload,
              files: upload.files.map((f, idx) =>
                idx === fileIndex
                  ? {
                      filelistdata,
                      selectType: upload.selectType,
                      docType: upload.upload_document_type_name.replace(
                        /\s/g,
                        "_"
                      ),
                    }
                  : f
              ),
            }
          : upload
      )
    );
  };

  const showproductdata = (amount, fieldto_update, productid, tenure) => {
    setfieldtoupdate(fieldto_update);
    setproductidtoupdate(productid);
    settenure(tenure);
    setselectedProduct(
      (converttoarray(productid, payload) || "") +
        "~" +
        (converttoarray(fieldto_update, payload) || "") +
        "~" +
        (converttoarray(tenure, payload) || "")
    );
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "GET",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: "payloadview",
        user_id: localStorage.getItem("in_userid"),
      },
    };
    const params = {
      max_amount: amount,
      household_id: converttoarray("household_id", payload),
    };
    const queryParams = new URLSearchParams(params).toString();
    customFetch(
      process.env.REACT_APP_URL + "/partner/get/dcb/products?" + queryParams,
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.status == 1) {
          setproductlist(json.data);
        } else {
          setproductlist([]);
          seterrorMsg(json.message);
        }
        setisProductList(true);
      });
  };
  const showblockdata = (block, fieldname, BucketField) => {
    // const groupedData = {};

    // for (const key in block) {
    //   if (block.hasOwnProperty(key)) {
    //     const field = block[key];
    //     const parts = key.split(".");
    //     const group = parts.slice(0, -1).join(".") || fieldname; // Set to "aaa" if group is not found

    //     if (!groupedData[group]) {
    //       groupedData[group] = [];
    //     }

    //     groupedData[group].push({ BucketField: key, value: field });
    //   }
    // }

    setblockData(block);
    setDeleteBucketField(BucketField);
    setisBlock(true);
  };
  const deleteblockdelete = (deletepath) => {
    setchange_blockdelete({
      [deletepath]: "",
    });
    setblockData();
    setisBlock(false);
    setDeleteBlock(1);
    // setTimeout(() => {

    // }, 1000);
    //console.log("DeleteBucketField", change_data);
  };

  const chkpayloadrequired = () => {
    // below condition for check if status U then user can't update field
    if (updateReasonlist && updateReasonlist["status"]) {
      if (converttoarray("status", payload)) {
        const hasItemWithValueTwoAndStatusU = updateReasonlist["status"].some(
          (item) =>
            item.item_value === converttoarray("status", payload) &&
            item.status === "U"
        );
        // If such an item exists, trigger an alert
        if (hasItemWithValueTwoAndStatusU) {          
          if(isMobileView){
            setEditfc(false);
          } else {
            setEdit(false);
          }
          alert("This Entry Can Not Edit");
        }
      }
    }
    ////console.log("payload_config.field_mapping", payload_config.field_mapping);
    let Editfields = [];
    payload_config.field_mapping.map((field) => {
      if (field.isEdit === "1") {
        Editfields.push({
          fieldName: field.fieldname,
          field: field.BucketField,
          isError: "",
        });
      }
    });
    const updatedDefaultData = payload_config.field_mapping.map((item) => {
      const matchingItem = defaultpayload_config.field_mapping.find(
        (item1) => item1.BucketField === item.BucketField
      );
      if (matchingItem) {
        // If a matching item is found in data1, update the isRequired value
        return {
          ...item,
          isEdit: matchingItem.isEdit,
        };
      }
      // If no matching item is found, keep the original item
      return item;
    });
    let DisableEditfields = [];
    payload_config.field_mapping.map((field) => {
      if (field.isEditDisable === "1") {
        // if (field.datatype === "dropdownlist") {
        //   let oldvalue = updatedPayload[field.BucketField];
        //   let row_columnName = this.state.Reasonlist[field.BucketField].find(
        //     (item) => item.item_value === oldvalue
        //   );
        //   if (row_columnName !== undefined) {
        //     console.log("row_columnName", row_columnName.item_name);
        //     updatedPayload[field.BucketField] = row_columnName.item_name;
        //   }
        // }

        const matches = field.DisableOnFieldValue.match(/(\w+)(=|>|<)(\w+)/);
        if (matches) {
          const [, key, operator, value] = matches;
          let disablefield = [];
          DisableEditfields.push({
            fieldName: field.fieldname,
            field: field.BucketField,
            isError: "",
            DisableOnField: key,
            DisableOnFieldValue: value,
            DisableOperator: operator,
          });
          Editfields.forEach((dataItem) => {
            const matchingData1 = DisableEditfields.find(
              (data1Item) => data1Item.field === dataItem.field
            );
            if (matchingData1) {
              const {
                field,
                DisableOnField,
                DisableOnFieldValue,
                DisableOperator,
              } = matchingData1;
              // Assuming data2 is an object, iterate through its keys
              for (const key in updatedPayload) {
                let expression = `${updatedPayload[key]} ${DisableOperator} ${DisableOnFieldValue}`;
                try {
                  // Evaluate the expression using a safer alternative like Function constructor
                  let evaluateExpression = new Function(`return ${expression}`);
                  let result = evaluateExpression();
                } catch (error) {
                  console.error("Error evaluating expression:", error);
                }
                if (key == DisableOnField && eval(expression)) {
                  disablefield.push(field);
                  // Do something if the condition is met
                }
              }
            }
          });
          // set isEdit = 0 if any field is mark as disable on specific status
          updatedDefaultData.forEach((item) => {
            if (disablefield.includes(item.BucketField)) {
              item.isEdit = "0";
            }
          });
          setnewfieldEdit_mapping(updatedDefaultData);
        } else {
          console.log("No matches found.");
        }
      }
    });
  };
  const extractstatedataVariablesFromObject = (obj) => {
    if (typeof obj === "object" && obj !== null) {
      const result = {};
      for (const key in obj) {
        result[key] = extractstatedataVariablesFromObject(obj[key]);
      }
      return result;
    } else if (typeof obj === "string") {
      return extractVariables(obj);
    } else {
      return obj;
    }
  };
  const extractVariables = (str) => {
    const regex = /{{(.*?)}}/g;
    const matches = str.match(regex);
    return matches ? matches.map((match) => match.slice(2, -2)) : [str];
  };
  const getstatedata = (data) => {
    const result = extractstatedataVariablesFromObject(data);
    let replacementValues = {};
    result.map((cell) => {
      replacementValues[cell] = converttoarray(cell, payload);
    });
    let replacedFormrow;

    replacedFormrow = extractstatedataVariablesFroReplace(
      data,
      replacementValues
    );
    return replacedFormrow;
  };

  const extractstatedataVariablesFroReplace = (obj, replacementValues) => {
    if (typeof obj === "object" && obj !== null) {
      const result = {};
      for (const key in obj) {
        result[key] = extractstatedataVariablesFroReplace(
          obj[key],
          replacementValues
        );
      }
      return result;
    } else if (typeof obj === "string") {
      return replacestatedataVariables(obj, replacementValues);
    } else {
      return obj;
    }
  };

  const replacestatedataVariables = (str, replacementValues) => {
    const regex = /{{(.*?)}}/g;
    const replacedStr = str.replace(regex, (match, variable) => {
      return replacementValues[variable] || match;
    });
    return replacedStr;
  };

  const checkredirect = (RedirectData, RedirectCondition, BucketField) => {
    // Define a regular function
    // Extracting key, operator, and value from RedirectCondition
    const conditions = RedirectCondition.split(",");
    let allConditionsMatch = true; // For AND logic

    conditions.forEach((condition) => {
      const matches = condition.match(/([\w.]+)(==|!=|>|<)('.*'|\w+)/);
      if (matches) {
        const [, key, operator, value] = matches;
        let conditionResult = false;
        let keyValue, valuedata;

        // Get the key value from the payload
        keyValue = converttoarray(key, payload);
        valuedata = value.replace(/'/g, ""); // Remove quotes if present

        // Convert to number if both keyValue and valuedata are numeric
        if (!isNaN(keyValue) && !isNaN(valuedata)) {
          keyValue = Number(keyValue);
          valuedata = Number(valuedata);
        }

        switch (operator) {
          case "!=":
            conditionResult = keyValue != valuedata;
            break;
          case "==":
            conditionResult = keyValue == valuedata;
            break;
          case ">":
            conditionResult = keyValue > valuedata;
            break;
          case "<":
            conditionResult = keyValue < valuedata;
            break;
          default:
            conditionResult = false;
        }

        // For AND logic, all conditions must be true
        allConditionsMatch = allConditionsMatch && conditionResult;
      } else {
        allConditionsMatch = false;
      }
    });

    // Set RedirectConditionWork based on the required logic
    let RedirectConditionWOrk = allConditionsMatch ? 1 : 0;

    // Checking condition
    if (RedirectConditionWOrk == 1) {
      // Extracting object from RedirectData
      let obj = RedirectData.toString()
        .split("requiredata:")[1]
        .split(",");
      // Getting state data
      const results = obj.map((obj, key) => {
        //const key = Object.keys(obj)[0];
        const statedata = getstatedata(obj);
        return { [key]: JSON.parse(statedata) }; // Return the updated object
      });

      let statedata = results; //getstatedata(obj);
      let urldata;
      let match = RedirectData.match(/path:'(.*?)'/);
      if (match && match[1]) {
        urldata = match[1];
      }
      let urlpath = getstatedata(urldata);
      const convertedData = urlpath.replace(/{|}|"/g, "");

      if (statedata) {
        let parsedData;

        try {
          parsedData = statedata; //JSON.parse(statedata);
        } catch (error) {
          console.error("Error parsing data:", error);
          return null;
        }

        // Check if parsedData is an array
        let modifiedArray = {};
        if (Array.isArray(parsedData)) {
          parsedData.forEach((obj) => {
            for (let key in obj) {
              const innerArray = obj[key];
              innerArray.forEach((innerObj) => {
                for (let innerKey in innerObj) {
                  modifiedArray[innerKey] = innerObj[innerKey];
                }
              });
            }
          });
        }

        const url = new URL(window.location.href);
        const baseUrl = url.origin;

        // Returning JSX
        return (
          <a
            href="javascript:void(0)"
            onClick={() => {
              checkUrlExists(baseUrl + "/" + convertedData).then(
                (urlExists) => {
                  if (urlExists == false) {
                    const state = {
                      api_token: api_token,
                      modifiedArray,
                      // ...parsedData.reduce((acc, item) => {
                      //   const key = Object.keys(item)[0];
                      //   acc[key] = item[key];
                      //   return acc;
                      // }, {}),
                    };

                    props.history.push({
                      pathname: "../" + convertedData,
                      // RedirectData.toString()
                      //   .split("path:")[1]
                      //   .split(",")[0]
                      //   .replace(/'/g, "")
                      //   .trim(),
                      state: state,
                    });
                  }
                }
              );
            }}
          >
            {converttoarray(BucketField, payload)}
          </a>
        );
      }
    }
    // Returning null if condition is not met
    return converttoarray(BucketField, payload);
  };

  const handleDownload = (filePath) => {
    // Implement your download logic here
    // For example, using a download link:
    const link = document.createElement("a");
    link.href = filePath; // Replace with the actual file URL or download endpoint
    link.download = filePath.split("/").pop(); // Extract filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const checkfields = (cell, reqedit="n") => {

    if (cell["picklast"] && cell["picklast"] == 1) {
      let updatedString;
      if (cell["pickindex"] && cell["pickindex"] != "") {
        updatedString = cell["BucketField"].replace(
          /:\d+\./,
          ":" + cell["pickindex"] + "."
        );
      } else {
        const [arrayPath, restPath] = cell["BucketField"].split(":");
        if (payload[arrayPath]) {
          //let number = (getarray - 1);
          updatedString = cell["BucketField"].replace(
            /:\d+\./,
            ":" + parseInt(payload[arrayPath].length - 1) + "."
          );
        }
      }
      if (updatedString) {
        cell["BucketField"] = updatedString;
      }
    }

    const isEditCondition = edit && cell["isEdit"] === "1";
    const isVisibleCondition = reqedit == 'y' && cell["isVisible"] == "1";
    return (isEditCondition || isVisibleCondition) ? (
      cell["datatype"] === "productlist" ? (
        <>
          <a
            href="javascript:void(0)"
            disabled={disabled}
            onClick={() => {
              {
                !disabled &&
                  showproductdata(
                    converttoarray(cell["BucketField"], payload),
                    cell["productlist"],
                    cell["productid"],
                    cell["tenure"]
                  );
              }
            }}
          >
            {converttoarray(cell["BucketField"], payload)}
          </a>
        </>
      ) : // : cell["datatype"] === "Uploadfile" ? (
      //   <>
      //     <Upload
      //       className="form-control"
      //       //fileList={this.state.upload_filepath}
      //       //onChange={(e) => this.getfileDetail(e, cell)}
      //       beforeUpload={(e) => beforeUpload(e, cell)}
      //       maxCount={1}
      //       onRemove={() => onRemove(cell)}
      //     >
      //       <Button icon={<UploadOutlined />}>Upload File</Button>
      //     </Upload>

      //     {/* {this.state[cell["BucketField"]] &&
      //       this.state[cell["BucketField"]].map((file) => (
      //         <div key={file.uid} style={{ marginBottom: "8px" }}>
      //           <span>
      //             {file.name} - {(file.size / 1024).toFixed(2)} KB
      //           </span>
      //         </div>
      //       ))} */}
      //     <span>Allow {cell["UploadType"]}</span>
      //   </>
      // )
      cell["datatype"] === "block" ? (
        <>
          <a>{cell["fieldname"]}</a>
        </>
      ) : cell["datatype"] === "Downloadfile" ? (
        cell["isupload"] == "1" ? (
          <>
            {/* <Upload
              className="form-control"
              //fileList={this.state.upload_filepath}
              //onChange={(e) => this.getfileDetail(e, cell)}
              beforeUpload={(e) => beforeUpload(e, cell)}
              maxCount={1}
              onRemove={() => onRemove(cell)}
            >
              <Button icon={<UploadOutlined />}>Upload File</Button>
            </Upload>
            <span>Allow {cell["UploadType"]}</span> */}
            {console.log("fileUploadData--", fileUploadData)}
            <a
              href="javascript:void(0)"
              onClick={() => {
                {
                  showfileupload(cell);
                }
              }}
            >
              Upload
            </a>
            {fileUploadData &&
              fileUploadData.map((item, index) => (
                <div key={index}>
                  {/* <h3>{item.upload_document_type_name}</h3> */}
                  <ul>
                    {item.files.length > 0 &&
                      item.files.map((file, fileIndex) => (
                        <>
                          {file.filelistdata && (
                            <li key={fileIndex}>
                              {file.filelistdata.map(
                                (fileData, fileDataIndex) => (
                                  <a
                                    href="#"
                                    key={fileDataIndex}
                                    onClick={() =>
                                      handleDownload(fileData.name)
                                    }
                                  >
                                    {fileData.name}
                                  </a>
                                )
                              )}
                            </li>
                          )}
                        </>
                      ))}
                  </ul>
                </div>
              ))}
          </>
        ) : converttoarray(cell["BucketField"], updatedPayload) ? (
          <button
            className="btn btn-primary"
            onClick={() =>
              downloadfile(
                converttoarray(cell["BucketField"], updatedPayload),
                cell
              )
            }
          >
            Download
          </button>
        ) : (
          "File Not Found"
        )
      ) : cell["datatype"] === "group_id" ? (
        <div className="d-flex">
          <select
            className="form-control"
            style={{
              width: 120,
            }}
            onChange={(event) => {
              handlePayloadChange(event.target.value, cell["BucketField"]);
            }}
            value={converttoarray(cell["BucketField"], updatedPayload)}
          >
            <option value="">Select</option>
            {updategroupid &&
              updategroupid.map((grpid) => (
                <option value={grpid.group_id}>{grpid.group_id}</option>
              ))}
          </select>
          {/* <button
      className="btn btn-primary ml-1"
      onClick={() => adduniqueid()}
    >
      +
    </button> */}
        </div>
      ) : cell["datatype"] === "DCB" ? (
        <TextArea
          autoSize={true}
          readOnly={!edit}
          bordered={edit}
          style={{
            width: 150,
          }}
          maxLength={cell["FieldLength"]}
          value={
            edit
              ? converttoarray(cell["BucketField"], updatedPayload)
              : // :
                // cell["datatype"] === "boolean"
                // ? converttoarray(cell["BucketField"], payload) == 1
                //   ? "Yes"
                //   : "No"
                converttoarray(cell["BucketField"], updatedPayload) &&
                JSON.stringify(
                  converttoarray(cell["BucketField"], updatedPayload)
                ).replace(/\"/g, "")
          }
          onChange={(event) => {
            handlePayloadChange(event.target.value, cell["BucketField"]);
          }}
        />
      ) : cell["datatype"] === "jsondata" ? (
        <>
          <a>{cell["BucketField"]}</a>
        </>
      ) : cell["datatype"] === "dropdownlist" ? (
        <>
          <Dropdownlist
            value={
              converttoarray(cell["BucketField"], updatedPayload) &&
              JSON.stringify(
                converttoarray(cell["BucketField"], updatedPayload)
              ).replace(/\"/g, "")
            }
            style={{
              width: 120,
            }}
            bucketfield={cell}
            isedit={true}
          />
        </>
      ) : cell["datatype"] === "boolean" ? (
        <select
          className="form-control"
          value={converttoarray(cell["BucketField"], updatedPayload)}
          style={{
            width: 120,
          }}
          onChange={(event) => {
            handlePayloadChange(event.target.value, cell["BucketField"]);
          }}
        >
          <option value="">Select</option>
          <option value="1">Yes</option>
          <option value="0">No</option>
        </select>
      ) : cell["datatype"] === "textbox" ? (
        <>
        <TextArea
          autoSize={true}
          readOnly={!edit}
          bordered={edit}
          style={{
            width: 120,
          }}
          maxLength={cell["FieldLength"]}
          value={
            edit
              ? converttoarray(cell["BucketField"], updatedPayload)
              : // :
                // cell["datatype"] === "boolean"
                // ? converttoarray(cell["BucketField"], payload) == 1
                //   ? "Yes"
                //   : "No"
                converttoarray(cell["BucketField"], payload) &&
                JSON.stringify(
                  converttoarray(cell["BucketField"], payload)
                ).replace(/\"/g, "")
          }
          onChange={(event) => {
            handlePayloadChange(event.target.value, cell["BucketField"]);
          }}
          onBlur={()=> checkifverify(cell["BucketField"])}
        />
        {verify_data["msg_verify"] ? verify_data["msg_verify"] : verify_data["sel_verify"] && verify_data["sel_verify"][0].value}
        </>
      ) : (
        cell["datatype"] === "date" && (
          <input
            type="date"
            className="form-control"
            value={
              edit
                ? moment(
                    converttoarray(cell["BucketField"], updatedPayload)
                  ).format("YYYY-MM-DD")
                : // :
                  // cell["datatype"] === "boolean"
                  // ? converttoarray(cell["BucketField"], payload) == 1
                  //   ? "Yes"
                  //   : "No"
                  converttoarray(cell["BucketField"], updatedPayload) &&
                  JSON.stringify(
                    converttoarray(cell["BucketField"], updatedPayload)
                  ).replace(/\"/g, "")
            }
            onChange={(event) => {
              handlePayloadChange(event.target.value, cell["BucketField"]);
            }}
          />
        )
      )
    ) : (
      // <TextArea
      //   autoSize
      //   readOnly={true}
      //   bordered={false}
      //   //bordered={edit}
      //   value={JSON.stringify(
      //     converttoarray(cell["BucketField"], payload)
      //   )}
      // />
      <span style={{
        ...(cell["color"] && { color: cell["color"] }),
        ...(cell["boldText"] == "1" && { fontWeight: 800 }),
      }}>
        {cell["datatype"] === "productlist" ? (
          <>
            <a
              href="javascript:void(0)"
              disabled={disabled}
              onClick={() => {
                {
                  !disabled &&
                    showproductdata(
                      converttoarray(cell["BucketField"], payload),
                      cell["productlist"],
                      cell["productid"],
                      cell["tenure"]
                    );
                }
              }}
            >
              {converttoarray(cell["BucketField"], payload)}
            </a>
          </>
        ) : cell["datatype"] === "date" ? (
          converttoarray(cell["BucketField"], updatedPayload) &&
          isUnixTimestamp(
            converttoarray(cell["BucketField"], updatedPayload)
          ) ? (
            moment(
              moment.unix(converttoarray(cell["BucketField"], updatedPayload))
            ).format(cell["displaydateformat"]?cell["displaydateformat"] : "YYYY-MM-DD HH:mm:ss")
          ) : isValidDate(
              converttoarray(cell["BucketField"], updatedPayload)
            ) ? (
            moment(converttoarray(cell["BucketField"], updatedPayload)).format(
              cell["displaydateformat"]?cell["displaydateformat"] : "YYYY-MM-DD"
            )
          ) : (
            JSON.stringify(
              converttoarray(cell["BucketField"], updatedPayload)
            ).replace(/\"/g, "")
          )
        ) : cell["datatype"] === "block" ? (
          <>
            <a
              href="javascript:void(0)"
              onClick={() => {
                let _updatedPayload = {
                  ...payload,
                };
                
                if(isMobileView){
                  setupdpayloadfc(_updatedPayload);
                } else {
                  setUpdatedPayload(_updatedPayload);
                }
                showblockdata(
                  converttoarray(cell["BucketField"], updatedPayload),
                  cell["fieldname"],
                  cell["BucketField"]
                );
              }}
            >
              {cell["fieldname"]}
            </a>
          </>
        ) : cell["datatype"] === "Downloadfile" ? (
          converttoarray(cell["BucketField"], payload) ? (
            <button
              className="btn btn-primary"
              onClick={() =>
                downloadfile(converttoarray(cell["BucketField"], payload), cell)
              }
            >
              Download
            </button>
          ) : (
            "File Not Found"
          )
        ) : cell["datatype"] === "DCB" ? (
          <>
            <div
              class="table-elipse"
              data-toggle="collapse"
              data-target={"#" + rowindex}
              onClick={() => {
                handleRowClick(payload);
                updaterowindex(rowindex);
              }}
            >
              <span className="exprowbtn">+</span>
              {converttoarray(cell["BucketField"], payload) &&
                JSON.stringify(
                  converttoarray(cell["BucketField"], payload)
                ).replace(/\"/g, "")}
            </div>
          </>
        ) : cell["datatype"] === "jsondata" ? (
          <>
            <a
              className="text-primary"
              onClick={() => {
                setisPayloadView(true);
                setPayloadViewData(
                  converttoarray(cell["BucketField"], payload) &&
                    JSON.stringify(converttoarray(cell["BucketField"], payload))
                );
              }}
            >
              {cell["BucketField"].split(".").pop()}
            </a>
          </>
        ) : cell["datatype"] === "boolean" ? (
          converttoarray(cell["BucketField"], payload) &&
          JSON.stringify(converttoarray(cell["BucketField"], payload)).replace(
            /\"/g,
            ""
          ) == 1 ? (
            "Yes"
          ) : (
            "No"
          )
        ) : cell["datatype"] === "dropdownlist" ? (
          <>
            <Dropdownlist
              value={
                converttoarray(cell["BucketField"], payload) &&
                JSON.stringify(
                  converttoarray(cell["BucketField"], payload)
                ).replace(/\"/g, "")
              }
              bucketfield={cell}
              isedit={false}
            />
          </>
        ) : cell["datatype"] === "image" ? (
          <>
            <button
              className="btn btn-primary"
              onClick={(e) =>
                viewImage(converttoarray(cell["BucketField"], payload))
              }
            >
              View
            </button>
          </>
        ) : cell["isRedirect"] == 1 ? ( // Check if cell["isRedirect"] equals 1
          checkredirect(
            cell["RedirectData"],
            cell["RedirectCondition"],
            cell["BucketField"]
          )
        ) : // : cell["addapi"] == 1 ? (
        //   <>
        //     <span
        //       className="payload-openmodal"
        //       onClick={() =>
        //         showdata(cell["selectapi"], cell["tabledata"])
        //       }
        //     >
        //       {converttoarray(cell["BucketField"], payload) &&
        //         JSON.stringify(
        //           converttoarray(cell["BucketField"], payload)
        //         ).replace(/\"/g, "")}
        //     </span>
        //   </>
        // )

        cell["getfromapi"] && cell["getfromapi"] == 1 ? (           
          isLoadingname?"Loading...": names[cell.BucketField]
        ) : (
          converttoarray(cell["BucketField"], payload) &&
          JSON.stringify(converttoarray(cell["BucketField"], payload)).replace(
            /\"/g,
            ""
          )
        )}
        {/* {converttoarray(
    "payload.payment.entity.notes.notes_key_2",
    payload
  )} */}
      </span>
    );
  };
  const isUnixTimestamp = (input) => {
    // Check if input is a number and within Unix timestamp range
    const isNumeric = !isNaN(input);
    const isInt = Number.isInteger(Number(input));
    const isValidRange = Number(input) >= 0 && Number(input) <= 2147483647; // Valid Unix timestamp range in seconds

    return isNumeric && isInt && isValidRange;
  };
  const handleCancel = () => {
    setisOpenModal(false);
  };
  const isValidDate = (input) => {
    // Use Moment.js to check if the input is a valid date
    return moment(input, moment.ISO_8601, true).isValid();
  };
  const validateForm = (ModalForm) => {
    let isValid = true;
    ModalForm.map((field, index) => {
      const list = [...ModalForm];
      if (field.isRequired == "1") {
        if (!field.value) {
          isValid = false;
          list[index]["isError"] = "1";
        }
      } else {
        list[index]["isError"] = "";
      }
      setModalForm(list);
    });
    return isValid;
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...ModalForm];
    list[index]["value"] = value;
    setModalForm(list);
  };
  const handleCancelError = () => {
    setisError(false);
  };

  const checkUrlExists = async (url) => {
    try {
      const response = await customFetch(url);
      if (response.ok) {
        return false; // No 404 error, real URL
      } else {
        return true; // 404 error occurred
      }
    } catch (error) {
      console.error("Error checking for 404:", error);
      return true; // Error occurred, assume 404
    }
  };

  const handleBlur = (event, minmaxlength, fieldname, bucketField) => {
    const minLength = minmaxlength;
    const maxLength = minmaxlength;
    const inputLength = event.target.value.length;

    if (inputLength < minLength) {
      //alert(`${fieldname} must be at least ${minLength} characters long.`);
      setErrorMessage({
        ...ErrorMessage,
        [bucketField]: `${fieldname} must be at least ${minLength} characters long.`,
      });
      setDisableBtn(true);
    } else {
      setErrorMessage("");
      setDisableBtn(false);
    }
  };
  return (
    <>
      <Modal
        open={isImageView}
        onCancel={handleCancelimage}
        footer={[]}
        width="auto"
      >
        {ImageView ? mimeType.includes("image") ?<Image
        width="100%"
        src={ImageView}
        alt="Image Preview"
        onError={() => setImageView(null)}
      />:mimeType.includes("pdf")&& <iframe src={ImageView} width="100%" height="500px" /> : "File Not Found"}
      </Modal>
      {/* {view_for=="mobhead" && (
        // <Modal
        //   open={isExprow}
        //   onCancel={() => setisExprow(false)}
        //   footer={[]}
        //   width="auto"
        //   id={selectedrowindex}
        // >
        //   <div id={selectedrowindex} class="cell-1 row-child">
        //     <DetailComponent
        //       detailData={expandedRow}
        //       payload_config={subpayload_config}
        //       api_token={api_token}
        //       effective_designation_id={effective_designation_id}
        //       fetch_onload={true}
        //       storesubdata={storesubdata}
        //       selectedrowindex={selectedrowindex}
        //       filtered_condition={filtered_condition}
        //       sub_data={sub_data||[]}
        //       removedCheckboxes={removedCheckboxes}
        //     />
        //   </div>
        // </Modal>
      )} */}
      <Modal
        open={isFileUpload}
        onCancel={() => {
          setisFileUpload(false);
        }}
        width="auto"
        onOk={() => {
          setisFileUpload(false);
        }}
        // okButtonProps={{
        //   disabled: !selectedProduct || errorMsg,
        // }}
      >
        <div className="row">
          {fileUploadData &&
            fileUploadData.map((filedata, index) => (
              <>
                <div className="col-md-5">
                  {filedata.upload_document_type_name}{" "}
                  {filedata.UploadRequired == 1 && <>*</>}
                </div>
                <div className="col-md-5">
                  {filedata.files.map((file, fileIndex) => (
                    <div key={fileIndex}>
                      {/* <input
                type="file"
                onChange={(e) => handleFileChange(index, fileIndex, e)}
              /> */}
                      <Upload
                        className="form-control"
                        fileList={file.filelistdata || []}
                        onChange={(e) =>
                          handleFileChange(index, fileIndex, e, filedata)
                        }
                        beforeUpload={(e) => beforeUpload(e, filedata)}
                        maxCount={1}
                        onRemove={() => onRemove(filedata)}
                      >
                        <Button icon={<UploadOutlined />}>Upload File</Button>
                      </Upload>
                      <span>Allow {filedata.UploadType}</span>
                      {filedata.UploadIsMultiple === "1" && (
                        <>
                          <button
                            className="btn btn-primary mr-1"
                            type="button"
                            onClick={() => handleAddFile(index)}
                          >
                            +
                          </button>
                          {filedata.files.length > 1 && (
                            <button
                              className="btn btn-danger"
                              type="button"
                              onClick={() => handleRemoveFile(index, fileIndex)}
                            >
                              -
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </>
            ))}
        </div>
      </Modal>
      <Modal
        open={isProductList}
        onCancel={() => setisProductList(false)}
        width="auto"
        onOk={() => {
          setupdateAmount(1);
          setisProductList(false);
        }}
        okButtonProps={{
          disabled: !selectedProduct || errorMsg,
        }}
      >
        <div className="row">
          {productlist && productlist.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Amount</th>
                  <th>EMI</th>
                  <th>Tenure</th>
                </tr>
              </thead>
              <tbody>
                {productlist.map((product) => (
                  <tr>
                    <td>
                      <input
                        type="radio"
                        value={
                          product.product_id +
                          "~" +
                          product.amount +
                          "~" +
                          product.tenure
                        }
                        checked={
                          product.product_id +
                            "~" +
                            product.amount +
                            "~" +
                            product.tenure ==
                          selectedProduct
                        }
                        onChange={(e) => setselectedProduct(e.target.value)}
                      />
                    </td>
                    <td>Rs. {product.amount.toLocaleString("en-IN")}</td>
                    <td>Rs. {product.emi.toLocaleString("en-IN")} / Month</td>
                    <td>{product.tenure} Month</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            errorMsg
          )}
        </div>
      </Modal>
      <Modal
        open={isFamily}
        onCancel={handleCancelfamily}
        footer={[]}
        width="auto"
      >
        <table className="table">
          <thead>
            <tr>
              <th>Consumername</th>
              <th>Reference Number</th>
            </tr>
          </thead>
          <tbody>
            {familyData &&
              familyData.map((fdata) => (
                <tr>
                  <td>{fdata.ConsumerName}</td>
                  <td>{fdata.ReferenceNumber}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </Modal>
      <Modal
        open={isPayloadView}
        onCancel={handleCanceljson}
        footer={[]}
        width="auto"
      >
        <div>
          <pre className="showjson">{PayloadViewData}</pre>
        </div>
      </Modal>
      <Modal
        open={openFileDownloadModal}
        onCancel={() => setOpenFileDownloadModal(false)}
        footer={[]}
        width="auto"
      >
        <div>No file found!</div>
      </Modal>
      <Modal
        open={isAdd}
        onCancel={() => setisAdd(false)}
        onOk={() => {
          handlePayloadChange(blockData, DeleteBucketField);
          setisAdd(false);
        }}
        okButtonProps={{
          disabled: !blockData,
        }}
        width="auto"
      >
        <div>
          <TextArea
            className="form-control"
            onChange={(e) => {
              try {
                const parsedData = JSON.parse(e.target.value);
                setblockData(parsedData);
                setisblockDataInput(true);
              } catch (error) {
                setblockData("");
                setisblockDataInput(false);
              }
            }}
            value={blockData && JSON.stringify(blockData)}
            rows={15}
          />
        </div>
      </Modal>
      <Modal
        open={isBlock}
        onCancel={handleCancelblock}
        footer={[]}
        width="auto"
      >
        <div>
          {blockData ? (
            <>
              <ReactJson
                src={blockData}
                onEdit={
                  onEdit
                    ? (e) => {
                        console.log(e);
                        //this.setState({ src: e.updated_src });                        
                        if(isMobileView){
                          setupdpayloadfc(e.updated_src);                          
                        } else {
                          setUpdatedPayload(e.updated_src);
                        }
                      }
                    : false
                }
              />
              {edit && isblockDataInput == false && (
                <div className="text-right">
                  <button
                    className="btn btn-danger"
                    onClick={() => deleteblockdelete(DeleteBucketField)}
                  >
                    <DeleteOutlined />
                  </button>
                </div>
              )}
            </>
          ) : (
            edit && (
              <>
                <button
                  className="btn btn-primary mt-4 mr-4"
                  onClick={() => setisAdd(true)}
                >
                  <FileAddOutlined />
                </button>
              </>
            )
          )}
        </div>
        {/* <div>
          {blockData &&
            Object.entries(blockData).map(([group, fields]) => (
              <div key={group}>
                <h2>{group}</h2>
                <div className="row">
                  {fields.map(({ BucketField, value }) => (
                    <div className="col-md-4" key={BucketField}>
                      <strong>{BucketField}:</strong> {JSON.stringify(value)}
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
         */}
      </Modal>
      <Modal
        //title="Title"
        open={isShowData}
        onCancel={handleCanceldata}
        footer={[]}
        width="auto"
      >
        <div className="row">
          {/* {ShowData &&
            ShowData.map((userdata) => (
              <div className="form-group col-md-4">
                {userdata.Branches.Branch}
              </div>
            ))} */}
          {ShowData &&
            ShowData.Branches.map((userdata, index) => {
              let getapiconfig = JSON.parse(payload_config.get_api_mapping);
              return getapiconfig[0].outputdata.map((apiconfig, i) => {
                return (
                  <>
                    <div className="form-group col-md-4">
                      <label className="font-weight-bold">
                        {apiconfig.outputkey}
                      </label>
                      <div>{userdata[apiconfig.outputvalue]}</div>
                    </div>
                  </>
                );
              });
            })}
          {ShowData &&
            ShowData.Branches.map((userdata, index) => {
              let getapiconfig = JSON.parse(payload_config.get_api_mapping);
              let splitrowdata = getapiconfig[0].tabledata[0].tablevalue.split(
                "."
              );
              let splitdata = splitrowdata[0];
              return (
                <>
                  <div className="form-group col-md-12">
                    <table className="table table-hove text-center">
                      <thead>
                        <tr>
                          {getapiconfig[0].tabledata.length > 0 &&
                            getapiconfig[0].tabledata.map((tabledata) => {
                              return <td>{tabledata.tablekey}</td>;
                            })}
                        </tr>
                      </thead>
                      <tbody>
                        {/* {getapiconfig[0].tabledata.length > 0 &&
                          getapiconfig[0].tabledata.map((tabledata, i) => {
                            let splitdata = tabledata.tablevalue.split(".");
                            //console.log("i", splitdata);
                            return (
                              <tr>
                                {userdata[splitdata[0]].map(
                                  (lastransaction) => {
                                    return (
                                      <td>{lastransaction[splitdata[1]]}</td>
                                    );
                                  }
                                )}
                              </tr>
                            );
                          })} */}
                        {getapiconfig[0].tabledata.length > 0 &&
                          userdata[splitdata].map((split, i) => {
                            //let splitdata = tabledata.tablevalue.split(".");
                            //console.log("i", splitdata);
                            return (
                              <tr key={i}>
                                {getapiconfig[0].tabledata.map(
                                  (lastransaction) => {
                                    let tablevalue = lastransaction.tablevalue.split(
                                      "."
                                    );
                                    return <td>{split[tablevalue[1]]}</td>;
                                  }
                                )}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </>
              );
            })}
        </div>
      </Modal>
      <Modal
        //title="Title"
        open={isOpenModal}
        onOk={() => {
          let validation = validateForm(ModalForm);

          if (validation) {
            if (ActionFormType == "formdata") {
              actionHandler(actionValue, ModalForm, ActionFormType);
            }
            if (ActionFormType == "formrow") {
              actionHandler(actionValue, replacedFormrow, ActionFormType);
            }

            setisOpenModal(false);
          }
        }}
        // confirmLoading={confirmLoading}
        onCancel={handleCancel}
        width={1000}
      >
        <div className="row">
          {ActionFormType == "formdata" &&
            ModalForm &&
            ModalForm.map((field, index) => (
              <>
                <div className="col-3">
                  <label>{field.fieldlabel}</label>
                  {field.datatype === "textbox" ? (
                    <>
                      <input
                        required={field.isRequired == 1}
                        className="form-control"
                        readOnly={field.isEdit == 0}
                        name={field.field}
                        value={field.value}
                        placeholder={field.fieldlabel}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                    </>
                  ) : field.datatype === "boolean" ? (
                    <select
                      className="form-control"
                      value={field.value}
                      disabled={field.isEdit == 0}
                      style={{
                        width: 120,
                      }}
                      onChange={(e) => handleInputChange(e, index)}
                    >
                      <option value="">Select</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  ) : field.datatype === "date" ? (
                    <input
                      type="date"
                      required={field.isRequired == 1}
                      className="form-control"
                      readOnly={field.isEdit == 0}
                      name={field.field}
                      value={
                        field.value
                          ? moment(field.value).format("YYYY-MM-DD")
                          : ""
                      }
                      placeholder={field.field}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  ) : (
                    <input
                      required={field.isRequired == 1}
                      className="form-control"
                      readOnly={field.isEdit == 0}
                      name={field.field}
                      value={field.value}
                      placeholder={field.fieldlabel}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  )}
                  {field.isError === "1" && (
                    <span className="errmsg">
                      {field.fieldlabel} is required
                    </span>
                  )}
                </div>
              </>
            ))}
          {ActionFormType == "formrow" && (
            // replacementValues.length > 0 ? (
            //   <></>
            // ) : (
            //   <div>Click OK to Perform Action</div>
            // )
            <div>Click OK to Perform Action</div>
          )}
        </div>
      </Modal>
      <Modal
        //title="Title"
        open={isError}
        onOk={async () => {
          setEditLoading(true);
          let response = await editHandler(
            payload_config["bucket_name"],
            payload["key"]
          );
          setEditLoading(false);
          if (response === true) {
            if(isMobileView){
              setEditfc(false);
            } else {
              setEdit(false);
            }
            getPayloads();
            setisError(false);
          } else {
            if(isMobileView){
              setEditfc(true);
            } else {
              setEdit(true);
            }
          }
        }}
        onCancel={handleCancelError}
        width={1000}
      >
        <div className="row">
          {PayloadRequired &&
            PayloadRequired.map((cell) => (
              <div className="col-md-2">
                <label>{cell["fieldname"]}</label>
                {checkfields(cell,"y")}
              </div>
            ))}
        </div>
        <div className="mt-2">
          <span className="errmsg">
            Please Update Values for {isErrorValue}
          </span>
        </div>
      </Modal>
      {view_type == "table" && (
        <tr key={rowindex}>
          {updateUniqueData.length > 0 || payload_config.view_type == "AV" ? (
            <td style={{backgroundColor: color}}>
              <>
                {updateUniqueData.length > 0 && (
                  <input
                    //checked={CheckAll}
                    type="checkbox"
                    className="mobilecheckbox"
                    checked={isChecked}
                    disabled={defaultCheckbox}
                    value={
                      payload_config.field_mapping.filter(
                        (field) => field.useasinput === "1"
                      ) == true
                        ? payload[
                            payload_config.field_mapping
                              .filter((field) => field.useasinput === "1")
                              .map((field) => field.BucketField)
                          ]
                        : payload_config.payload_type == "M"
                        ? payload["_id.$oid"]
                          ? payload["_id.$oid"]
                          : payload._id.$oid
                        : payload["key"]
                    }
                    onChange={(e) => selectedMassUpdateData(e.target.value)}
                  />
                )}
                {payload_config.view_type == "AV" && payload_config.isexpand == "1" && (
                  <div
                    class="table-elipse"
                    data-toggle="collapse"
                    data-target={"#" + rowindex}
                    onClick={() => {
                      handleRowClick(payload);
                      updaterowindex(rowindex);
                    }}
                  >
                    {selectedrowindex == rowindex ? (
                      <span className="exprowbtn">-</span>
                    ) : (
                      <span className="exprowbtn">+</span>
                    )}
                  </div>
                )}
              </>
            </td>
          ) : (
            ""
          )}
          {(newfieldEdit_mapping || payload_config.field_mapping).map(
            (cell, tdindex) => {
              return (
                <>
                  {cell["isVisible"] == "1" && (
                    <td key={tdindex} style={{backgroundColor: color}}>{(() => {
                      // Ensure conditions are met
                      if (cell.addIcon === "1" &&
                        cell.iconPosition === "PF") {
                        // Dynamically get the icon component
                        const IconComponent = Icons[cell.icontoshow];
                        // Return the icon if it exists
                        const iconColor = cell.color || "black";
                        return IconComponent ? <IconComponent style={{ color: iconColor }}/> : null;
                      }
                      return null;
                    })()}
                    {/* {checkfields(cell)} */}
                    {(() => {
          const checkResult = checkfields(cell);

                        // Extract the numeric value from the returned JSX
                        const numericValue =
                          checkResult &&
                          checkResult.props &&
                          checkResult.props.children
                            ? parseFloat(checkResult.props.children)
                            : NaN;

                        // Apply formatting conditionally
                        if (
                          cell.currencyFormat == "1" &&
                          !isNaN(numericValue)
                        ) {
                          return (
                            <span style={checkResult.props.style}>
                              {numericValue.toLocaleString("en-IN")}
                            </span>
                          );
                        }

                        // Return the original checkResult if no formatting is needed
                        return checkResult;
                      })()}

                    {(() => {
                      // Ensure conditions are met
                      if (cell.addIcon === "1" &&
                        cell.iconPosition === "SF") {
                        // Dynamically get the icon component
                        const IconComponent = Icons[cell.icontoshow];
                        // Return the icon if it exists
                        const iconColor = cell.color || "black";
                        return IconComponent ? <IconComponent style={{ color: iconColor }}/> : null;
                      }
                      return null;
                    })()}
                    </td>
                  )}
                </>
              );
            }
          )}
          {/* <td>{moment(moment.unix(payload["created_at"])).fromNow()}</td> */}
          <td style={{backgroundColor: color}}>
            <div className="payload_view_table_actions">
              <Actions
                disabled={disabled}
                defaultValue=""
                payload_config={payload_config}
                actionHandler={actionHandler}
                editHandler={editHandler}
                payload={payload}
                edit={edit}
                setEdit={setEdit}
                setEditfc={setEditfc}                
                getPayloads={getPayloads}
                DeletePayload={DeletePayload}
                DeleteMongoPayload={DeleteMongoPayload}
                updatedPayload={updatedPayload}
                effective_designation_id={effective_designation_id}
                converttoarray={converttoarray}
                newfield_mapping={newfield_mapping}
                chkpayloadrequired={chkpayloadrequired}
                generate_modaldata={generate_modaldata}
                ActionFormType={ActionFormType}
                ModalForm={ModalForm}
                replacedFormrow={replacedFormrow}
                replacementValues={replacementValues}
                setModalForm={setModalForm}
                DisableBtn={DisableBtn}
                setDisableBtn={setDisableBtn}
                PayloadRequired={PayloadRequired}
                validatepayloaddata={validatepayloaddata}
                isError={isError}
                setisError={setisError}
                isErrorValue={isErrorValue}
                setisErrorValue={setisErrorValue}
                actionValue={actionValue}
                setisOpenModal={setisOpenModal}
                setactionValue={setactionValue}
                editLoading={editLoading}
                setEditLoading={setEditLoading}
                isVisibleEdit={isVisibleEdit}
                isMobileView={isMobileView}
              />
            </div>
          </td>
        </tr>
      )}

      {view_type == "mobile" && (
        <>         
          {JSON.parse(grid_position.fielddata).showLabel == "1" && (
            <>{JSON.parse(grid_position.fielddata).fieldname} :</>
          )}


    {(() => {
      // Parse field data
      const fieldData = JSON.parse(grid_position.fielddata);
    
      // Ensure conditions are met
      if (JSON.parse(grid_position.fielddata).addIcon === "1" &&
      JSON.parse(grid_position.fielddata).iconPosition === "PF") {
        // Dynamically get the icon component
        const IconComponent = Icons[JSON.parse(grid_position.fielddata).icontoshow];
        // Return the icon if it exists
        const iconColor = JSON.parse(grid_position.fielddata).color || "black";
        return IconComponent ? <IconComponent style={{ color: iconColor }}/> : null;
      }
      return null;
    })()}

          {/* {checkfields(JSON.parse(grid_position.fielddata), edit)} */}
          {(() => {
            const checkResult = checkfields(
              JSON.parse(grid_position.fielddata),
              edit
            );

            // Extract the numeric value from the returned JSX
            const numericValue =
              checkResult && checkResult.props && checkResult.props.children
                ? parseFloat(checkResult.props.children)
                : NaN;

            // Apply formatting conditionally
            if (
              JSON.parse(grid_position.fielddata).currencyFormat == "1" &&
              !isNaN(numericValue)
            ) {
              return (
                <span style={checkResult.props.style}>
                  {numericValue.toLocaleString("en-IN")}
                </span>
              );
            }

            // Return the original checkResult if no formatting is needed
            return checkResult;
          })()}

    {(() => {
        // Parse field data
        const fieldData = JSON.parse(grid_position.fielddata);
      
        // Ensure conditions are met
        if (fieldData.addIcon === "1" &&
          fieldData.iconPosition === "SF") {
          // Dynamically get the icon component
          const IconComponent = Icons[fieldData.icontoshow];
          // Return the icon if it exists
          const iconColor = fieldData.color || "black";
          return IconComponent ? <IconComponent style={{ color: iconColor }}/> : null;
        }
        return null;
      })()}
            {
              (view_for == "mobbody" && rowindexbody + 1 == totcnt && (
                <>
                  <div
                    className="payload_view_table_actions_mobile"
                    id={rowindex}
                  >
                    <Actions
                      disabled={disabled}
                      defaultValue=""
                      payload_config={payload_config}
                      actionHandler={actionHandler}
                      editHandler={editHandler}
                      payload={payload}
                      edit={edit}
                      setEdit={setEdit}
                      setEditfc={setEditfc}
                      getPayloads={getPayloads}
                      DeletePayload={DeletePayload}
                      DeleteMongoPayload={DeleteMongoPayload}
                      updatedPayload={updatedPayload}
                      effective_designation_id={effective_designation_id}
                      converttoarray={converttoarray}
                      newfield_mapping={newfield_mapping}
                      chkpayloadrequired={chkpayloadrequired}
                      generate_modaldata={generate_modaldata}
                      ActionFormType={ActionFormType}
                      ModalForm={ModalForm}
                      replacedFormrow={replacedFormrow}
                      replacementValues={replacementValues}
                      setModalForm={setModalForm}
                      DisableBtn={DisableBtn}
                      setDisableBtn={setDisableBtn}
                      PayloadRequired={PayloadRequired}
                      validatepayloaddata={validatepayloaddata}
                      isError={isError}
                      setisError={setisError}
                      isErrorValue={isErrorValue}
                      setisErrorValue={setisErrorValue}
                      actionValue={actionValue}
                      setisOpenModal={setisOpenModal}
                      setactionValue={setactionValue}
                      editLoading={editLoading}
                      setEditLoading={setEditLoading}
                      isVisibleEdit={isVisibleEdit}
                      isMobileView={isMobileView}
                    />
                  </div>
                </>
              ))
            }
          </>
        )}
        {/* {view_for == "action" && (
          
        )} */}
        {/* {expandedRow && isMobileView && (
          <div id={rowindex} class="cell-1 row-child">
            <DetailComponent
              detailData={expandedRow}
              payload_config={subpayload_config}
              api_token={api_token}
              effective_designation_id={effective_designation_id}
              fetch_onload={true}
            />
          </div>
        )} */}
        {selectedrowindex == rowindex && expandedRow && !isMobileView && (
          <>{subpayload_config ?
          <tr id={rowindex} class="collapse cell-1 row-child">
            <td colSpan={12}>
              <DetailComponent
                detailData={expandedRow}
                payload_config={subpayload_config}
                api_token={api_token}
                effective_designation_id={effective_designation_id}
                fetch_onload={true}
                storesubdata={storesubdata}
                selectedrowindex={selectedrowindex}
                filtered_condition={filtered_condition}
                sub_data={sub_data || []}
                removedCheckboxes={removedCheckboxes}
                removedCheckboxesid={removedCheckboxesid}
              />
            </td>
          </tr>:<tr id={rowindex} class="collapse cell-1 row-child">
          <td colSpan={5}>Configuration Not Found</td></tr>}</>
        )}
      </>
    );
  }

function PaymentData({ payment }) {
  return (
    <tr>
      {Object.entries(payment.entity).map(([key, value]) => (
        <td key={key}>{value}</td>
      ))}
    </tr>
  );
}
function Actions({
  actionHandler,
  payload_config,
  payload,
  disabled,
  edit,
  setEdit,
  setEditfc,
  editHandler,
  getPayloads,
  DeletePayload,
  DeleteMongoPayload,
  updatedPayload,
  effective_designation_id,
  converttoarray,
  newfield_mapping,
  chkpayloadrequired,
  generate_modaldata,
  ActionFormType,
  ModalForm,
  replacedFormrow,
  replacementValues,
  setModalForm,
  DisableBtn,
  setDisableBtn,
  PayloadRequired,
  validatepayloaddata,
  isError,
  setisError,
  isErrorValue,
  setisErrorValue,
  actionValue,
  setisOpenModal,
  setactionValue,
  editLoading,
  setEditLoading,
  isVisibleEdit,
  isMobileView,
}) {
  const [actionLoading, setActionLoading] = useState(false);
  const [allowClear, setAllowClear] = useState(false);
  useEffect(() => {
    if (actionValue) {
      setisOpenModal(true);
    }
  }, [actionValue]);
  return (
    <>
      {payload_config.action_mapping && payload_config.action_mapping[0].action_name &&
      effective_designation_id != "1" ? (
        <Select
          placeholder="Action"
          style={{
            width: 120,
          }}
          disabled={disabled}
          loading={actionLoading}
          options={[
            {
              label: "Select an action", // You can customize this label
              value: "", // An empty value
            },
            ...payload_config.action_mapping
              .map((action) => {
                if (
                  action.action_event != "onsave" &&
                  action.action_event != "massupdate"
                ) {
                  return { label: action.action_name, value: action.id };
                } else {
                  return null;
                }
              })
              .filter((item) => item !== null),
          ]}
          allowClear={allowClear}
          onChange={(value) => {
            setactionValue(value);
            value != "" && generate_modaldata(value, payload);
          }}
        />
      ) : (
        ""
      )}

      {!edit && isVisibleEdit && (
        <span className={isMobileView ? "mobileEditbtn" : ""}>
          <Button
          type="default"
          disabled={DisableBtn}
          onClick={() => {
            if(isMobileView){
              setEditfc(true);
            } else {
              setEdit(true);
            }
            chkpayloadrequired();            
          }}
          icon={<EditOutlined />}
        />
        </span>
      )}
      {edit && !editLoading ? (
        <Button
          type="default"
          onClick={() => {
            if(isMobileView){
              setEditfc(false);
            } else {
              setEdit(false);
            }
            setDisableBtn(false);
          }}
          icon={<CloseOutlined />}
        />
      ) : (
        ""
      )}
      {edit ? (
        <Button
          type="default"
          loading={editLoading}
          disabled={DisableBtn}
          icon={<CheckOutlined />}
          onClick={
            // () => {
            // let validatepayload = validatepayloaddata();
            // if (validatepayload) {
            async () => {
              let validatepayload = validatepayloaddata();

              if (validatepayload) {
                setEditLoading(true);
                let response = await editHandler(
                  payload_config["bucket_name"],
                  payload["key"]
                );
                setEditLoading(false);
                if (response === true) {
                  if(isMobileView){
                    setEditfc(false);
                  } else {
                    setEdit(false);
                  }
                  //getPayloads();
                } else {
                  if(isMobileView){
                    setEditfc(true);
                  } else {
                    setEdit(true);
                  }
                }
              } else {
                //console.log("PayloadRequired", PayloadRequired);
              }
              // checkanduploadfile();
            }
            //}
            //}
          }
        />
      ) : (
        ""
      )}

      {effective_designation_id == 21 &&
        (payload_config.payload_type == "M" ? (
          <Button
            type="default"
            onClick={() => {
              if (window.confirm("Delete the item?")) {
                DeleteMongoPayload(payload._id.$oid);
              }
            }}
            icon={<DeleteOutlined />}
          />
        ) : (
          <Button
            type="default"
            onClick={() => {
              if (window.confirm("Delete the item?")) {
                DeletePayload(payload.key);
              }
            }}
            icon={<DeleteOutlined />}
          />
        ))}
    </>
  );
}
